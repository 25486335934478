import React, { Fragment } from "react";
import Fade from "react-fade-in";

const Instructions = ({ order }) => {
  const instruction = order?.payment_method?.withdraw_instructions;

  return (
    <Fade>
      <Fragment>
        <p className="bluey-grey-text my-6">
          {instruction ||
            "Go to the agent's location with your ID card and this QR code."}
          <br />
          <span className="pale-red-text">
            Wait for the status of this transaction to change to ‘processed’
            before leaving agent.
          </span>
        </p>
      </Fragment>
    </Fade>
  );
};

export default Instructions;
