import React, { Fragment } from "react";

import MoneyBagIllustration from "../../../../../../Illustrations/MoneyBag";
import ButtonComponent from "../../../../../Button";
import BubbleStyle from "../../../../../../Styles/Bubble";

const Fiat = ({ order }) => {
  return (
    <Fragment>
      <div
        style={{ maxWidth: "23.5rem", width: "100%" }}
        className="mx-auto my-5"
      >
        <div className="px-2 text-center">
          <BubbleStyle
            size={84}
            bg="var(--pale-grey-four)"
            className="mx-auto overflow-hidden"
          >
            <MoneyBagIllustration variant="send" size={48} />
          </BubbleStyle>

          <big className="lh-15 d-inline-block mt-4">
            Click send now to continue
          </big>
        </div>

        <div className="my-8 mx-auto">
          <ButtonComponent
            width={292}
            className="mx-auto text-uppercase"
            onClick={(e) => window.open(order?.payment_url)}
          >
            Send Now
          </ButtonComponent>
        </div>
      </div>
    </Fragment>
  );
};

export const Footer = ({ order: { ecurrency }, mutate = () => {} }) => {
  return (
    <Fragment>
      <div className="my-4">
        <div className="border-top ice-blue-border pt-4">
          <small className="steel-text lh-13">
            <Fragment>
              By clicking 'Send Now' button, You Agree that you've been informed
              and you know that your transaction MAY be temporarily tied up by
              the security service of {ecurrency?.name} for manual checking
              purposes. In this case, you can see that the money has been
              debited from your account and BATCH transactions, but the
              recipient ({process.env.REACT_APP_APP_NAME || "PayPlux"}) can not
              see the transaction. The checking usually takes from 5 minutes to
              several hours. Definitely, the exchange will be completed
              immediately after we receive your transaction.
            </Fragment>
          </small>
        </div>
      </div>
    </Fragment>
  );
};

export default Object.assign(Fiat, { Footer });
