import PropTypes from "prop-types";
import useSWR from "swr";

const propTypes = {
  key: PropTypes.string.isRequired,
  value: PropTypes.any,
};

const useConfig = (key, value) => {
  /**
   * api
   */
  const { data } = useSWR("/configs", {
    dedupingInterval: 4000,
  });

  /**
   * variables
   */
  const config = data?.find((config) => config.key === key);

  if (config) {
    if (value) {
      return config.value === value;
    } else {
      return config;
    }
  } else {
    return undefined;
  }
};

useConfig.propTypes = propTypes;

export default useConfig;
