import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 48,
};

const MobilePayment = ({ size, ...props }) => (
  <svg
    fill="none"
    width={size}
    height={size}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="12" y="4" width="24" height="40" rx="2" fill="#2B4059" />
    <rect x="14" y="6" width="20" height="31" rx="1" fill="#F2F3F5" />
    <rect x="21" y="39" width="6" height="3" rx="1.5" fill="#516276" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26 11C24.8954 11 24 11.8954 24 13V20V21V26L26.5 23H42C43.1046 23 44 22.1046 44 21V13C44 11.8954 43.1046 11 42 11H26Z"
      fill="#1C8AFF"
    />
    <path
      d="M26.5 15C26.2239 15 26 14.7761 26 14.5C26 14.2239 26.2239 14 26.5 14H33.5C33.7761 14 34 14.2239 34 14.5C34 14.7761 33.7761 15 33.5 15H26.5Z"
      fill="white"
    />
    <path
      d="M26.4828 17C26.2161 17 26 16.7761 26 16.5C26 16.2239 26.2161 16 26.4828 16H39.5172C39.7839 16 40 16.2239 40 16.5C40 16.7761 39.7839 17 39.5172 17H26.4828Z"
      fill="white"
    />
    <path
      d="M26.4762 19C26.2132 19 26 18.7761 26 18.5C26 18.2239 26.2132 18 26.4762 18H35.5238C35.7868 18 36 18.2239 36 18.5C36 18.7761 35.7868 19 35.5238 19H26.4762Z"
      fill="white"
    />
  </svg>
);

MobilePayment.propTypes = propTypes;
MobilePayment.defaultProps = defaultProps;

export default MobilePayment;
