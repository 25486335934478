import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 96,
};

const SendEmail = ({ size, ...props }) => (
  <svg
    fill="none"
    width={size}
    height={size}
    viewBox="0 0 96 96"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="icon">
      <g id="Interface Components / Coloured Icons / mail_verify">
        <g id="send (1)">
          <path
            id="Path"
            d="M30.1394 59.1094V81.8386C30.1394 83.9094 32.756 84.7919 33.9827 83.1343L44.9537 68.3341L48.2981 66.6L84.5096 14.7853V12.793L84.1274 12.4508L30.1274 57.2455L30.1394 59.1094Z"
            fill="#1C8AFF"
          />
          <path
            id="Path_2"
            d="M82.3054 12.1587C82.2085 12.1946 13.2785 44.2689 13.1849 44.3124C11.6817 45.0089 11.5892 46.9684 13.0193 47.7864C13.0193 47.7864 24.626 54.1075 29.952 57.1453C30.0104 57.1787 30.1274 57.2454 30.1274 57.2454L84.5096 12.4831C83.947 12.0313 83.1391 11.8385 82.3054 12.1587Z"
            fill="#49A1FF"
          />
          <path
            id="Path_3"
            d="M84.5725 12.555C84.5385 12.5251 84.5273 12.4788 84.4915 12.4508L84.0295 13.0399L44.9531 68.3341L72.1927 83.4181C73.6114 84.2028 75.4475 83.4546 75.7502 82.0012L85.2952 14.4568C85.4492 13.7364 85.1246 13.0386 84.5725 12.555Z"
            fill="#49A1FF"
          />
        </g>
      </g>
    </g>
  </svg>
);

SendEmail.propTypes = propTypes;
SendEmail.defaultProps = defaultProps;

export default SendEmail;
