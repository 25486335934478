import React, { useEffect, useState, Fragment } from "reactn";
import PropTypes from "prop-types";
import Markdown from "react-showdown";
import useSWR from "swr";

import { handleStatusColor, handleStatusIcon } from "../Utils/helpers";
import useSessionHook from "../Hooks/useSession";
import AlertComponent from "./Alert";
import ModalComponent from "./Modal";
import BubbleStyle from "../Styles/Bubble";

const propTypes = {
  page: PropTypes.string.isRequired,
};

/**
 * Fetch and Display Notices from API
 */
const Banner = ({ page, ...props }) => {
  /**
   * state
   */
  const [show, setShow] = useState(false);

  /**
   * api
   */
  const { data } = useSWR("/notice-board");

  /**
   * variables
   */
  const session = useSessionHook();
  const banner = data?.find((notice) =>
    notice.pages
      .split(",")
      .map((page) => page.toUpperCase())
      .includes(page.toUpperCase()),
  );
  const Icon = banner ? handleStatusIcon(banner?.type) : null;
  const color = banner ? handleStatusColor(banner?.type) : null;
  const closedBanners = session.getItem("closedBanners") || [];

  /**
   * functions
   */
  const setClosedBanners = (slug) =>
    session.setItem("closedBanners", [...(closedBanners || []), slug]);

  /**
   * effect
   */
  useEffect(() => {
    if (banner) {
      if (!closedBanners.includes(banner.slug)) {
        setShow(true);
      }
    }
  }, [banner]);

  return (
    <Fragment>
      {show && banner && (
        <Fragment>
          {banner.location === "modal" && (
            <ModalComponent.Dark
              show
              onHide={() => setClosedBanners(banner.slug) | setShow(false)}
            >
              <ModalComponent.Body className="white-bg p-6">
                {Icon && (
                  <BubbleStyle
                    size={72}
                    bg={color}
                    opacity={0.1}
                    className="mx-auto mb-4"
                  >
                    <Icon size={32} />
                  </BubbleStyle>
                )}
                <Markdown className="small" markdown={banner?.details} />
              </ModalComponent.Body>
            </ModalComponent.Dark>
          )}
          {banner.location === "banner" && (
            <AlertComponent
              icon={Icon}
              variant={banner.type}
              close={() => setClosedBanners(banner.slug)}
              {...props}
            >
              <div className="lh-12">
                {banner?.title && (
                  <p className="mb-1 fw-bolder">{banner?.title}</p>
                )}
                <Markdown className="small" markdown={banner?.details} />
              </div>
            </AlertComponent>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

Banner.propTypes = propTypes;

export default Banner;
