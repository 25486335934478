import styled from "styled-components";
import Card from "react-bootstrap/Card";

export const data = ({ data, isLoading }) => ({
  labels: data?.map(() => "") || [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ],
  datasets: [
    {
      data: data || [
        11000, 30000, 98000, 52000, 48000, 43000, 37000, 73000, 51000, 50000,
        69000, 100000,
      ],
      fill: true,
      tension: 0.5,
      borderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 0,
      pointHoverRadius: 0,
      borderColor: isLoading ? "#eee" : "#c0c6ce",
      pointHoverBorderWidth: 0,
      backgroundColor: isLoading ? "#eee" : "#f7f8fa",
    },
  ],
});

export const options = {
  animation: false,
  responsive: true,
  maintainAspectRatio: false,
  plugins: { legend: false, tooltip: { enabled: false } },
  scales: {
    y: {
      display: false,
    },
    x: {
      display: false,
    },
  },
};

export const Wrapper = styled(Card)`
  width: 100%;
  flex: 0 0 100%;
  height: 13.5rem;
  overflow: hidden;

  .card-body {
    height: 100%;
  }

  .currency__details {
    padding: 1.5rem;

    &__info {
      gap: 1rem;
      display: grid;
      align-items: center;
      margin-bottom: 1rem;
      grid-template-columns: 2rem minmax(0, 1fr) 4rem;
    }
  }

  .currency__chart {
    height: 6rem;
    overflow: hidden;
  }

  @media (min-width: 567px) {
    width: 22.25rem;
    flex: 0 0 22.25rem;
  }
`;
