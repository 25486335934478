import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Sklt from "react-loading-skeleton-2";

import { transactionType } from "../Transaction/index";
import TransactionComponent from "../Transaction";
import LinkComponent from "../Link";
import GridStyle from "../../Styles/Grid";
import loopUtil from "../../Utils/loop";

const Mobile = ({ isLoading, transactions }) => {
  return (
    <div>
      {isLoading ? (
        <Fragment>
          {loopUtil(5).map((index) => (
            <Skeleton key={index} />
          ))}
        </Fragment>
      ) : (
        <Fragment>
          {transactions.map((transaction, key) => (
            <Transaction key={key} {...{ transaction }} />
          ))}
        </Fragment>
      )}
    </div>
  );
};

/**
 * components
 */
const Skeleton = (props) => (
  <Item preventDefault {...props}>
    <div>
      <Sklt width={144} height={14} className="mb-2" />
      <GridStyle
        gaps={{ sm: "0.625rem" }}
        columns={{ sm: "1rem minmax(0,1fr)" }}
      >
        <Sklt width={16} height={16} circle />
        <Sklt width={112} height={14} />
      </GridStyle>
    </div>

    <div>
      <Sklt width={88} height={14} className="mb-2 d-block ms-auto" />
      <Sklt width={72} height={14} className="d-block ms-auto" />
    </div>
  </Item>
);

const Transaction = ({ transaction, ...props }) => {
  /**
   * state
   */
  const [modal, setModal] = useState(false);

  /**
   * variables
   */
  const type = transactionType(transaction);

  return (
    <Fragment>
      <Item
        preventDefault
        type={transaction.type}
        onClick={() => setModal(true)}
        {...props}
      >
        <div>
          <small className="text-truncate mb-2">Prepaid wallet topup</small>
          <GridStyle
            gaps={{ sm: "0.625rem" }}
            className="align-items-center"
            columns={{ sm: "1rem minmax(0,1fr)" }}
          >
            <TransactionComponent.Image {...{ transaction }} />
            <small className="bluey-grey-text">
              <TransactionComponent.Description {...{ transaction }} />
            </small>
          </GridStyle>
        </div>
        <div className="text-end">
          <small className="mb-2 fw-bolder font-monospace item__amount">
            <TransactionComponent.Amount {...{ withSign: true, transaction }} />
          </small>
          <small className="bluey-grey-text">
            <TransactionComponent.Date {...{ transaction }} />
          </small>
        </div>
      </Item>

      {["purchase", "sale"].includes(type) ? (
        <TransactionComponent.Modal.Order
          onHide={() => setModal(false)}
          id={modal && transaction?.order?.id}
          order={{ ...transaction?.order, wallet: transaction?.wallet }}
        />
      ) : (
        <TransactionComponent.Modal.Wallet
          transaction={transaction}
          id={modal && transaction?.id}
          onHide={() => setModal(false)}
        />
      )}
    </Fragment>
  );
};

/**
 * styles
 */
const Item = styled(LinkComponent)`
  display: grid;
  color: var(--navy);
  padding: 1rem 0rem;
  grid-template-columns: repeat(2, 1fr);
  border-bottom: solid 0.0625rem var(--ice-blue);

  &:hover {
    color: var(--navy);
  }

  img {
    width: 1.25rem;
  }

  small {
    display: block;
  }

  .item__amount {
    color: ${({ type }) =>
      type?.toLowerCase() === "debit" ? "var(--pale-red)" : "var(--navy)"};
  }
`;

export default Object.assign(Mobile, { Skeleton, Transaction });
