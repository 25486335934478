import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton-2";

import MoneyBagIllustration from "../../../../../../Illustrations/MoneyBag";
import CopyItemComponent from "../../../../../CopyItem";
import TransactionAmount from "../../../../Amount";
import TooltipComponent from "../../../../../Tooltip";
import ButtonComponent from "../../../../../Button";
import NotifyComponent from "../../../../../Notify";
import QRCodeComponent from "../../../../../QRCode";
import WrapperStyle from "../../../../../../Styles/Wrapper";
import BubbleStyle from "../../../../../../Styles/Bubble";
import HelpIcon from "../../../../../../Icons/Help";

const Crypto = ({ order }) => {
  return (
    <Fragment>
      <WrapperStyle width={376} className="mx-auto mb-5">
        <div className="px-2 mb-6">
          <BubbleStyle
            size={84}
            bg="var(--pale-grey-four)"
            className="mx-auto overflow-hidden"
          >
            <MoneyBagIllustration variant="send" size={48} />
          </BubbleStyle>

          <big className="lh-15 d-inline-block mt-4 text-center">
            Go to your wallet and send exactly{" "}
            <span className="fw-bold">
              <b>
                <TransactionAmount transaction={order} local={false} />
              </b>
            </span>{" "}
            to the {order?.ecurrency?.name} address
          </big>
        </div>

        {order?.ecurrency_detail_required ? (
          <CopyItemComponent
            label={`${process.env.REACT_APP_APP_NAME || "PayPlux"} ${
              order?.ecurrency?.name
            } Address`}
            content={order?.ecurrency_detail_required}
          />
        ) : (
          <WrapperStyle className="mx-auto" width={372}>
            <Skeleton height={72} />
          </WrapperStyle>
        )}
      </WrapperStyle>

      <div className="mb-5">
        <ORSeparator />
      </div>

      <ScanQR {...{ order }} />
    </Fragment>
  );
};

export const Footer = ({ order: { ecurrency }, mutate = () => {} }) => {
  /**
   * state
   */
  const [loading, setLoading] = useState(false);

  /**
   * functions
   */
  const handleOnClick = () => {
    mutate();
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
      toast(
        <NotifyComponent
          body="Transaction refreshed successfully"
          type="success"
        />,
      );
    }, 3000);
  };

  return (
    <Fragment>
      <div className="my-8">
        <div className="border-top ice-blue-border pt-4">
          <small className="steel-text lh-13">
            <Fragment>
              By sending the {ecurrency?.name}, you agree that you've been
              informed that you'll be paid for the USD value at the time of
              transaction and also after we have received at least 1 - 3
              confirmations (value below 999 USD) or 3 - 6 confirmations (value
              above 1000 USD) from the {ecurrency?.name} network.
            </Fragment>
          </small>
        </div>
      </div>

      <div className="d-flex justify-content-center mb-4">
        <ButtonComponent
          width={292}
          className="me-4"
          isSubmitting={loading}
          onClick={handleOnClick}
        >
          Refresh
        </ButtonComponent>

        <TooltipComponent
          content={`Click on the "Refresh" button after you are done sending the ${ecurrency?.name} amount to the address.`}
        >
          <HelpIcon color="var(--cloudy-blue)" />
        </TooltipComponent>
      </div>
    </Fragment>
  );
};

export const ScanQR = ({ order: { ecurrency, ...order } }) => (
  <div className="d-flex align-items-center justify-content-center">
    <big className="me-4 lh-15">
      You can also scan the QR code to capture the {ecurrency?.name} address
    </big>
    <div className="qrcode">
      {order?.ecurrency_detail_required ? (
        <QRCodeComponent value={order?.ecurrency_detail_required} />
      ) : (
        <Skeleton width={120} height={120} />
      )}
    </div>
  </div>
);

const ORSeparator = () => (
  <div className="d-flex position-relative align-items-center justify-content-center">
    <span
      className="w-100 pale-grey-three-bg d-block position-absolute"
      style={{ height: 1 }}
    />
    <BubbleStyle size={40} bg="var(--pale-grey-four)">
      <small className="bluey-grey-text fw-bolder">OR</small>
    </BubbleStyle>
  </div>
);

// export default Crypto;

export default Object.assign(Crypto, { Footer, ScanQR, ORSeparator });
