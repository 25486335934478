import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Separator from "./WavyCard/Separator";
import Waves from "./WavyCard/Waves";

const propTypes = {
  bg: PropTypes.string,
  width: PropTypes.number,
  wavesProps: PropTypes.object, // refer to waves component for details
};

const defaultProps = {
  bg: "#fff",
  width: 450,
};

const WavyCard = ({ bg, width, children, wavesProps, ...props }) => {
  return (
    <Wrapper
      {...{
        bg,
        width,
        radius: wavesProps?.radius || Waves.defaultProps.radius,
        ...props,
      }}
    >
      <Waves {...{ width, ...wavesProps }} placement="top" />
      {children}
      <Waves {...{ width, ...wavesProps }} placement="bottom" />
    </Wrapper>
  );
};

/**
 * styles
 */
const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-color: ${({ bg }) => bg};
  max-width: ${({ width }) => `${width * 0.0625}rem`};
  padding: ${({ radius }) => `${radius * 0.0625}rem 0`};
`;

WavyCard.propTypes = propTypes;
WavyCard.defaultProps = defaultProps;

export default Object.assign(WavyCard, { Waves, Separator });
