import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import BSTooltip from "react-bootstrap/Tooltip";
import PropTypes from "prop-types";

import LinkComponent from "./Link";

/**
 * props definition
 */
const propTypes = {
  /**
   * placement: where to show the tooltip
   */
  placement: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  /**
   * content: Content to show in tooltip
   */
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  /**
   * children: Trigger
   */
  children: PropTypes.any.isRequired,
  /**
   * trigger: event to trigger tooltip
   */
  trigger: PropTypes.oneOf(["click", "hover", "focus"]),
};

const defaultProps = {
  placement: "top",
  trigger: "click",
};

const Tooltip = ({ placement, content, children, trigger, className }) => (
  <OverlayTrigger
    key={placement}
    overlay={<BSTooltip id={`tooltip-${placement}`}>{content}</BSTooltip>}
    {...{ trigger, placement }}
  >
    <LinkComponent
      preventDefault
      className={`d-flex align-items-center ${className}`}
    >
      {children}
    </LinkComponent>
  </OverlayTrigger>
);

Tooltip.propTypes = propTypes;
Tooltip.defaultProps = defaultProps;

export default Tooltip;
