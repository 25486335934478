import React, { Fragment } from "react";
import { Formik } from "formik";
import { object } from "yup";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton-2";
import useSWR from "swr";
import Form from "react-bootstrap/Form";

import * as schemaUtil from "../Utils/schema";
import ConfirmOtpComponent from "./ConfirmOtp";
import ButtonComponent from "./Button";
import SelectComponent from "./Select";
import FieldComponent from "./Field";
import ModalComponent from "./Modal";
import GridStyle from "../Styles/Grid";
import HttpUtil from "../Utils/Http";

const propTypes = {
  type: PropTypes.oneOf(["withdrawal", "deposit"]),
  phoneType: PropTypes.oneOf(["momo", "default"]),
  onSuccess: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  show: PropTypes.bool,
};

const defaultProps = {
  phoneType: "momo",
  withSave: false,
  type: "deposit",
};

const AddPhoneNumber = ({
  onSuccess,
  phoneType,
  withSave,
  type,
  user,
  ...props
}) => {
  /**
   * api
   */
  const { data, error } = useSWR(
    `/wallets/${user?.default_wallet_id}/${type}/methods`,
    { dedupingInterval: 1000 * 60 * 60 },
  );

  /**
   * variables
   */
  const networks = (() => {
    if (data) {
      return data
        .filter(
          (d) => d.active && d.type === process.env.REACT_APP_MOBILE_MONEY_TYPE,
        )
        .map(({ id: value, name: label, image: avatar }) => ({
          label,
          value,
          avatar,
          type: "url",
        }));
    }

    return [];
  })();

  /**
   * functions
   */
  const handleLocalSubmit = (formData, { setSubmitting, setErrors }) => {
    ConfirmOtpComponent({
      params: formData,
      variant: "phone",
      api: (payload) => HttpUtil.put("/phones", payload),
    })
      .then(({ success, errors }) => {
        if (success) {
          onSuccess();
        } else {
          if (errors?.fields) {
            setErrors(errors.fields);
          }
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Fragment>
      <ModalComponent.Dark {...props}>
        <ModalComponent.Body className="white-bg oveflow-hidden">
          <h5 className="fw-bold p-6" style={{ fontSize: 20 }}>
            Add a new number
          </h5>

          <Formik
            validateOnMount
            validationSchema={object({
              payment_method_id: schemaUtil.requireNumber("Network"),
              name: schemaUtil.requireString("Name"),
              phone: schemaUtil.requirePhoneNumber("Phone number"),
              type: schemaUtil.requireString("Type"),
              country: schemaUtil.requireString("Country"),
            })}
            initialValues={{
              name: "",
              phone: "",
              save: true,
              type: "momo",
              payment_method_id: "",
              country: user?.country,
            }}
            onSubmit={handleLocalSubmit}
          >
            {({
              isValid,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
              values: { payment_method_id, name, phone, save },
            }) => (
              <Form>
                <GridStyle
                  gaps={{ sm: "1.5rem" }}
                  columns={{ md: "repeat(2, 1fr)" }}
                  className={`px-6 py-2 ${!withSave && "pb-6"}`}
                >
                  {!data && !error && (
                    <Fragment>
                      <Skeleton
                        height={14}
                        width={164}
                        className="mb-2 d-block"
                      />
                      <Skeleton height={48} className="d-block" />
                    </Fragment>
                  )}
                  {data && (
                    <FieldComponent
                      placeholder="--"
                      options={networks}
                      label="Select network"
                      name="payment_method_id"
                      wrapperClassName="mb-0"
                      value={payment_method_id}
                      component={SelectComponent}
                      onChange={({ value }) =>
                        setFieldValue("payment_method_id", value)
                      }
                    />
                  )}

                  <FieldComponent.Phone
                    name="phone"
                    value={phone}
                    wrapperClassName="mb-0"
                    label="Mobile Money Number"
                    defaultCountry={user?.country}
                    onlyCountries={[user?.country]}
                    {...{ setFieldTouched, setFieldValue }}
                  />

                  <FieldComponent
                    name="name"
                    value={name}
                    wrapperClassName="mb-0"
                    label="Name on Mobile Money account"
                  />
                </GridStyle>

                {withSave && (
                  <FieldComponent.Checkbox
                    checked={save}
                    className="m-6"
                    onChange={(checked) => setFieldValue("save", checked)}
                  >
                    Save this account for later use
                  </FieldComponent.Checkbox>
                )}

                <div className="py-5 px-6 pale-grey-four-bg d-flex justify-content-end">
                  <ButtonComponent
                    width={144}
                    type="submit"
                    onClick={handleSubmit}
                    {...{ isValid, isSubmitting }}
                  >
                    Add number
                  </ButtonComponent>
                </div>
              </Form>
            )}
          </Formik>
        </ModalComponent.Body>
      </ModalComponent.Dark>
    </Fragment>
  );
};

AddPhoneNumber.propTypes = propTypes;
AddPhoneNumber.defaultProps = defaultProps;

export default AddPhoneNumber;
