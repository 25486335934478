import React from "react";
import { Formik } from "formik";
import { object } from "yup";
import PropTypes from "prop-types";
import styled from "styled-components";
import Form from "react-bootstrap/Form";

import * as schema from "../Utils/schema";
import ButtonComponent from "../Components/Button";
import FieldComponent from "../Components/Field";
import ArrowIcon from "../Icons/Arrow";

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isValid: PropTypes.bool,
};

const defaultProps = {
  isValid: false,
};

const Coupon = ({ handleSubmit, isValid: valid, ...props }) => (
  <Formik
    validateOnMount
    validationSchema={object({
      coupon: schema.requireString("Discount code"),
    })}
    initialValues={{ coupon: "" }}
    onSubmit={handleSubmit}
  >
    {({ handleSubmit, values: { coupon }, isValid, isSubmitting }) => (
      <Wrapper {...props}>
        <FieldComponent
          name="coupon"
          value={coupon}
          containerClassName="border-0"
          placeholder="Enter discount code…"
          style={{ height: 60, borderRadius: 0 }}
          postfix={
            <ButtonComponent
              {...{ isValid: valid && isValid, isSubmitting }}
              onClick={handleSubmit}
              variant="custom"
              bg="#2FC463"
              type="submit"
              height={60}
              width={60}
            >
              <ArrowIcon variant="forward" color="#fff" />
            </ButtonComponent>
          }
        />
      </Wrapper>
    )}
  </Formik>
);

/**
 * styles
 */
const Wrapper = styled(Form)`
  .input-container {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 6px;
    overflow: hidden;

    .form-control {
      color: #fff;
      background-color: transparent !important;

      &::placeholder {
        color: var(--bluey-grey);
      }
    }
  }

  .btn {
    border-radius: 6px;
  }
`;

Coupon.propTypes = propTypes;
Coupon.defaultProps = defaultProps;

export default Coupon;
