import PaymentDetails from "./Transaction/PaymentDetails";
import PaymentMethod from "./Transaction/PaymentMethod";
import Description from "./Transaction/Description";
import Progress from "./Transaction/Progress";
import Amount from "./Transaction/Amount";
import Status from "./Transaction/Status";
import Image from "./Transaction/Image";
import Modal from "./Transaction/Modal";
import Date from "./Transaction/Date";
import Type from "./Transaction/Type";

export default Object.assign(
  {},
  {
    PaymentDetails,
    PaymentMethod,
    Description,
    Progress,
    Amount,
    Status,
    Image,
    Modal,
    Date,
    Type,
  },
);
