import React, { Fragment } from "react";
import PropTypes from "prop-types";

import loopUtil from "../Utils/loop";
import Card from "./CurrencyMarketCards/Card";

const propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  wrapper: PropTypes.func,
};

const defaultProps = {
  isLoading: true,
  wrapper: Fragment,
};

const CurrencyMarketCards = ({
  assets,
  isLoading,
  wrapper: ParentWrapper,
  ...props
}) => {
  /**
   * functions
   */
  const handleSettings = (settings) => {
    if (Array.isArray(settings)) {
      return settings[0];
    } else {
      return settings;
    }
  };

  return (
    <div {...props}>
      <h5 className="fw-bold">Currency market value</h5>

      {isLoading ? (
        <ParentWrapper>
          {loopUtil(4).map((index) => (
            <Card.Skeleton key={index} />
          ))}
        </ParentWrapper>
      ) : (
        <ParentWrapper>
          {assets
            ?.filter(
              ({ type, settings }) =>
                handleSettings(settings)?.visible && type === "crypto",
            )
            ?.map((asset, key) => (
              <Card key={key} asset={asset} />
            ))}
        </ParentWrapper>
      )}
    </div>
  );
};

CurrencyMarketCards.propTypes = propTypes;
CurrencyMarketCards.defaultProps = defaultProps;

export default Object.assign(CurrencyMarketCards, { Card });
