import React, { Fragment, useRef, useGlobal } from "reactn";
import Skeleton from "react-loading-skeleton-2";
import ReactToPrint from "react-to-print";
import queryString from "query-string";
import startCase from "lodash/startCase";
import styled from "styled-components";
import PropTypes from "prop-types";
import useSWR from "swr";

import { transactionType } from "../../index";
import PaymentDetails from "../../PaymentDetails";
import GridStyle from "../../../../Styles/Grid";
import useUser from "../../../../Hooks/useUser";
import loopUtil from "../../../../Utils/loop";
import ButtonComponent from "../../../Button";
import ModalComponent from "../../../Modal";
import IconAndAmount from "../Wallet/index";
import TabsComponent from "../../../Tabs";

import Status from "../../Status";

// tabs
import Instructions from "./WithdrawalReservation/Instructions";
import Details from "./WithdrawalReservation/Details";

/**
 * props definition
 */
const propTypes = {
  /**
   * details of transaction from API
   */
  order: PropTypes.object,
  /**
   * modal props
   */
};

const defaultProps = {};

const WithdrawalReservation = ({ id, order, onHide, ...props }) => {
  /**
   * api
   */
  const { data, error, mutate } = useSWR(
    !order &&
      Boolean(id) &&
      `/transactions/${id}?${queryString.stringify({
        with_ecurrency: true,
        with_transaction: true,
        with_crypto_details: true,
      })}`,
  );

  /**
   * ref
   */
  const printableRef = useRef();

  /**
   * variables
   */
  const transaction = Boolean(id) && (order || data);
  const type = Boolean(id) && transactionType(transaction);
  const transactionDetails =
    Boolean(id) &&
    (() => {
      if (transaction?.transaction_details) {
        try {
          return JSON.parse(transaction.transaction_details);
        } catch (error) {
          return false;
        }
      }
    })();

  /**
   * state
   */
  const [token, setToken] = useGlobal("token");

  /**
   * hooks
   */
  const { user } = useUser({ token, setToken });

  return (
    <ModalComponent.Light show={Boolean(id)} size="sm" {...{ onHide }}>
      <ModalComponent.Body className="p-6 px-md-8 white-bg">
        {/* skeleton */}
        {!order && !data && !error && (
          <Fragment>
            <div className="d-flex justify-content-between mb-10">
              <Skeleton height={14} width={132} />
              <Skeleton height={14} width={72} />
            </div>

            <div className="mb-10">
              <IconAndAmount.Skeleton />
            </div>

            <GridStyle
              gaps={{ sm: "2rem" }}
              columns={{ sm: "repeat(2, minmax(0, 1fr))" }}
              className="mb-10 justify-content-between"
            >
              {loopUtil(3 * 2).map((index) => (
                <div
                  key={index}
                  className={`d-flex x-spacing-4 ${
                    index % 2 === 0
                      ? "justify-content-start"
                      : "justify-content-end"
                  } `}
                >
                  <Skeleton width={144} height={14} />
                </div>
              ))}
            </GridStyle>

            <div>
              <Skeleton height={48} />
            </div>
          </Fragment>
        )}

        {transaction && (
          <Fragment>
            <div className="print__area" ref={printableRef}>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <small className="text-sub text-uppercase bluey-grey-text">
                  #{transaction?.id}
                </small>
                <Status {...{ transaction }} />
              </div>

              <IconAndAmount className="mb-10" {...{ transaction }} />

              <TabWrapper>
                <TabsComponent
                  childProps={{ order, user }}
                  tabs={[
                    {
                      title: "Instructions",
                      content: Instructions,
                      eventKey: "instructions",
                    },
                    {
                      title: "Transaction Details",
                      content: Details,
                      eventKey: "details",
                    },
                  ]}
                />
              </TabWrapper>
            </div>
            {/* print-area */}

            <div className="mb-10">
              {transactionDetails && (
                <PaymentDetails
                  items={Object.keys(transactionDetails)
                    .filter(
                      (key) =>
                        key !== "payment_method_id" &&
                        Boolean(transactionDetails[key]),
                    )
                    .map((key) => ({
                      title: startCase(key),
                      desc: transactionDetails[key],
                    }))}
                />
              )}
            </div>

            <GridStyle gaps={{ sm: "1rem" }}>
              {transaction?.status?.name?.toLowerCase() !== "processed" && (
                <ButtonComponent
                  variant="outline"
                  className="border-1"
                  onClick={() => mutate()}
                >
                  Refresh
                </ButtonComponent>
              )}

              <ReactToPrint
                content={() => printableRef.current}
                documentTitle={`Transaction_#${transaction?.id}`}
                trigger={() => (
                  <ButtonComponent variant="outline" className="border-1">
                    Print
                  </ButtonComponent>
                )}
                bodyClass="print"
              />
            </GridStyle>
          </Fragment>
        )}
      </ModalComponent.Body>
    </ModalComponent.Light>
  );
  x;
};

/**
 * styled components
 */
const TabWrapper = styled.div`
  .tabs__nav {
    justify-content: center;
    border-bottom: none;
    overflow-x: hidden;

    > a {
      text-transform: uppercase;
      font-size: 0.75rem;
      margin: 0 1.25rem;
      padding: 0.375rem 1rem;
    }

    .-active {
      color: var(--clear-blue);
    }
  }
`;

WithdrawalReservation.propTypes = propTypes;
WithdrawalReservation.defaultProps = defaultProps;

export default WithdrawalReservation;
