import React from "react";
import PropTypes from "prop-types";
import Sklt from "react-loading-skeleton-2";
import Image from "react-bootstrap/Image";

import { transactionType } from "../../";
import * as helpers from "../../../../Utils/helpers";
import MoneyBagIllustration from "../../../../Illustrations/MoneyBag";
import WrapperStyle from "../../../../Styles/Wrapper";
import BubbleStyle from "../../../../Styles/Bubble";
import Type from "../../Type";

const propTypes = {
  /**
   * details of transaction from API
   */
  transaction: PropTypes.object.isRequired,
};

const Index = ({ transaction, ...props }) => {
  /**
   * variables
   */
  const isCredit = ["deposit", "purchase"].includes(
    transactionType(transaction),
  );
  const isCrypto = Boolean(transaction?.order?.ecurrency);

  return (
    <div {...props}>
      <WrapperStyle width={376} className="mx-auto">
        <BubbleStyle
          size={84}
          bg="var(--pale-grey-four)"
          className="mx-auto overflow-hidden mb-12"
        >
          {isCrypto ? (
            <Image
              width={48}
              src={`/assets/${transaction?.order?.ecurrency?.short_name?.toLowerCase()}.svg`}
            />
          ) : (
            <MoneyBagIllustration
              variant={isCredit ? "deposit" : "withdraw"}
              size={48}
            />
          )}
        </BubbleStyle>

        <div className="text-center">
          <h1 className="mb-4 font-monospace fw-bolder">
            {`${isCredit ? "+" : "-"} ${helpers.Money(transaction?.amount)} ${
              transaction?.wallet?.currency?.abbreviation
            }`}
          </h1>
          <small className="d-block bluey-grey-text">
            <Type {...{ transaction }} />
          </small>
        </div>
      </WrapperStyle>
    </div>
  );
};

/**
 * others
 */
const Skeleton = (props) => (
  <div {...props}>
    <WrapperStyle width={376} className="mx-auto">
      <Sklt circle width={84} height={84} className="d-block mb-12 mx-auto" />

      <div className="text-center">
        <Sklt height={44} width={172} className="mx-auto mb-4 d-block" />
        <Sklt height={14} width={72} className="mx-auto d-block" />
      </div>
    </WrapperStyle>
  </div>
);

Index.propTypes = propTypes;

export default Object.assign(Index, { Skeleton });
