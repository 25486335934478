import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 48,
};

const EVoucher = ({ size, ...props }) => (
  <svg
    fill="none"
    width={size}
    height={size}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.9917 4C15.1677 4 8 10.894 8 19.396C8 27.1019 17.0475 38.899 23.9283 44C32.8001 37.076 40 27.225 40 19.396C40 10.8939 32.8323 4 23.9917 4ZM16.2157 19.0799C16.2157 14.9459 19.7009 11.5939 24 11.5939C28.2992 11.5939 31.7832 14.9459 31.7832 19.0799C31.7832 23.2149 28.2992 26.5669 24 26.5669C19.7008 26.5669 16.2157 23.2149 16.2157 19.0799Z"
      fill="#E65B43"
    />
    <path
      d="M24 9C18.4772 9 14 13.4772 14 19C14 24.522 18.4772 29 24 29C29.522 29 34 24.522 34 19C34 13.4772 29.522 9 24 9ZM24 13C27.314 13 30 15.6862 30 19C30 22.3138 27.314 25 24 25C20.686 25 18 22.3138 18 19C18 15.6862 20.686 13 24 13Z"
      fill="#CA4538"
    />
  </svg>
);

EVoucher.propTypes = propTypes;
EVoucher.defaultProps = defaultProps;

export default EVoucher;
