import React, { Fragment } from "react";
import { Line } from "react-chartjs-2";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";

import { Wrapper, options, data as chartData } from "./index";
import useAssetHook from "../../Hooks/useAsset";
import ArrowIcon from "../../Icons/Arrow";
import Skeleton from "./Card/Skeleton";

const Index = ({ asset, ...props }) => {
  /**
   * functions
   */
  const handleArrow = (value) => {
    if (value < 0) {
      return { icon: "downward", color: "pale-red" };
    }

    if (value > 0) {
      return { icon: "upward", color: "dark-mint" };
    }

    return { icon: "navy" };
  };

  /**
   * hooks
   */
  const { data: assetData, error } = useAssetHook(
    { asset: asset?.id, chart: true },
    (url) =>
      window
        .fetch(`${process.env.REACT_APP_API_BASEURL}${url}`)
        .then((response) => response.json()),
  );

  /**
   * variables
   */
  const percentageDifference = (() => {
    const { data, ecurrency } = assetData || {};

    if (data) {
      const lastPrice = data.slice(data.length - 1, data.length)[0]
        ?.price_close;

      const decreaseValue = (ecurrency.crypto_rate || 0) - lastPrice;

      return parseFloat((decreaseValue / lastPrice) * 100, 2).toLocaleString(
        "en-GB",
        {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      );
    } else {
      return 0;
    }
  })();

  const prices = assetData?.data?.map((history) => history?.price_close);

  return (
    <Fragment>
      {!assetData && !error && <Skeleton />}
      {assetData && (
        <Wrapper {...props}>
          <Card.Body>
            <div className="currency__details">
              <div className="currency__details__info">
                <Image
                  width={32}
                  height={32}
                  src={`/assets/${asset?.short_name?.toLowerCase()}.svg`}
                  className="img-fit rounded-circle"
                />
                <h5 className="text-truncate">{asset?.name}</h5>
                <div className="d-flex align-items-center x-spacing-2 ms-auto">
                  {percentageDifference === 0 ? (
                    <span>-</span>
                  ) : (
                    <ArrowIcon
                      size={18}
                      variant={handleArrow(percentageDifference).icon}
                      color={`var(--${
                        handleArrow(percentageDifference).color
                      })`}
                    />
                  )}
                  <small
                    className={`font-monospace fw-bolder ${
                      handleArrow(percentageDifference).color
                    }-text`}
                  >
                    {percentageDifference}%
                  </small>
                </div>
              </div>
              <h4 className="clear-blue-text fw-bolder font-monospace">
                {assetData?.ecurrency?.crypto_rate || 0} USD
              </h4>
            </div>
            <div className="currency__chart">
              <Line
                {...{
                  options,
                  data: chartData({
                    data: prices,
                    isLoading: !assetData && !error,
                  }),
                }}
              />
            </div>
          </Card.Body>
        </Wrapper>
      )}
    </Fragment>
  );
};

export default Object.assign(Index, { Skeleton });
