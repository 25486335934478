import React, { useState, Fragment, useEffect } from "react";
import Skeleton from "react-loading-skeleton-2";
import Markdown from "react-showdown";
import useSWR from "swr";
import Card from "react-bootstrap/Card";
import Fade from "react-fade-in";

import * as helpersUtil from "../../../../Utils/helpers";
import MobilePaymentIllustration from "../../../../Illustrations/MobilePayment";
import AddPhoneNumberComponent from "../../../AddPhoneNumber";
import LogoCardComponent from "../../../LogoCard";
import ButtonComponent from "../../../Button";
import SelectComponent from "../../../Select";
import AlertComponent from "../../../Alert";
import FieldComponent from "../../../Field";
import SmartPhoneIcon from "../../../../Icons/SmartPhone";
import LinkComponent from "../../../Link";
import useConfigHook from "../../../../Hooks/useConfig";
import BubbleStyle from "../../../../Styles/Bubble";
import GridStyle from "../../../../Styles/Grid";
import CloseIcon from "../../../../Icons/Close";
import InfoIcon from "../../../../Icons/Info";
import loopUtil from "../../../../Utils/loop";
import AddIcon from "../../../../Icons/Add";

const Index = ({
  user,
  wallet,
  methods,
  setHasPaid,
  transactionData,
  setPaymentMethod,
}) => {
  /**
   * api
   */
  const { data: phones, error: phonesError, mutate } = useSWR("/phones");

  /**
   * variables
   */
  const currency = wallet?.currency?.abbreviation;

  /**
   * state
   */
  const [method, setMethod] = useState(methods[0]);
  const [modal, setModal] = useState(false);

  /**
   * hooks
   */
  const canAddPhoneNumber = useConfigHook("feature_add_phone", "true");
  const maxPhones = useConfigHook("feature_max_phones");

  /**
   * effect
   */
  useEffect(() => {
    if (method) {
      setPaymentMethod(method?.id);
    }
  }, [method, setPaymentMethod]);

  return (
    <Fade>
      <GridStyle
        columns={{ md: "repeat(2, 1fr)" }}
        gaps={{ sm: "1.5rem", md: "2.5rem" }}
      >
        <div className="text-center">
          {!method?.verified && (
            <AlertComponent
              variant="danger"
              className="mb-6"
              icon={(props) => <CloseIcon {...props} variant="circle" />}
            >
              <small className="lh-13 text-start">
                You are not verified to use mobile money payment methods.
              </small>
            </AlertComponent>
          )}

          <h5 className="mb-8 lh-13">
            You are paying{" "}
            <b>
              {`${helpersUtil.Money(
                transactionData?.total || transactionData?.amount,
              )} ${currency}`}
            </b>{" "}
            to our Mobile money number.
          </h5>

          <BubbleStyle
            size={84}
            className="mx-auto mb-8"
            bg="var(--pale-grey-four)"
          >
            <MobilePaymentIllustration />
          </BubbleStyle>

          <div style={{ maxWidth: 320 }} className="mx-auto mb-6">
            <h5 className="mb-3">Pay to our mobile money account</h5>
            <Markdown
              className="lh-15"
              markdown={method?.deposit_instructions || ""}
            />
          </div>

          <ButtonComponent
            isValid={method?.verified}
            onClick={() => setHasPaid(true)}
          >
            I have made the payment
          </ButtonComponent>
        </div>

        <div>
          <GridStyle gaps={{ sm: "1rem" }}>
            <FieldComponent
              name="network"
              withFormik={false}
              value={method?.id}
              label="Select network"
              wrapperClassName="mb-0"
              component={SelectComponent}
              onChange={({ value }) =>
                setMethod(methods?.find(({ id }) => value === id))
              }
              options={methods?.map((method) => ({
                type: "url",
                value: method?.id,
                label: method?.name,
                avatar: method?.image,
              }))}
            />

            <LogoCardComponent className="white-text">
              <GridStyle gaps={{ sm: "1rem" }}>
                <small className="fw-bolder">
                  {process.env.REACT_APP_APP_NAME || "PayPlux"} Mobile Money details
                </small>
                {method?.details &&
                  Object.keys(method.details).map((item, key) => (
                    <div key={key}>
                      <small className="bluey-grey-text mb-1">{item}</small>
                      <p className="fw-bold">{method.details[item]}</p>
                    </div>
                  ))}
              </GridStyle>
            </LogoCardComponent>

            <Card className="shadow-none light-periwinkle-border border">
              <Card.Body className="d-flex x-spacing-4 p-6">
                <InfoIcon
                  color="var(--clear-blue)"
                  style={{ flex: "0 0 1.5rem" }}
                />
                <div>
                  <p className="fw-bold lh-13 mb-6">
                    Pay with any of your added numbers to avoid confirmation
                    delays
                  </p>

                  {/* skeleton */}
                  {!phones && !phonesError && (
                    <Fragment>
                      {loopUtil(2).map((key) => (
                        <div
                          key={key}
                          className="d-flex align-items-center x-spacing-4 mb-6"
                        >
                          <Skeleton height={22} width={18} />
                          <Skeleton height={16} width={116} />
                        </div>
                      ))}
                    </Fragment>
                  )}

                  {phones?.map(({ phone }, key) => (
                    <div
                      key={key}
                      className="d-flex align-items-center x-spacing-4 mb-6"
                    >
                      <SmartPhoneIcon color="var(--bluey-grey)" />
                      <p>{phone}</p>
                    </div>
                  ))}
                  {phones?.length < maxPhones?.value && canAddPhoneNumber && (
                    <LinkComponent
                      preventDefault
                      onClick={() => setModal(true)}
                      className="d-flex align-items-center x-spacing-4 clear-blue-text"
                    >
                      <AddIcon color="var(--clear-blue)" />
                      <small>Add a new mobile number</small>
                    </LinkComponent>
                  )}
                </div>
              </Card.Body>
            </Card>
          </GridStyle>
        </div>
      </GridStyle>

      <AddPhoneNumberComponent
        show={modal}
        type="deposit"
        onHide={() => setModal(false)}
        onSuccess={() => mutate() | setModal(false)}
        {...{ user, type: "deposit" }}
      />
    </Fade>
  );
};

export default Index;
