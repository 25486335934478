import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import ButtonComponent from "./Button";
import CompareIcon from "../Icons/Compare";
import HexagonIcon from "../Icons/Hexagon";
import ArrowIcon from "../Icons/Arrow";

const propTypes = {
  className: PropTypes.string,
  /**
   * send path for routing
   */
  path: PropTypes.object.isRequired,
  /**
   * router function
   */
  router: PropTypes.func.isRequired,
};

const NoTransactions = ({ className, path, router, ...props }) => {
  /**
   * variables
   */
  const cards = [
    {
      icon: CompareIcon,
      name: "Make your first exchange with us!",
      bg: { card: "var(--clear-blue)", hex: "var(--navy)" },
      description:
        "Buy bitcoin or perfect money with as little as GHS50.00 or sell yours to us for GHS.",
      button: {
        content: "Buy & Sell",
        link: path?.buy?.index,
      },
    },
    {
      icon: ArrowIcon,
      name: "Top up your Prepaid wallet!",
      bg: { card: "var(--navy)", hex: "#fff" },
      description:
        "Easily add funds and place a buy order at anytime including holidays!",
      button: {
        content: "Add Funds",
        link: path?.buy?.index,
      },
    },
  ];

  return (
    <div {...{ className }}>
      <h5 className="fw-bold mb-6">Get Started</h5>
      <Wrapper>
        {cards.map((card, key) => (
          <Card key={key} bg={card.bg.card} className="text-center">
            <div className="card__icon">
              <HexagonIcon className="card__icon__bg" color={card.bg.hex} />
              <card.icon
                color="#fff"
                variant="downward"
                className="card__icon__icon"
              />
            </div>
            <h5 className="card__header">{card.name}</h5>
            <div className="small card__description">{card.description}</div>
            <ButtonComponent
              width={164}
              height={54}
              variant="outline:white"
              className="card__btn mx-auto mt-auto"
              onClick={() => router(card.button.link)}
            >
              {card.button.content}
            </ButtonComponent>
          </Card>
        ))}
      </Wrapper>
    </div>
  );
};

/**
 * styles
 */
const Card = styled.div`
  color: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background-color: ${({ bg }) => bg};

  .card__icon {
    display: flex;
    width: 4.5rem;
    height: 4.5rem;
    margin-left: auto;
    position: relative;
    margin-right: auto;
    align-items: center;
    margin-bottom: 1.5rem;
    justify-content: center;

    &__bg {
      width: 100%;
      height: 100%;
      opacity: 0.15;
    }

    &__icon {
      width: 2.25rem;
      height: 2.25rem;
      position: absolute;
    }
  }

  .card__header {
    margin-bottom: 0.625rem;
  }

  .card__description {
    opacity: 0.5;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    max-width: 20.25rem;
    margin-bottom: 1.5rem;
  }

  .btn {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 600;
  }

  @media (min-width: 992px) {
    padding: 3rem;

    .card__icon {
      width: 5.75rem;
      height: 5.75rem;
      margin-bottom: 2rem;

      &__icon {
        width: 3rem;
        height: 3rem;
      }
    }
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  border-radius: 0.375rem;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

NoTransactions.propTypes = propTypes;

export default NoTransactions;
