import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Image from "react-bootstrap/Image";

import TooltipComponent from "../Tooltip";
import ButtonComponent from "../Button";
import LinkComponent from "../Link";
import BubbleStyle from "../../Styles/Bubble";
import GridStyle from "../../Styles/Grid";

/**
 * props definition
 */
const propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  caption: PropTypes.string.isRequired,
  amount: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    currency: PropTypes.string.isRequired,
  }),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  info: PropTypes.shape({
    link: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.string.isRequired,
  }),
};
// prettier-ignore
const WalletCard = ({ title, icon, amount, caption, buttons, info, ...props }) => (
  <Wrapper {...props}>
    <div className="info mb-5 mb-md-12">
      <BubbleStyle size={85} bg="var(--ice-blue)" className="mb-5 mx-auto p-4">
        <Image src={icon || "/assets/wallet.svg"} />
      </BubbleStyle>
      <h5 className="mb-5">{title}</h5>
      <div>
        <h1 className="mb-2 font-monospace fw-bolder">
          {amount && `${amount?.value || "--"} ${amount?.currency}`}
        </h1>
        <p className="bluey-grey-text">
          <small className="text-uppercase">{caption}</small>
        </p>
      </div>
    </div>
    <div className="actions">
      {buttons && (
        <GridStyle
          gaps={{ sm: "1rem" }}
          columns={{ sm: "repeat(1, 1fr)", md: "repeat(2, min-content)" }}
          className="justify-content-center"
        >
          {buttons?.map(({ label, ...props }, index) => (
            <ButtonComponent key={index} {...{ ...props }}>
              <span title={label} className="actions__label text-truncate">
                {label}
              </span>
            </ButtonComponent>
          ))}
        </GridStyle>
      )}
      {info && (
        <u className="mt-5 d-inline-block text-center">
          {info?.link && (
            <LinkComponent
              target="_blank"
              href={info?.link}
              rel="noopener noreferrer"
            >
              {info?.text}
            </LinkComponent>
          )}

          {info?.description && (
            <TooltipComponent
              trigger="focus"
              children={info?.text}
              content={info?.description}
              className="justify-content-center"
            />
          )}
        </u>
      )}
    </div>
  </Wrapper>
);

/**
 * styles
 */
const Wrapper = styled.div`
  text-align: center;
  padding: 2rem 1.5rem;
  border-radius: 0.375rem;
  background-color: var(--pale-grey);
  border: solid 0.0625rem var(--ice-blue);

  .actions {
    .btn {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
`;

WalletCard.propTypes = propTypes;

export default WalletCard;
