import React from "react";
import startCase from "lodash/startCase";
import styled from "styled-components";
import Fade from "react-fade-in";
import useSWR from "swr";

import * as helpers from "../../../../../Utils/helpers";
import TransactionDate from "../../../Date";
import LinkComponent from "../../../../Link";
import LaunchIcon from "../../../../../Icons/Launch";

const Details = ({ order, ...props }) => {
  /**
   * variables
   */
  const ecurrency = order?.ecurrency;
  const currency = order?.wallet?.currency;
  const orderType = order?.order_type?.toLowerCase();
  const isProcessed = order?.status?.slug === `user-${orderType}-processed`;

  /**
   * api
   */
  const { data: loyaltyPoints } = useSWR(
    Boolean(order?.id) &&
      Boolean(order?.user_id) &&
      `${process.env.REACT_APP_LOYALTY_API_BASEURL}/user/${order.user_id}/loyalty-points/${order.id}`,
  );
  const isLoyaltyPointEarned = loyaltyPoints && Boolean(loyaltyPoints.point);

  const items = [
    ...(isLoyaltyPointEarned
      ? [
          {
            title: "Loyalty Points Earned",
            desc: `${(loyaltyPoints?.point || 0)?.toLocaleString()}`,
          },
        ]
      : []),
    ...(isProcessed
      ? [
          {
            title: "Processed on",
            desc: (
              <TransactionDate
                date="processed_at"
                transaction={order}
                format="hh:mm A, Do MMM, YYYY"
              />
            ),
          },
        ]
      : []),
    {
      title: `${startCase(orderType)} rate`,
      desc: `${helpers.Money(order?.ecurrency_rate)} ${currency?.abbreviation}`,
    },
    {
      title: ecurrency?.type === "crypto" ? "Miners Fee (USD)" : "Fee (USD)",
      desc: `${order?.fee_usd} (${startCase(order?.fee_option)})`,
    },
    ...(ecurrency?.type === "crypto"
      ? [
          {
            title: `${startCase(ecurrency?.slug)} address`,
            desc: order?.ecurrency_detail_required,
          },
        ]
      : ecurrency?.type === "fiat"
      ? [
          {
            title: "Batch ID",
            desc: order?.batch_id,
          },
        ]
      : []),
    ...(order?.crypto_details?.tx_hash
      ? [
          {
            title: `${startCase(ecurrency?.slug)} Transaction ID`,
            desc: order?.crypto_details?.tx_hash,
          },
        ]
      : []),
  ];

  return (
    <div {...props}>
      <Fade mountOnEnter unmountOnExit>
        <div className="d-flex flex-column y-spacing-8">
          {items?.map(({ title, desc }, key) => (
            <Item key={key}>
              <div>
                <small className="item__title">{title}</small>
              </div>
              <div>
                <small className="item__description">{desc}</small>
              </div>
            </Item>
          ))}
        </div>

        {isProcessed && order?.crypto_details?.tx_hash && (
          <div className="d-flex justify-content-end x-spacing-2 mt-5">
            {[
              {
                name: "Blockchain",
                link: `https://www.blockchain.com/${ecurrency?.short_name?.toLowerCase()}/tx/${
                  order?.crypto_details?.tx_hash
                }`,
              },
              {
                name: "Tradeblock",
                link: `https://tradeblock.com/${ecurrency?.slug?.toLowerCase()}/tx/${
                  order?.crypto_details?.tx_hash
                }`,
              },
              {
                name: "Blockcypher",
                link: `https://live.blockcypher.com/${ecurrency?.short_name?.toLowerCase()}/tx/${
                  order?.crypto_details?.tx_hash
                }`,
              },
            ].map(({ name, link }, key) => (
              <LinkComponent
                key={key}
                href={link}
                target="_blank"
                className="d-flex align-items-center"
              >
                <LaunchIcon color="var(--clear-blue)" size={16} />
                <small className="clear-blue-text ms-1">{name}</small>
              </LinkComponent>
            ))}
          </div>
        )}
      </Fade>
    </div>
  );
};

/**
 * styles
 */
const Item = styled.div`
  gap: 1.5rem;
  display: grid;
  line-height: 1.3;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  .item__title {
    color: var(--bluey-grey);
  }

  .item__description {
    text-align: right;
    word-wrap: break-word;
  }
`;

export default Details;
