import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

/**
 * prop definition
 */
const propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
  isStatus: PropTypes.bool,
  opacity: PropTypes.number,
  children: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(["failed", "success", "processing", "warning"]),
  radius: PropTypes.number,
};

const defaultProps = {
  color: "#fff",
  isStatus: true,
  opacity: 0.1,
  radius: 6,
};

const Badge = ({
  bg,
  color,
  radius,
  variant,
  opacity,
  isStatus,
  children,
  ...props
}) => {
  /**
   * variables
   */
  bg = ((bg, variant) => {
    if (bg) {
      return bg;
    }

    switch (variant) {
      case "failed":
        return "var(--pale-red)";
      case "success":
        return "var(--medium-green)";
      case "processing":
        return "var(--turquoise-blue)";
      case "warning":
        return "var(--squash-two)";
      default:
        break;
    }
  })(bg, variant);

  return (
    <Wrapper {...{ ...props, bg, color, isStatus, opacity, radius }}>
      <span className="text-sub -medium">{children}</span>
    </Wrapper>
  );
};

/**
 * styles
 */
const Wrapper = styled.div`
  z-index: 1;
  height: 1rem;
  display: flex;
  overflow: hidden;
  font-weight: 600;
  position: relative;
  white-space: nowrap;
  font-size: 0.6875rem;
  padding: 0rem 0.3125rem;
  border-radius: ${({ radius }) => `${radius * 0.0625}rem`};
  background-color: ${({ bg, isStatus }) => (isStatus ? "#fff" : bg)};

  span {
    margin: auto;
    display: block;
    text-transform: uppercase;
    color: ${({ isStatus, color, bg }) => (isStatus ? bg : color)};
  }

  &::before {
    display: ${({ isStatus }) => (isStatus ? "block" : "none")};
    opacity: ${({ opacity }) => opacity};
    background-color: ${({ bg }) => bg};
    position: absolute;
    content: " ";
    height: 100%;
    width: 100%;
    z-index: -1;
    right: 0;
    top: 0;
  }
`;

Badge.propTypes = propTypes;
Badge.defaultProps = defaultProps;

export default Badge;
