import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton-2";

import Grid from "../../Styles/Grid";

const propTypes = {
  desc: PropTypes.string,
  heading: PropTypes.string.isRequired,
};

/**
 *
 * @param {*} desc description for no record
 * @param {*} heading heading string for no record
 */

const NoRecord = ({ desc, heading, ...props }) => {
  return (
    <Wrapper {...props}>
      <tr>
        <td>
          <div className="card__inner">
            <div className="card__inner__image mb-4">
              <Grid
                gaps={{ sm: "0.625rem" }}
                columns={{ sm: "1rem minmax(0,1fr)" }}
              >
                <Skeleton duration={0} width={10} height={10} circle />
                <Skeleton duration={0} width={65} height={10} />

                <Skeleton duration={0} width={10} height={10} circle />
                <Skeleton duration={0} width={30} height={10} />

                <Skeleton duration={0} width={10} height={10} circle />
                <Skeleton duration={0} width={45} height={10} />
              </Grid>
            </div>

            <div className="card__inner__text text-center mb-4">
              <p className="mb-2 fw-bold">{heading}</p>
              {desc && <p className="bluey-grey-text small">{desc}</p>}
            </div>
          </div>
        </td>
      </tr>
    </Wrapper>
  );
};

/**
 * styles
 */
const Wrapper = styled.tbody`
  td {
    border: none;
    background-color: #fff !important;
  }

  .card__inner {
    padding-top: 5.25rem;
    padding-bottom: 5.25rem;

    max-width: 15.625rem;
    overflow-x: auto;
    margin-left: auto;
    margin-right: auto;
    word-break: break-word;

    &__image {
      max-width: 5rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

NoRecord.propTypes = propTypes;

export default NoRecord;
