import React from "react";
import { createConfirmation, confirmable } from "react-confirm";
import PropTypes from "prop-types";

import Button from "./Button";
import Modal from "./Modal";
import Grid from "../Styles/Grid";

const propTypes = {
  show: PropTypes.bool,
  proceed: PropTypes.func,
  msg: PropTypes.any.isRequired,
  header: PropTypes.string,
  buttons: PropTypes.object,
};

export function Dialog({ show, proceed, msg, header, buttons }) {
  return (
    <Modal
      centered
      size="sm"
      show={show}
      close={false}
      className="p-4"
      onHide={() => proceed(false)}
    >
      {header && <h3 className="mb-2">{header}</h3>}
      <div className="mb-8">{msg}</div>
      <Grid
        gaps={{ sm: "1rem" }}
        columns={{ sm: "auto auto" }}
        className="justify-content-end"
      >
        <Button
          variant="outline"
          onClick={() => proceed(false)}
          children={buttons?.cancel?.children || "Cancel"}
          className={`px-3 ${buttons?.cancel?.className}`}
        />
        <Button
          variant="default"
          onClick={() => proceed(true)}
          children={buttons?.proceed?.children || "Proceed"}
          className={`px-3 ${buttons?.proceed?.className}`}
        />
      </Grid>
    </Modal>
  );
}

Dialog.propTypes = propTypes;

export const Confirm = createConfirmation(confirmable(Dialog));

export default Confirm;
