import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Link from "./Link";

const propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object,
      ]),
      content: PropTypes.func.isRequired,
      eventKey: PropTypes.string.isRequired,
    }),
  ).isRequired,
  activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.oneOf(["bordered", "default"]),
  childProps: PropTypes.object,
  onSelect: PropTypes.func,
};

const defaultProps = {
  variant: "default",
};

const Tabs = ({
  tabs,
  variant,
  onSelect,
  activeKey,
  childProps,
  navClassName,
  contentClassName,
  ...props
}) => {
  /**
   * states
   */
  const [selectedTab, setSelectedTab] = useState();

  /**
   * variables
   */
  const Wrapper = (() => {
    switch (variant) {
      case "bordered":
        return BorderedTabs;
      default:
        return DefaultTabs;
    }
  })();

  /**
   * effect
   */
  useEffect(() => {
    if (!activeKey) {
      setSelectedTab(tabs.find((tab) => !tab.disabled) || tabs[0]);
    } else {
      if (activeKey !== selectedTab?.eventKey) {
        setSelectedTab(tabs.find((tab) => tab.eventKey === activeKey));
      }
    }
  }, []);

  return (
    <Fragment>
      <Wrapper {...props}>
        <TabItems
          className={navClassName}
          {...{ tabs, onSelect, selectedTab, setSelectedTab }}
        />
        <div className="tabs__content">
          {selectedTab && <selectedTab.content {...childProps} />}
        </div>
      </Wrapper>
    </Fragment>
  );
};

/**
 * component
 */
const TabItems = ({
  tabs,
  onSelect,
  className,
  selectedTab,
  setSelectedTab,
}) => (
  <nav className={`tabs__nav ${className}`}>
    {tabs.map((tab, key) => (
      <NavLink
        key={key}
        preventDefault
        className={tab.eventKey === selectedTab?.eventKey ? "-active" : ""}
        onClick={() => {
          if (selectedTab?.eventKey !== tab.eventKey) {
            if (onSelect) {
              if (tab?.disabled) {
                return;
              } else {
                onSelect(tab.eventKey);
              }
            }
            if (!tab?.disabled) {
              return setSelectedTab(tab);
            }
          }
        }}
      >
        {tab.title}
      </NavLink>
    ))}
  </nav>
);

/**
 * styles
 */
const NavLink = styled(Link)`
  padding: 1rem;
  font-weight: 500;
  white-space: nowrap;
  text-decoration: none;
  color: var(--bluey-grey);
  transition: ease-in-out 0.15s all;
  border-bottom: 0.125rem solid transparent;

  &:hover {
    color: var(--bluey-grey);
  }

  &.-active {
    font-weight: 500;
    color: var(--navy);
    border-color: var(--clear-blue);
  }
`;

const DefaultTabs = styled.div`
  .tabs__nav {
    z-index: 10;
    display: flex;
    overflow: auto;
    margin-bottom: 1.5rem;
    background-color: #fff;
    border-bottom: solid 0.0625rem var(--ice-blue);
  }
`;

const BorderedTabs = styled(DefaultTabs)`
  overflow: hidden;
  border-radius: 0.5rem;
  border: 0.0625rem solid var(--ice-blue);

  .tabs__nav {
    z-index: 10;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 1.5rem;
    background-color: var(--pale-grey);
  }
`;

Tabs.propTypes = propTypes;
Tabs.defaultProps = defaultProps;

export default React.memo(Tabs);
