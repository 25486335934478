import React, { useState, Fragment } from "react";
import { createConfirmation, confirmable } from "react-confirm";
import startCase from "lodash/startCase";
import Image from "react-bootstrap/Image";

import * as helpersUtil from "../../../Utils/helpers";
import WavyCardComponent from "../../WavyCard";
import ButtonComponent from "../../Button";
import CouponComponent from "../../Coupon";
import ModalComponent from "../../Modal";
import LinkComponent from "../../Link";
import useConfigHook from "../../../Hooks/useConfig";
import useAssetHook from "../../../Hooks/useAsset";
import BubbleStyle from "../../../Styles/Bubble";
import CloseIcon from "../../../Icons/Close";
import GridStyle from "../../../Styles/Grid";
import HttpUtil from "../../../Utils/Http";

const Summary = ({
  show,
  user,
  params,
  wallet,
  proceed,
  paramKey,
  transactionData,
  handleCouponCode,
}) => {
  /**
   * hooks
   */
  const { data: ecurrency } = useAssetHook(
    {
      asset: transactionData.asset,
      user,
    },
    (url) => HttpUtil.get(url).then((data) => data),
  );
  const canUseSellCoupon = useConfigHook("can_use_sell_coupon", "true");

  /**
   * variable
   */
  const currency = wallet?.currency?.abbreviation;

  /**
   * state
   */
  const [coupon, setCoupon] = useState();
  const [amount, setAmount] = useState();

  return (
    <ModalComponent.Light
      size="sm"
      show={show}
      onHide={() => proceed({ success: false })}
    >
      <ModalComponent.Body className="shadow-none">
        <WavyCardComponent
          width={425}
          bg="var(--navy)"
          className="mx-auto py-8 mb-6"
        >
          <BubbleStyle
            size={46}
            bg="#fff"
            className="mx-auto"
            style={{ padding: "0.1875rem", marginTop: "-3.4375rem" }}
          >
            <Image
              src={`/assets/${ecurrency?.short_name?.toLowerCase()}.svg`}
              className="img-fit w-100"
            />
          </BubbleStyle>

          <div className="text-center steel-text mt-3 mb-8">
            <p className="mb-4">You are selling</p>
            <h4 className="white-text font-monospace fw-bolder mb-4 lh-13">
              {`${helpersUtil.Money(transactionData.amount_usd)} USD`}
            </h4>

            <p className="mb-1">
              And will receive{" "}
              <span className="fw-bolder font-monospace">
                {`${helpersUtil.Money(
                  transactionData.amount_local,
                )} ${currency}`}
              </span>
            </p>
            <p>
              At{" "}
              <span className="white-text font-monospace fw-bolder">
                {`${helpersUtil.Money(transactionData.rate)} ${currency}`}
              </span>{" "}
              per{" "}
              <span className="white-text font-monospace fw-bolder">1 USD</span>
            </p>
          </div>

          <WavyCardComponent.Separator />

          <div className="px-8 my-4">
            {canUseSellCoupon && (
              <Fragment>
                {amount ? (
                  <LinkComponent
                    preventDefault
                    className="d-flex align-items-center small mb-2"
                    onClick={() => setAmount()}
                  >
                    <CloseIcon
                      size={16}
                      className="me-1"
                      color="var(--clear-blue)"
                    />
                    <span>Clear promotion</span>
                  </LinkComponent>
                ) : (
                  <CouponComponent
                    isValid
                    handleSubmit={(params, actions) =>
                      handleCouponCode(params, actions).then(({ value }) => {
                        setCoupon(params.coupon);
                        setAmount(
                          parseFloat(
                            transactionData?.amount_usd *
                              Number(value).toFixed(2),
                          ),
                        );
                      })
                    }
                    className="mb-4"
                  />
                )}
              </Fragment>
            )}
            <div className="y-spacing-2">
              {[
                ...(amount
                  ? [
                      {
                        name: "Promotion (coupon)",
                        desc: `${
                          amount - transactionData?.amount_local
                        } ${currency}`,
                      },
                    ]
                  : []),
                {
                  name: "Total",
                  desc: (
                    <Fragment>
                      <span
                        className={`d-block ${
                          amount && `steel-text text-decoration-line-through`
                        }`}
                      >
                        {`${helpersUtil.Money(
                          transactionData.amount_local,
                        )} ${currency}`}
                      </span>
                      {amount && (
                        <span className="d-block mt-1">
                          {`${helpersUtil.Money(amount)} ${currency}`}
                        </span>
                      )}
                    </Fragment>
                  ),
                },
              ].map(({ name, desc }, key) => (
                <div
                  key={key}
                  className="d-flex align-items-center justify-content-between"
                >
                  <p className="steel-text">{name}</p>
                  <p className="white-text font-monospace fw-bolder">{desc}</p>
                </div>
              ))}
            </div>
          </div>

          <WavyCardComponent.Separator />

          <div className="my-3 px-8">
            <p className="steel-text text-center mb-4">Payment Details</p>

            {params[paramKey] ? (
              <GridStyle gaps={{ sm: "0.75rem" }}>
                {Object.keys(params[paramKey]).map(
                  (key) =>
                    !["payment_method_id"].includes(key) && (
                      <div
                        key={key}
                        className="d-flex align-items-center justify-content-between"
                      >
                        <p className="steel-text">{startCase(key)}</p>
                        <p className="white-text">
                          {startCase(params[paramKey][key])}
                        </p>
                      </div>
                    ),
                )}
              </GridStyle>
            ) : (
              <p className="white-text lh-13">
                You will be receiving into your Cash-out wallet
              </p>
            )}
          </div>
        </WavyCardComponent>

        <ButtonComponent
          height={64}
          onClick={() => proceed({ success: true, coupon, amount })}
        >
          Sell {ecurrency?.name}
        </ButtonComponent>
      </ModalComponent.Body>
    </ModalComponent.Light>
  );
};

export const Confirm = createConfirmation(confirmable(Summary));

export default Confirm;
