import React from "react";
import { Formik } from "formik";
import { object } from "yup";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";
import Fade from "react-fade-in";
import Form from "react-bootstrap/Form";

import * as schema from "../../../../Utils/schema";
import ButtonComponent from "../../../Button";
import FieldComponent from "../../../Field";
import LinkComponent from "../../../Link";
import ArrowIcon from "../../../../Icons/Arrow";

const propTypes = {
  /**
   * user wallet
   */
  wallet: PropTypes.object,

  /**
   * params key is the wrapper object for the data being sent to the API
   * example is payment_method_data
   */
  paramKey: PropTypes.string,

  /**
   * onSuccess, used to tell parent component task is complete
   */
  onSuccess: PropTypes.func,

  /**
   * used to toggle between payment information screen and payment form screen
   */
  setHasPaid: PropTypes.func,

  /**
   * submission function for data collected
   */
  handleSubmit: PropTypes.func,

  /**
   * paymentMethod is the ID of the payment method selected
   */
  paymentMethod: PropTypes.number,

  /**
   * transactionData contains transaction Data in initial stages of transaction data collection
   */
  transactionData: PropTypes.object,
};

const PaymentDetailsForm = ({
  wallet,
  paramKey,
  onSuccess,
  setHasPaid,
  handleSubmit,
  paymentMethod,
  transactionData,
}) => {
  return (
    <Fade>
      <LinkComponent
        preventDefault
        onClick={() => setHasPaid(false)}
        className="d-flex align-items-center x-spacing-2 small navy-text fw-bolder mb-4"
      >
        <ArrowIcon variant="backward" />
        <span>GO BACK</span>
      </LinkComponent>

      <Wrapper>
        <div className="mb-4">
          <h5 className="mb-2">Enter payment details</h5>
          <p className="bluey-grey-text lh-13">
            Please fill the form below with the details of the mobile money
            transaction
          </p>
        </div>

        <Formik
          validateOnMount
          validationSchema={object({
            [paramKey]: object().shape({
              amount: schema.requireNumber("Amount"),
              transaction_id: schema.requireString("Transaction ID"),
              datetime: schema.requireString("Transaction date"),
            }),
          })}
          initialValues={{
            [paramKey]: {
              transaction_id: "",
              datetime: new Date(),
              amount: transactionData?.total || transactionData?.amount || "",
            },
          }}
          onSubmit={(params, { setSubmitting, setErrors }) =>
            handleSubmit({
              payment_method_id: paymentMethod,
              [paramKey]: {
                ...params[paramKey],
                payment_method_id: paymentMethod,
                datetime: dayjs(params[paramKey].datetime).format(
                  "DD MMMM YYYY",
                ),
              },
            })
              .then((response) => onSuccess(response))
              .catch((error) => setErrors(error?.fields))
              .finally(() => setSubmitting(false))
          }
        >
          {({
            isValid,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            values: {
              [paramKey]: { transaction_id, datetime, amount },
            },
          }) => (
            <Form>
              <FieldComponent
                float
                value={transaction_id}
                label="Transaction ID"
                placeholder="Transaction ID"
                name={`${paramKey}.transaction_id`}
              />

              <FieldComponent.Money
                float
                value={amount}
                label="Amount paid"
                placeholder="Amount paid"
                name={`${paramKey}.amount`}
                currency={wallet?.currency?.abbreviation}
                {...{ setFieldValue, setFieldTouched }}
              />

              <FieldComponent.Date
                float
                value={datetime}
                dateFormat="dd MMMM yyyy"
                label="Date of transaction"
                name={`${paramKey}.datetime`}
                placeholder="Date of transaction"
                {...{ setFieldValue, setFieldTouched }}
              />

              <ButtonComponent
                type="submit"
                onClick={handleSubmit}
                {...{ isValid, isSubmitting }}
              >
                Submit
              </ButtonComponent>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Fade>
  );
};

/**
 * styles
 */
const Wrapper = styled.div`
  padding: 1.5rem;
  width: 23.125rem;
  border-radius: 0.625rem;
  background-color: var(--pale-grey);
  border: solid 0.0625rem var(--light-periwinkle);
`;

PaymentDetailsForm.propTypes = propTypes;

export default PaymentDetailsForm;
