import PropTypes from "prop-types";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";

const propTypes = {
  transaction: PropTypes.object.isRequired,
  format: PropTypes.string.isRequired,
};

const defaultProps = {
  format: "DD MMM YYYY",
};

const Date = ({ transaction, format, date = "created_at" }) => {
  dayjs.extend(advancedFormat); // adds support for needed format options
  return dayjs(transaction[date]).format(format);
};

Date.propTypes = propTypes;
Date.defaultProps = defaultProps;

export default Date;
