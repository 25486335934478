import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Sklt from "react-loading-skeleton-2";

import { transactionType } from "../Transaction/index";
import TransactionComponent from "../Transaction";
import LinkComponent from "../Link";
import loopUtil from "../../Utils/loop";

const Desktop = ({ isLoading, transactions }) => {
  return (
    <div>
      {isLoading ? (
        <Fragment>
          {loopUtil(5).map((index) => (
            <Skeleton key={index} />
          ))}
        </Fragment>
      ) : (
        <Fragment>
          {transactions.map((transaction, key) => (
            <Transaction key={key} {...{ transaction }} />
          ))}
        </Fragment>
      )}
    </div>
  );
};

/**
 * components
 */
const Skeleton = (props) => (
  <Item preventDefault {...props}>
    <Sklt height={32} width={32} circle />
    <Sklt height={18} width={180} />
    <Sklt height={16} width={144} />
    <Sklt height={16} width={88} className="mx-auto" />
    <Sklt height={18} width={122} className="ms-auto" />
  </Item>
);

const Transaction = ({ transaction, ...props }) => {
  /**
   * state
   */
  const [modal, setModal] = useState(false);

  /**
   * variables
   */
  const type = transactionType(transaction);

  return (
    <Fragment>
      <Item
        preventDefault
        type={transaction.type}
        onClick={() => setModal(true)}
        {...props}
      >
        <TransactionComponent.Image {...{ transaction }} />
        <div className="item__description">
          <h5 className="text-truncate">
            <TransactionComponent.Description {...{ transaction }} />
          </h5>
        </div>
        <div className="item__source">
          <p className="bluey-grey-text text-truncate">
            {transaction?.order ? (
              <TransactionComponent.Amount {...{ transaction }} />
            ) : (
              transaction?.payment_method?.name
            )}
          </p>
        </div>
        <div className="mx-auto item__date">
          <p className="bluey-grey-text text-truncate">
            <TransactionComponent.Date {...{ transaction }} />
          </p>
        </div>
        <div className="ms-auto item__amount">
          <h5 className="font-monospace fw-bolder">
            <TransactionComponent.Amount {...{ transaction, withSign: true }} />
          </h5>
        </div>
      </Item>

      {["purchase", "sale"].includes(type) ? (
        <TransactionComponent.Modal.Order
          onHide={() => setModal(false)}
          id={modal && transaction?.order?.id}
          order={{ ...transaction?.order, wallet: transaction?.wallet }}
        />
      ) : (
        <TransactionComponent.Modal.Wallet
          transaction={transaction}
          id={modal && transaction?.id}
          onHide={() => setModal(false)}
        />
      )}
    </Fragment>
  );
};

/**
 * styles
 */
const Item = styled(LinkComponent)`
  gap: 1rem;
  display: grid;
  color: var(--navy);
  padding: 1rem 0rem;
  align-items: center;
  white-space: nowrap;
  border-bottom: solid 0.0625rem var(--ice-blue);
  grid-template-columns: 2rem 11.25rem 9rem minmax(0, 1fr) minmax(0, 1fr);

  &:hover {
    color: var(--navy);
  }

  img {
    width: 2rem;
  }

  .item__description {
    h5 {
      font-weight: 400;
    }
  }

  .item__amount {
    color: ${({ type }) =>
      type?.toLowerCase() === "debit" ? "var(--pale-red)" : "var(--navy)"};
  }

  &:first-child {
    border-top: solid 0.0625rem var(--ice-blue);
  }

  @media (min-width: 992px) {
    gap: 2rem;
    grid-template-columns: 2rem 12.5rem 9rem minmax(0, 1fr) minmax(0, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: 2rem 15.25rem 9rem minmax(0, 1fr) minmax(0, 1fr);
  }
`;

export default Object.assign(Desktop, { Skeleton, Transaction });
