import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const propTypes = {
  size: PropTypes.number,
  color: PropTypes.oneOf(["navy", "grey"]),
  variant: PropTypes.oneOf(["full", "logo"]),
};

const defaultProps = {
  size: 100,
  color: "navy",
  variant: "full",
};

const Logo = ({ size, color, variant, ...props }) => {
  return (
    <Fragment>
      {variant === "full" && (
        <Wrapper
          id="Layer_1"
          width={size}
          data-name="Layer 1"
          viewBox="0 0 639 150"
          xmlns="http://www.w3.org/2000/svg"
          color={color}
          {...props}
        >
          <title>Px Logo - Grey</title>
          <path
            className="cls-1"
            d="M61.7,1,2.4,40.54A3.72,3.72,0,0,0,.75,43.63v62.74a3.72,3.72,0,0,0,1.65,3.09L61.7,149a3.71,3.71,0,0,0,5.76-3.09V4.09A3.71,3.71,0,0,0,61.7,1Z"
          />
          <path
            className="cls-2"
            d="M149.27,38.33,93.28,1a3.71,3.71,0,0,0-5.76,3.09V73.75A3.71,3.71,0,0,0,93.08,77l56-32.33A3.71,3.71,0,0,0,149.27,38.33Z"
          />
          <path
            className="cls-1"
            d="M87.52,105.47v40.44A3.71,3.71,0,0,0,93.28,149l59.3-39.54a3.71,3.71,0,0,0,1.66-3.09V71.23A3.71,3.71,0,0,0,148.68,68L89.37,102.26A3.72,3.72,0,0,0,87.52,105.47Z"
          />
          <path
            className="cls-1"
            d="M235.63,43.5a28.59,28.59,0,0,1,11,2.13,26.42,26.42,0,0,1,15,15.52,34.82,34.82,0,0,1,2.21,12.58,34.44,34.44,0,0,1-2.19,12.48,26.24,26.24,0,0,1-15.06,15.52,29.73,29.73,0,0,1-11.26,2.12q-11,0-18.27-7.11v26.13H202v-78h14.77v5.93A26.11,26.11,0,0,1,235.63,43.5Zm-2.42,47.15a15.33,15.33,0,0,0,11.26-4.5Q249,81.65,249,73.68t-4.5-12.45a15.36,15.36,0,0,0-11.29-4.47,15,15,0,0,0-11.15,4.45q-4.42,4.44-4.42,12.47t4.44,12.53A15.13,15.13,0,0,0,233.21,90.65Z"
          />
          <path
            className="cls-1"
            d="M318.29,45.07h14.82v57.54H319.05V95q-7.5,9-19.72,9a29,29,0,0,1-11.08-2.13,27.73,27.73,0,0,1-9.08-6,27.05,27.05,0,0,1-6.11-9.54,34.16,34.16,0,0,1-2.24-12.55,32.63,32.63,0,0,1,3.8-15.93A26.34,26.34,0,0,1,285,47.28a29.5,29.5,0,0,1,14.69-3.67q11.31,0,18.64,7.06ZM301.37,90.81a15.16,15.16,0,0,0,11.16-4.47Q317,81.87,317,73.79c0-5.36-1.49-9.51-4.47-12.48a16.33,16.33,0,0,0-22.42,0q-4.47,4.47-4.47,12.45t4.5,12.52A15.2,15.2,0,0,0,301.37,90.81Z"
          />
          <path
            className="cls-1"
            d="M386.18,45.07h16.71l-31.36,79.64h-15l9.06-22.63-24.25-57h17L372.61,83Z"
          />
          <path
            className="cls-1"
            d="M444.81,43.5a28.59,28.59,0,0,1,11,2.13,27.34,27.34,0,0,1,9,6,27,27,0,0,1,6,9.56A34.82,34.82,0,0,1,473,73.73a34.65,34.65,0,0,1-2.18,12.48,26.31,26.31,0,0,1-15.06,15.52,29.73,29.73,0,0,1-11.26,2.12q-11.06,0-18.27-7.11v26.13h-15v-78H426v5.93A26.13,26.13,0,0,1,444.81,43.5Zm-2.43,47.15a15.34,15.34,0,0,0,11.27-4.5q4.52-4.5,4.52-12.47t-4.5-12.45a15.35,15.35,0,0,0-11.29-4.47,15,15,0,0,0-11.15,4.45q-4.41,4.44-4.42,12.47t4.45,12.53A15.09,15.09,0,0,0,442.38,90.65Z"
          />
          <path className="cls-1" d="M483.5,102.61V25.29H499v77.32Z" />
          <path
            className="cls-1"
            d="M552.15,45.07h15.46v57.49H552.68v-6q-7.21,7.33-18.53,7.33-11,0-16.92-7.09T511.3,76.37V45.07h15.52v31.3c0,4.21,1,7.48,3,9.84s4.93,3.53,8.71,3.53a12.78,12.78,0,0,0,9.72-4q3.81-4,3.86-12Z"
          />
          <path
            className="cls-1"
            d="M638.25,45.07,615.94,73.79l22.2,28.82H620.58L607.05,85.16l-13.58,17.45H575.85l22.42-28.82L576,45.07H593.8l13.31,17.29,13.47-17.29Z"
          />
        </Wrapper>
      )}
      {variant === "logo" && (
        <Wrapper
          id="Layer_1"
          width={size}
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 156 150"
          color={color}
          {...props}
        >
          <title>Px Logomark</title>
          <path
            className="cls-1"
            d="M62.21,1,2.91,40.54a3.72,3.72,0,0,0-1.65,3.09v62.74a3.72,3.72,0,0,0,1.65,3.09L62.21,149A3.71,3.71,0,0,0,68,145.91V4.09A3.71,3.71,0,0,0,62.21,1Z"
          />
          <path
            className="cls-2"
            d="M149.78,38.33,93.79,1A3.71,3.71,0,0,0,88,4.09V73.75A3.71,3.71,0,0,0,93.59,77l56-32.33A3.71,3.71,0,0,0,149.78,38.33Z"
          />
          <path
            className="cls-1"
            d="M88,105.47v40.44A3.71,3.71,0,0,0,93.79,149l59.3-39.54a3.72,3.72,0,0,0,1.65-3.09V71.23A3.71,3.71,0,0,0,149.18,68l-59.3,34.24A3.7,3.7,0,0,0,88,105.47Z"
          />
        </Wrapper>
      )}
    </Fragment>
  );
};

/**
 * styles
 */
const Wrapper = styled.svg`
  ${({ color }) => {
    switch (color) {
      case "grey":
        return `
            .cls-1 {
                fill: var(--steel);
            }
            .cls-2 {
                fill: var(--cloudy-blue);
            }    
        `;

      default:
        return `
            .cls-1{
                fill: var(--navy);
            }
            .cls-2{
                fill:var(--clear-blue);
            }
        `;
    }
  }}
`;

Logo.propTypes = propTypes;
Logo.defaultProps = defaultProps;

export default Logo;
