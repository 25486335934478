import React, { Fragment } from "react";
import startCase from "lodash/startCase";
import PropTypes from "prop-types";

import { transactionProgressStatus } from "../../index";
import ProgressComponent from "../../../Transaction/Progress";
import AlertComponent from "../../../Alert";
import ModalComponent from "../../../Modal";
import CheckIcon from "../../../../Icons/Check";
import Asset from "./PendingOrder/Asset";

/**
 * props definition
 */
const propTypes = {
  /**
   * details of order from API
   */
  order: PropTypes.object.isRequired,

  /**
   * onHide func for Modal
   */
  onHide: PropTypes.func.isRequired,
};

const PendingOrder = ({ id, onHide, order, mutate }) => {
  /**
   * variables
   */
  const assetType = order?.ecurrency?.type?.toLowerCase();

  return (
    <ModalComponent.Dark show={Boolean(id)} onHide={onHide} size="md">
      <ModalComponent.Body className="py-6 px-4 px-md-6 white-bg">
        <Fragment>
          <div className="mb-8 d-flex justify-content-between align-items-center">
            <h5>
              {startCase(order?.order_type || "")} Order #{order?.id}
            </h5>
          </div>

          <div className="mx-md-10">
            <AlertComponent
              close
              color="var(--dark-mint)"
              icon={(props) => <CheckIcon variant="circle" {...props} />}
            >
              <small className="my-auto text-sub dark-mint-text">
                {startCase(order?.order_type || "")} order placed successfully!
              </small>
            </AlertComponent>

            <div className="my-10">
              <ProgressComponent
                items={transactionProgressStatus(order?.status?.slug)}
              />
            </div>

            {assetType === "crypto" ? (
              <Asset.Crypto {...{ order }} />
            ) : (
              <Asset.Fiat {...{ order }} />
            )}
          </div>

          <div className="footer">
            {assetType === "crypto" ? (
              <Asset.Crypto.Footer {...{ order, mutate }} />
            ) : (
              <Asset.Fiat.Footer {...{ order, mutate }} />
            )}
          </div>
        </Fragment>
      </ModalComponent.Body>
    </ModalComponent.Dark>
  );
};

PendingOrder.propTypes = propTypes;

export default PendingOrder;
