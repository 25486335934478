import Summary from "./SaleOrWithdrawal/Summary";
import CashPickup from "./SaleOrWithdrawal/CashPickup";
import BankDeposit from "./SaleOrWithdrawal/BankDeposit";
import MobileMoney from "./SaleOrWithdrawal/MobileMoney";
import CashoutWallet from "./SaleOrWithdrawal/CashoutWallet";

export default Object.assign(
  {},
  { Summary, CashPickup, BankDeposit, MobileMoney, CashoutWallet },
);
