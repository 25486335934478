import React from "react";
import Card from "react-bootstrap/Card";

const GraphInfo = ({ ...props }) => {
  return (
    <Card {...props}>
      <Card.Body className="p-6">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5>Chart header</h5>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default GraphInfo;
