import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Fade from "react-bootstrap/Fade";

import { handleStatusColor } from "../Utils/helpers";
import LinkComponent from "./Link";
import CloseIcon from "../Icons/Close";

/**
 * props definition
 */
const propTypes = {
  color: PropTypes.string,
  opacity: PropTypes.number,
  withBorder: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  variant: PropTypes.oneOf([
    "default",
    "primary",
    "success",
    "warning",
    "danger",
    "info",
  ]),
  icon: PropTypes.func,
  close: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

const defaultProps = {
  withBorder: false,
  opacity: 0.1,
};

/**
 *
 * @param {*} variant variant of alert
 * @param {*} withBorder show border
 * @param {*} color color of alert
 * @param {*} close function to run when closed
 * @param {*} icon icon for alert
 * @param {*} opacity opacity of alert background
 * @param {*} children content of alert
 */
const Alert = ({
  icon: Icon,
  withBorder,
  children,
  opacity,
  variant,
  close,
  color,
  ...props
}) => {
  /**
   * state
   */
  const [show, setShow] = useState(true);

  /**
   * variables
   */
  color = ((variant, color) => {
    if (color) {
      return color;
    }

    return handleStatusColor(variant);
  })(variant, color);

  return (
    <Fade in={show} mountOnEnter unmountOnExit>
      <Wrapper
        {...{
          color,
          opacity,
          withBorder,
          icon: Icon,
          ...props,
        }}
        role="alert"
      >
        {Icon && <Icon className="alert__icon" color={color} size={24} />}

        {children}

        {close && (
          <LinkComponent
            preventDefault
            className="alert__close"
            onClick={() => {
              if (typeof close === "function") {
                close();
              }
              return setShow(false);
            }}
          >
            <CloseIcon size={20} color="var(--bluey-grey)" />
          </LinkComponent>
        )}
      </Wrapper>
    </Fade>
  );
};

/**
 * styles
 */
const Wrapper = styled.div`
  grid-template-columns: ${({ icon }) => (icon ? "24px auto" : "100%")};
  word-break: break-word;
  border-radius: 0.5rem;
  position: relative;
  overflow: hidden;
  padding: 1rem;
  display: grid;
  gap: 0.75rem;
  z-index: 1;

  border: ${({ withBorder, color }) =>
    withBorder ? `solid 1px ${color}` : "none"};

  .alert__close {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
  }

  &::before {
    z-index: -1;
    width: 100%;
    height: 100%;
    content: " ";
    display: block;
    position: absolute;
    opacity: ${({ opacity }) => opacity};
    background-color: ${({ color }) => color};
  }

  p {
    color: var(--dark-grey-blue);
  }
`;

Alert.propTypes = propTypes;
Alert.defaultProps = defaultProps;

export default Alert;
