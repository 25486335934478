import React from "react";
import relativeTime from "dayjs/plugin/relativeTime";
import PropTypes from "prop-types";
import startCase from "lodash/startCase";
import styled from "styled-components";
import Image from "react-bootstrap/Image";
import dayjs from "dayjs";

import { transactionProgressStatus } from "../../index";
import * as helpers from "../../../../Utils/helpers";
import WavyCardComponent from "../../../WavyCard";
import PaymentDetails from "../../PaymentDetails";
import ModalComponent from "../../../Modal";
import PaymentMethod from "../../PaymentMethod";
import TabsComponent from "../../../Tabs";
import Progress from "../../../Transaction/Progress";
import SwapIcon from "../../../../Icons/Swap";
import Amount from "../../../Transaction/Amount";
import Status from "../../../Transaction/Status";
import Date from "../../../Transaction/Date";
// tabs
import Comments from "./ProcessedReservation/Comments";
import Details from "./ProcessedReservation/Details";

/**
 * props definition
 */
const propTypes = {
  /**
   * details of order from API
   */
  order: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  /**
   * onHide func for Modal
   */
  onHide: PropTypes.func.isRequired,
};

/**
 * variables
 */
const LightModalComponent = ModalComponent.Light;

/**
 * Sell Order
 *
 * Payment Method
 * Wallet Debit Transaction ID
 * Transaction Status
 * Amount Paid
 * Payment Date
 */

/**
 * Buy Order
 *
 * Payment Method
 * Prepaid Transaction ID
 * Wallet Debit Transaction ID
 * Transaction Status
 * Amount Paid
 * Payment Date
 */

const ProcessedReservation = ({ id, onHide, order, ...props }) => {
  dayjs.extend(relativeTime);

  /**
   * variables
   */
  const userTransaction = order?.user_transaction;
  const paymentDetailsItems = [
    {
      title: "Payment Method",
      desc: PaymentMethod({ transaction: order }),
    },
    ...(order?.order_type === "buy" && userTransaction
      ? [
          {
            title: "Prepaid Transaction ID",
            desc: `#${order?.user_transaction?.id}`,
          },
        ]
      : []),
    {
      title: "Wallet Debit Transaction ID",
      desc: `#${order?.wallet_transaction_id}`,
    },
    {
      title: "Transaction Status",
      desc: order?.status?.name?.toUpperCase(),
    },
    {
      title: "Amount Paid",
      desc: `${helpers.Money(order?.amount)} ${
        order?.wallet?.currency?.abbreviation
      }`,
    },
    ...(order?.processed_at
      ? [
          {
            title: "Payment Date",
            desc: dayjs(order?.processed_at).fromNow(),
          },
        ]
      : []),
  ];

  const isProcessed =
    order?.status?.slug ===
    `user-${order?.order_type?.toLowerCase()}-processed`;

  return (
    <LightModal show={Boolean(id)} onHide={onHide} size="sm">
      <div className="mb-10 text-center">
        <p className="mb-2">{startCase(order?.order_type)} Order</p>
        <p className="bluey-grey-text">
          <Date transaction={order} format="hh:mm A, DD MMM, YYYY" />
        </p>
      </div>

      <div className="my-12">
        <ModalComponent.Body>
          <WavyCardComponent
            className="p-6"
            wavesProps={{ color: "rgba(242,243,245,1)", radius: 5 }}
          >
            <div className="currency text-center">
              <div className="oval mx-auto mb-4">
                <Image
                  width={84}
                  src={`/assets/${order?.ecurrency?.short_name?.toLowerCase()}.svg`}
                />
              </div>
            </div>

            <div className="mb-8 position-relative">
              <div className="d-flex justify-content-between">
                <small className="text-sub text-uppercase bluey-grey-text fw-bolder">
                  Order #{order?.id}
                </small>
                <Status transaction={order} />
              </div>
            </div>

            <div className="mb-12 currency-block text-center">
              <p className="mb-12">
                {order?.ecurrency?.name} ({order?.ecurrency?.short_name})
              </p>
              <div className="my-4">
                <h1 className="font-monospace fw-bolder">
                  <Amount transaction={order} />
                </h1>
              </div>
              <SwapIcon
                direction="vertical"
                variant="circle"
                color="var(--cloudy-blue)"
              />
              <div className="my-4">
                <h1 className="font-monospace fw-bolder">
                  <Amount local={false} transaction={order} />
                </h1>
              </div>

              <div className="mb-12">
                <p className="bluey-grey-text">
                  {order?.ecurrency?.short_name}
                </p>
              </div>

              <Progress
                items={transactionProgressStatus(order?.status?.slug)}
              />
            </div>

            <TabWrapper>
              <TabsComponent
                childProps={{ order }}
                tabs={[
                  {
                    title: "Order Details",
                    content: Details,
                    eventKey: "details",
                  },
                  {
                    title: "Comments",
                    content: Comments,
                    eventKey: "comments",
                  },
                ]}
              />
            </TabWrapper>

            {isProcessed && (
              <PaymentDetails className="mt-8" items={paymentDetailsItems} />
            )}
          </WavyCardComponent>
        </ModalComponent.Body>
      </div>
    </LightModal>
  );
};

/**
 * styles
 */

const LightModal = styled(LightModalComponent)`
  .modal-content {
    padding-bottom: 1.75rem;

    @media (min-width: 768px) {
      top: 1rem;
    }

    @media (min-width: 992px) {
      top: 2.75rem;
    }

    @media (min-width: 1200px) {
      top: 4.5rem;
    }
  }

  .currency {
    transform: translateY(-3.75rem);
    position: absolute;
    margin: 0 auto;
    width: 100%;
    right: 0;
    left: 0;
  }

  .oval {
    height: 5.875rem;
    width: 5.875rem;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background: var(--pale-grey-three);
  }
`;

const TabWrapper = styled.div`
  .tabs__nav {
    justify-content: center;
    border-bottom: none;
    overflow-x: hidden;

    > a {
      text-transform: uppercase;
      font-size: 0.75rem;
      margin: 0 1.25rem;
      padding: 0.375rem 1rem;
    }

    .-active {
      color: var(--clear-blue);
    }
  }
`;

ProcessedReservation.propTypes = propTypes;

export default ProcessedReservation;
