import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 48,
};

const BankDeposit = ({ size, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h48v48H0z" />
      <path fill="#516276" d="M5 14h38v3H5z" />
      <path fill="#2B4059" d="M7 17h34v2H7z" />
      <path fill="#1C8AFF" d="M16 17h16v2H16z" />
      <path fill="#516276" d="M9 19h30v16H9zM6 35h36v2H6z" />
      <path fill="#2B4059" d="M4 37h40v2H4z" />
      <path fill="#516276" d="M2 39h44v2H2zM24 5l19 9H5z" />
      <path fill="#1C8AFF" d="M14 19h20v16H14z" />
      <path fill="#197BE3" d="M16 17h16v2H16z" />
      <path
        fill="#FFF"
        d="M23.437 30.11a4.557 4.557 0 0 1-1.479-.467 3.688 3.688 0 0 1-1.547-1.513c-.363-.657-.544-1.45-.544-2.38 0-.93.181-1.723.544-2.38a3.713 3.713 0 0 1 1.53-1.513 4.44 4.44 0 0 1 1.496-.474V19.8h1.547v1.57c.44.048.828.143 1.164.283a3.2 3.2 0 0 1 1.25.893c.317.38.55.807.697 1.283l-1.853.731c-.125-.555-.351-.975-.68-1.258a1.678 1.678 0 0 0-.578-.326v5.564a1.712 1.712 0 0 0 .969-.665c.181-.25.312-.55.391-.901l1.785.612a3.27 3.27 0 0 1-.714 1.36c-.34.385-.77.686-1.292.901a4.262 4.262 0 0 1-1.139.285V31.7h-1.547v-1.59zm0-7.087a2.15 2.15 0 0 0-.85.568c-.442.476-.663 1.201-.663 2.176 0 .657.105 1.196.314 1.615.21.42.496.731.859.935.11.061.223.114.34.157v-5.451z"
      />
      <circle cx="24" cy="12" r="2" fill="#D8D8D8" />
    </g>
  </svg>
);

BankDeposit.propTypes = propTypes;
BankDeposit.defaultProps = defaultProps;

export default BankDeposit;
