import React, { useState } from "react";
import { Formik } from "formik";
import { object } from "yup";
import Fade from "react-fade-in";
import Form from "react-bootstrap/Form";

import * as helpersUtil from "../../../Utils/helpers";
import * as schemaUtil from "../../../Utils/schema";
import ButtonComponent from "../../Button";
import SelectComponent from "../../Select";
import AlertComponent from "../../Alert";
import FieldComponent from "../../Field";
import GridStyle from "../../../Styles/Grid";
import InfoIcon from "../../../Icons/Info";

const CashPickup = ({
  wallet,
  methods,
  paramKey,
  handleSubmit,
  transactionData,
}) => {
  /**
   * state
   */
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <Fade>
      <AlertComponent icon={InfoIcon} className="small navy-text lh-13 mb-8">
        Cash pickup takes up 3 - 6 hours to be ready depending on the amount and
        time of the request. A secret / QR code will be generated after your
        cash pickup order has been created. You are to come to the pickup point
        you selected with this secret / QR code and a valid ID to pickup up your
        cash. Do NOT share this secret / QR code with anyone else aside from the
        cashier at the pickup point.
      </AlertComponent>

      <GridStyle gaps={{ sm: "2rem" }} columns={{ md: "23.125rem 15rem" }}>
        <div className="order-md-2">
          <div className="text-center border ice-blue-border pale-grey-bg rounded-3 p-6 py-md-12">
            <p className="fw-bold mb-2">You will be receiving</p>
            <h2 className="text-wrap font-monospace fw-bolder">
              {`${helpersUtil.Money(transactionData?.amount)} ${
                wallet?.currency?.abbreviation
              }`}
            </h2>
          </div>
        </div>
        <div className="order-md-1 border ice-blue-border pale-grey-bg rounded-3 p-6">
          <Formik
            validateOnMount
            validationSchema={object({
              [paramKey]: object().shape({
                branch: schemaUtil.requireString("Branch"),
              }),
            })}
            initialValues={{
              [paramKey]: {
                branch: "",
              },
            }}
            onSubmit={(params, actions) => {
              setSubmitting(true);

              const branchId = params[paramKey].branch;

              params[paramKey].branch = methods?.find(
                ({ id }) => id === branchId,
              )?.name;
              params[paramKey].payment_method_id = branchId;

              return handleSubmit(
                { payment_method_id: branchId, ...params },
                { ...actions, setSubmitting },
              );
            }}
          >
            {({
              isValid,
              handleSubmit,
              setFieldValue,
              values: {
                [paramKey]: { branch },
              },
            }) => (
              <Form>
                <FieldComponent
                  value={branch}
                  name={`${paramKey}.branch`}
                  component={SelectComponent}
                  placeholder="Select Cash Pickup Point"
                  onChange={({ value }) =>
                    setFieldValue(`${paramKey}.branch`, value)
                  }
                  options={
                    methods?.map(({ id, name, details }) => ({
                      value: id,
                      label: `${name}${
                        details?.Location ? ` - ${details?.Location}` : ""
                      }`,
                    })) || []
                  }
                />

                {/* Show related details for the selected payment method */}
                {methods?.filter((m) => m.id === branch)[0]?.details && (
                  <div className="mb-6">
                    {Object.keys(
                      methods?.filter((m) => m.id === branch)[0]?.details,
                    ).map((item, key) => {
                      /**
                       * variable for the selected payment method
                       */
                      const selectedPaymentMethod = methods?.filter(
                        (m) => m.id === branch,
                      )[0];

                      return (
                        <div key={key} className="mb-2">
                          <small className="bluey-grey-text mb-1">{item}</small>
                          <p className="fw-bold">
                            {selectedPaymentMethod?.details[item]}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}

                <ButtonComponent
                  type="submit"
                  onClick={handleSubmit}
                  {...{ isValid, isSubmitting }}
                >
                  Continue
                </ButtonComponent>
              </Form>
            )}
          </Formik>
        </div>
      </GridStyle>
    </Fade>
  );
};

export default CashPickup;
