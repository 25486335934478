import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 48,
};

const MobileMoney = ({ size, ...props }) => (
  <svg
    fill="none"
    width={size}
    height={size}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="32" y="24" width="10" height="4" fill="#F6CF44" />
    <rect opacity="0.0385975" x="37" y="24" width="5" height="4" fill="black" />
    <rect x="32" y="28" width="10" height="4" fill="#FFB121" />
    <rect opacity="0.0385975" x="37" y="28" width="5" height="4" fill="black" />
    <rect x="32" y="32" width="10" height="4" fill="#FFC642" />
    <rect opacity="0.0385975" x="37" y="32" width="5" height="4" fill="black" />
    <rect x="32" y="36" width="10" height="4" fill="#FFB121" />
    <rect opacity="0.0385975" x="37" y="36" width="5" height="4" fill="black" />
    <rect x="32" y="40" width="10" height="4" fill="#FFC642" />
    <rect opacity="0.0385975" x="37" y="40" width="5" height="4" fill="black" />
    <rect x="32" y="8" width="10" height="4" fill="#FFC642" />
    <rect opacity="0.0385975" x="37" y="8" width="5" height="4" fill="black" />
    <rect x="32" y="12" width="10" height="4" fill="#FFB121" />
    <rect opacity="0.0385975" x="37" y="12" width="5" height="4" fill="black" />
    <rect x="32" y="16" width="10" height="4" fill="#FFC642" />
    <rect opacity="0.0385975" x="37" y="16" width="5" height="4" fill="black" />
    <rect x="32" y="20" width="10" height="4" fill="#FFB121" />
    <rect opacity="0.0385975" x="37" y="20" width="5" height="4" fill="black" />
    <rect x="32" y="24" width="10" height="4" fill="#FFC642" />
    <rect opacity="0.0385975" x="37" y="24" width="5" height="4" fill="black" />
    <rect x="12" y="4" width="24" height="40" rx="2" fill="#2B4059" />
    <rect x="14" y="7" width="20" height="30" rx="1" fill="#1C8AFF" />
    <rect x="21" y="39" width="6" height="3" rx="1.5" fill="#516276" />
    <rect x="7" y="24" width="10" height="4" fill="#FFC642" />
    <rect opacity="0.0385975" x="12" y="24" width="5" height="4" fill="black" />
    <rect x="7" y="28" width="10" height="4" fill="#FFB121" />
    <rect opacity="0.0385975" x="12" y="28" width="5" height="4" fill="black" />
    <rect x="7" y="32" width="10" height="4" fill="#FFC642" />
    <rect opacity="0.0385975" x="12" y="32" width="5" height="4" fill="black" />
    <rect x="7" y="36" width="10" height="4" fill="#FFB121" />
    <rect opacity="0.0385975" x="12" y="36" width="5" height="4" fill="black" />
    <rect x="7" y="40" width="10" height="4" fill="#FFC642" />
    <rect opacity="0.0385975" x="12" y="40" width="5" height="4" fill="black" />
  </svg>
);

MobileMoney.propTypes = propTypes;
MobileMoney.defaultProps = defaultProps;

export default MobileMoney;
