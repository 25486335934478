import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 48,
};

const MobilePayment = ({ size, ...props }) => (
  <svg
    fill="none"
    width={size}
    height={size}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="12" y="4" width="24" height="40" rx="2" fill="#2B4059" />
    <rect x="14" y="6" width="20" height="31" rx="1" fill="#F2F3F5" />
    <rect x="21" y="39" width="6" height="3" rx="1.5" fill="#516276" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 21C19.1046 21 20 20.1046 20 19C20 17.8954 19.1046 17 18 17C16.8954 17 16 17.8954 16 19C16 20.1046 16.8954 21 18 21Z"
      fill="#C0C6CE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 27C19.1046 27 20 26.1046 20 25C20 23.8954 19.1046 23 18 23C16.8954 23 16 23.8954 16 25C16 26.1046 16.8954 27 18 27Z"
      fill="#C0C6CE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 33C19.1046 33 20 32.1046 20 31C20 29.8954 19.1046 29 18 29C16.8954 29 16 29.8954 16 31C16 32.1046 16.8954 33 18 33Z"
      fill="#C0C6CE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 21C25.1046 21 26 20.1046 26 19C26 17.8954 25.1046 17 24 17C22.8954 17 22 17.8954 22 19C22 20.1046 22.8954 21 24 21Z"
      fill="#C0C6CE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 27C25.1046 27 26 26.1046 26 25C26 23.8954 25.1046 23 24 23C22.8954 23 22 23.8954 22 25C22 26.1046 22.8954 27 24 27Z"
      fill="#C0C6CE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 33C25.1046 33 26 32.1046 26 31C26 29.8954 25.1046 29 24 29C22.8954 29 22 29.8954 22 31C22 32.1046 22.8954 33 24 33Z"
      fill="#C0C6CE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 21C31.1046 21 32 20.1046 32 19C32 17.8954 31.1046 17 30 17C28.8954 17 28 17.8954 28 19C28 20.1046 28.8954 21 30 21Z"
      fill="#C0C6CE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 27C31.1046 27 32 26.1046 32 25C32 23.8954 31.1046 23 30 23C28.8954 23 28 23.8954 28 25C28 26.1046 28.8954 27 30 27Z"
      fill="#C0C6CE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30 33C31.1046 33 32 32.1046 32 31C32 29.8954 31.1046 29 30 29C28.8954 29 28 29.8954 28 31C28 32.1046 28.8954 33 30 33Z"
      fill="#C0C6CE"
    />
    <rect x="19" y="11" width="2" height="2" fill="#768493" />
    <rect x="23" y="11" width="2" height="2" fill="#768493" />
    <rect x="27" y="11" width="2" height="2" fill="#768493" />
  </svg>
);

MobilePayment.propTypes = propTypes;
MobilePayment.defaultProps = defaultProps;

export default MobilePayment;
