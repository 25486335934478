import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
  preventDefault: PropTypes.bool,
  stopPropagation: PropTypes.bool,
};

const defaultProps = {
  href: "/",
  onClick: () => {},
  preventDefault: false,
  stopPropagation: false,
};

const Link = ({
  href,
  onClick,
  children,
  preventDefault,
  stopPropagation,
  ...props
}) => (
  <a
    href={href}
    onClick={(e) => {
      if (preventDefault) e.preventDefault();
      if (stopPropagation) e.stopPropagation();
      return onClick();
    }}
    {...props}
  >
    {children}
  </a>
);

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;

export default Link;
