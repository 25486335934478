import React, { Fragment } from "react";
import Fade from "react-fade-in";

const Instructions = ({ order }) => {
  const instruction = order?.payment_method?.withdraw_instructions;
  const location = order?.payment_method?.details?.Location;

  return (
    <Fade>
      {order ? (
        <Fragment>
          <p className="bluey-grey-text my-10">
            {instruction ||
              "Visit our cash office to complete payment for your transaction. Come along with your ID card and the corresponding amount. Our cash agent will assist you further."}
          </p>

          <p className="black-text mb-6 text-uppercase">
            {location ||
              "Office Location: Achimota, Opposite Treet Restaurant (Near Achimota New station)"}
          </p>
        </Fragment>
      ) : (
        <p className="bluey-grey-text text-center">No comment</p>
      )}
    </Fade>
  );
};

export default Instructions;
