import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// import useWidthHook from "../Hooks/useWidth";
import WalletInfo from "./WalletCards/WalletInfo";
import GraphInfo from "./WalletCards/GraphInfo";

const propTypes = {
  /**
   * object of wallet data
   * see {@link WalletInfo} for props breakdown
   */
  wallet: PropTypes.object,
  /**
   * object of graph data
   * see {@link GraphInfo} for props breakdown
   */
  graph: PropTypes.object,
};

const WalletCards = ({ wallet, graph, ...props }) => {
  /**
   * variables
   */
  // const width = useWidthHook();

  return (
    <Wrapper {...props}>
      <WalletInfo {...wallet} />
      {/* {width >= 992 && <GraphInfo {...graph} />} */}
    </Wrapper>
  );
};

/**
 * styles
 */
const Wrapper = styled.div`
  gap: 2rem;
  display: grid;
  margin-bottom: 2rem;

  @media (min-width: 1400px) {
    grid-template-columns: 40% minmax(0, 1fr);
  }
`;

WalletCards.propTypes = propTypes;

export default Object.assign(WalletCards, { WalletInfo, GraphInfo });
