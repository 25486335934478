import React, { Fragment, useState } from "react";
import { object, number } from "yup";
import { Formik } from "formik";
import TabContainer from "react-bootstrap/TabContainer";
import TabContent from "react-bootstrap/TabContent";
import TabPane from "react-bootstrap/TabPane";
import styled from "styled-components";
import Nav from "react-bootstrap/Nav";
import PropTypes from "prop-types";
import Fade from "react-fade-in";

// import FilteredLocations from './Agent/Components/FilteredLocations';
import EVoucherIllustration from "../../../../Illustrations/EVoucher";
import * as helpersUtil from "../../../../Utils/helpers";
import WrapperStyle from "../../../../Styles/Wrapper";
import GridStyle from "../../../../Styles/Grid";
import ButtonComponent from "../../../Button";
import SelectComponent from "../../../Select";
import FieldComponent from "../../../Field";
import TableComponent from "../../../Table";
import LinkComponent from "../../../Link";

const propTypes = {
  /**
   * key to identify the transaction section
   */
  paramKey: PropTypes.string,
  /**
   * user wallet
   */
  wallet: PropTypes.object,

  /**
   * methods are all the filtered banks under the bank payment methods
   */
  methods: PropTypes.array,

  /**
   * used to toggle between payment information screen and payment form screen
   */
  setHasPaid: PropTypes.func,

  /**
   * transactionData is the data collection in initial stages of transaction data collection
   */
  transactionData: PropTypes.object,
  /**
   * handleSubmit is for form submission
   */
  handleSubmit: PropTypes.func,
  /**
   * onSuccess for successful transaction request
   */
  onSuccess: PropTypes.func,
};

const Agent = ({
  paramKey,
  wallet,
  methods,
  transactionData,
  handleSubmit,
  onSuccess,
}) => {
  /**
   * props
   *
   * GET EVOUCHER METHODS
   */
  methods = methods?.filter((method) => method?.type === "e-voucher-agent");

  /**
   * states
   */
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedMethod, setSelectedMethod] = useState(methods[0]);
  const [selectedRegion, setSelectedRegion] = useState("All Regions");

  /**
   * functions
   */
  const handleRowSelect = (m) => {
    if (selectedMethod?.id === m?.id) {
      setSelectedMethod(null);
    } else {
      setSelectedMethod(methods?.find(({ id }) => m?.id === id));
    }
  };
  const sortAlphabetical = (array) => array.sort((a, b) => a.localeCompare(b));
  const onlyUnique = (value, index, self) => self?.indexOf(value) === index;
  const filteredLocation = (methods) =>
    methods?.filter((m) =>
      selectedRegion === "All Regions"
        ? m?.location_region
        : selectedRegion === m?.location_region,
    );

  /**
   * variables
   */
  const Th = TableComponent.Th;
  const Td = TableComponent.Td;

  const methodsRegions = methods
    ?.filter((m) => m?.location_region)
    .map((value) => value?.location_region);

  const cities = sortAlphabetical(
    filteredLocation(methods)
      ?.filter((m) => m?.location)
      .map((value) => value?.location)
      .filter(onlyUnique),
  );

  const regions = ["All Regions"].concat(methodsRegions?.filter(onlyUnique));

  /**
   * components
   */
  const FilteredLocations = () => (
    <Fragment>
      <thead className="no-borders">
        <tr>
          <Th> </Th>
          <Th>Name</Th>
          <Th>Phone</Th>
          <Th>Address</Th>
        </tr>
      </thead>
      <tbody className="table-body">
        {filteredLocation(methods)
          ?.filter((e) =>
            cities?.includes(selectedCity) ? e.location === selectedCity : e,
          )
          ?.map((m, key) =>
            m?.details ? (
              <tr
                key={m?.id + key}
                className={m?.id === selectedMethod?.id ? "selectedRow" : ""}
              >
                <Td size="small">
                  <FieldComponent.Checkbox
                    type="radio"
                    name="selectedLocation"
                    style={{ gap: "0" }}
                    className="align-items-center"
                    onChange={() => handleRowSelect(m)}
                    checked={m?.id === selectedMethod?.id}
                  />
                </Td>
                <Td tdClass="medium" onClick={() => handleRowSelect(m)}>
                  {m?.name}
                </Td>
                <Td>
                  {m?.details?.phone ? (
                    <a href={`tel:+${m?.details?.phone}`}>
                      {m?.details?.phone}
                    </a>
                  ) : (
                    "--"
                  )}
                </Td>
                <Td tdClass="large">
                  {m?.details?.exact_location || "--"}.
                  {m?.details?.map_location ? (
                    <LinkComponent
                      href={m?.details.map_location}
                      className="ms-1"
                      target="_blank"
                    >
                      view on maps <EVoucherIllustration size={16} />
                    </LinkComponent>
                  ) : (
                    "--"
                  )}
                </Td>
              </tr>
            ) : (
              <tr key={key}>
                <Td>--</Td>
                <Td>--</Td>
                <Td>--</Td>
                <Td>--</Td>
              </tr>
            ),
          )}
      </tbody>
    </Fragment>
  );

  const SelectRegion = () => (
    <TabContainer
      id="left-side-nav"
      defaultActiveKey={
        cities?.includes(selectedCity) ? selectedCity : cities[0]
      }
    >
      <div className="row">
        <div className="py-2 px-5 locations-column col-sm-2">
          <Nav variant="pills" className="nav flex-column">
            {cities?.map((c) => (
              <Nav.Item key={c}>
                <Nav.Link onClick={() => setSelectedCity(c)} eventKey={c}>
                  {c}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>
        <div className="col-sm-10">
          <TabContent>
            {cities?.map((c) => (
              <TabPane key={c} eventKey={c}>
                <FilteredLocations />
              </TabPane>
            ))}
          </TabContent>
        </div>
      </div>
    </TabContainer>
  );

  return (
    <Fragment>
      <Fade>
        <GridStyle
          className="mb-6"
          columns={{ md: "1fr 250px" }}
          gaps={{ sm: "1.5rem", md: "2.5rem" }}
        >
          <div className="mb-6">
            <p className="mb-4">
              Select your region and then a location closest to you. From the
              list of agents, chose an agent closest to you and click on proceed
              to generate a QR code. Next, go to the agent's location with your
              ID card and QR Code to make payment.
            </p>
            <p>
              If you need help, click here to use the{" "}
              <a href={process.env.REACT_APP_SUPPORT_URL} target="support">
                livechat
              </a>{" "}
              to get support.
            </p>
          </div>

          <div>
            <div className="text-center border ice-blue-border pale-grey-bg rounded-3 p-6 py-md-12">
              <p className="fw-bold mb-2">
                {transactionData?.type === "credit" ? "paying" : "receiving"}
              </p>
              <h2 className="text-wrap font-monospace fw-bolder">
                {`${helpersUtil.Money(transactionData?.amount)} ${
                  wallet?.currency?.abbreviation
                }`}
              </h2>
            </div>
          </div>
        </GridStyle>

        {/* SEcond Batch */}
        <GridStyle
          className="my-4"
          columns={{ md: "250px 300px" }}
          gaps={{ sm: "0.5rem", md: "2.5rem" }}
        >
          <div className="mb-3">
            <FieldComponent
              name="region"
              withFormik={false}
              value={selectedRegion}
              label="Select Region"
              wrapperClassName="mb-0"
              component={SelectComponent}
              onChange={({ value }) => setSelectedRegion(value)}
              options={regions?.map((region) => ({
                value: region,
                label: region,
              }))}
            />
          </div>
        </GridStyle>

        <TableComponent
          hover
          count={7}
          title="Agent Locations"
          isLoading={!filteredLocation(methods) && !selectedRegion}
          isEmpty={
            methods?.length === 0 && {
              heading: "No payment methods available",
              desc: "All your payment methods will appear here once they are available.",
            }
          }
        >
          <TableContentStyled className="px-4 px-md-6">
            <SelectRegion />
          </TableContentStyled>
        </TableComponent>

        <WrapperStyle awidth={400} className="mt-6 mx-auto">
          <div className="d-flex justify-content-center">
            <Formik
              validateOnMount
              enableReinitialize
              validationSchema={object({
                payment_method_id: number().typeError().required(),
              })}
              initialValues={{
                payment_method_id: selectedMethod?.id,
                [paramKey]: {
                  payment_method_id: selectedMethod?.id,
                  ...selectedMethod?.details,
                },
              }}
              onSubmit={(params, { setSubmitting, setErrors }) => {
                handleSubmit(params)
                  .then((response) => onSuccess(response))
                  .catch((error) => setErrors(error?.fields))
                  .finally(() => setSubmitting(false));
              }}
            >
              {({ isSubmitting, isValid, handleSubmit }) => (
                <ButtonComponent
                  onClick={handleSubmit}
                  {...{ isValid, isSubmitting }}
                >
                  Proceed
                </ButtonComponent>
              )}
            </Formik>
          </div>
        </WrapperStyle>
      </Fade>
    </Fragment>
  );
};

const TableContentStyled = styled.div`
  .locations-column {
    background-color: var(--pale-grey);

    a {
      color: black;

      &.active {
        color: var(--clear-blue);
      }
    }
  }

  .nav-item {
    + .nav-item {
      margin-top: 1.5rem;
    }
  }

  th {
    color: black;
  }

  .fade:not(.show) {
    display: none;
  }

  .table-body {
    > * > *.large {
      width: 100vw;
      max-width: unset;
      word-break: break-word;

      @media (min-width: 992px) {
        & .text-truncate {
          all: unset;
        }
      }
    }
  }

  tr {
    cursor: pointer;

    &.selectedRow,
    &:hover {
      background-color: var(--bs-table-hover-bg);
    }

    th > div,
    td > div {
      padding: 0.5rem;
    }
  }
`;

Agent.propTypes = propTypes;

export default Agent;
