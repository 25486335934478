import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import Skeleton from "react-loading-skeleton-2";
import BSTable from "react-bootstrap/Table";
import styled from "styled-components";
import Card from "react-bootstrap/Card";

import PaginationComponent from "./Pagination";
import FieldComponent from "./Field";
import SearchIcon from "../Icons/Search";
import ArrowIcon from "../Icons/Arrow";
import loopUtil from "../Utils/loop";
import NoRecord from "./Table/NoRecord";
import Th from "./Table/Th";
import Td from "./Table/Td";

const propTypes = {
  count: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      desc: PropTypes.string,
    }),
  ]),
  pagination: PropTypes.shape({
    page: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
    setPage: PropTypes.func.isRequired,
    setLimit: PropTypes.func.isRequired,
  }),
  search: PropTypes.shape({
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
  }),
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

const defaultProps = {
  count: 7,
  isLoading: true,
  onLimit: () => {},
};

const Table = ({
  count,
  title,
  search,
  isEmpty,
  children,
  isLoading,
  pagination,
}) => {
  /**
   * variables
   */
  const limits = [5, 10, 20, 50, 100];

  return (
    <Card>
      <Card.Body>
        <div
          className={`px-4 px-md-6 ${
            search
              ? "py-4 d-flex x-spacing-4 justify-content-between align-items-center"
              : `py-6`
          }`}
        >
          <h5 className="text-truncate">{title}</h5>
          {search && (
            <SearchWrapper>
              <FieldComponent
                name="search"
                component="input"
                withFormik={false}
                value={search.value || ""}
                wrapperClassName="mb-0 h-100"
                className="h-100 form-control"
                containerClassName="px-3 px-md-4 h-100"
                placeholder={search.placeholder || "Search..."}
                prefix={<SearchIcon size={20} color="var(--bluey-grey)" />}
                onChange={({ currentTarget: { value } }) =>
                  search.onSearch(value)
                }
              />
            </SearchWrapper>
          )}
        </div>
        <BSTable hover responsive>
          {isLoading ? (
            <Fragment>
              <thead>
                <tr>
                  {loopUtil(count).map((index) => (
                    <Th key={index}>
                      <Skeleton height={12} width={100} />
                    </Th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {loopUtil(10).map((index) => (
                  <tr key={index}>
                    {loopUtil(count).map((index) =>
                      index === 1 ? (
                        <Td key={index}>
                          <Skeleton width={20} height={20} circle />
                          <Skeleton width={96} height={14} />
                        </Td>
                      ) : (
                        <Td key={index}>
                          <Skeleton height={14} width={120} />
                        </Td>
                      ),
                    )}
                  </tr>
                ))}
              </tbody>
            </Fragment>
          ) : (
            <Fragment>
              {isEmpty ? <NoRecord {...isEmpty} /> : children}
            </Fragment>
          )}
        </BSTable>
        {pagination && !isEmpty && (
          <Footer className="pt-0">
            <div className="d-flex align-items-center x-spacing-2">
              <small>Show</small>
              <Dropdown>
                <Dropdown.Toggle className="p-2">
                  <span className="ps-1">{pagination?.limit || 10}</span>
                  <ArrowIcon variant="dropdown" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {limits.map((limit, key) => (
                    <Dropdown.Item
                      key={key}
                      active={limit === pagination?.perPage}
                      onClick={() =>
                        pagination.setLimit ? pagination.setLimit(limit) : {}
                      }
                    >
                      {limit}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <small>entries</small>
            </div>

            <PaginationComponent {...pagination} />
          </Footer>
        )}
      </Card.Body>
    </Card>
  );
};

/**
 * styles
 */
const Footer = styled.div`
  display: flex;
  padding: 1.5rem 1rem;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  .dropdown-toggle {
    border: solid 0.125rem #e0e4e8;
  }

  @media (min-width: 992px) {
    align-items: center;
    flex-direction: row;
    padding-left: 1.5rem;
    justify-content: space-between;

    & > *:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

const SearchWrapper = styled.div`
  width: 10.25rem;
  height: 2.375rem;

  .form-control {
    padding-top: 0rem;
    padding-right: 0rem;
    font-size: 0.875rem;
    padding-bottom: 0rem;
  }

  @media (min-width: 768px) {
    width: 16.5rem;
  }
`;

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default Object.assign(Table, { Td, Th });
