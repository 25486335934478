import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const propTypes = {
  bold: PropTypes.bool,
  font: PropTypes.oneOf(["number", "text"]),
  direction: PropTypes.oneOf(["start", "center", "end"]),
};

const defaultProps = {
  bold: false,
  font: "text",
  direction: "start",
};

const Td = ({ children, tdClass, ...props }) => {
  const TdClass = tdClass
    ? Array.isArray(tdClass)
      ? tdClass
      : [tdClass]
    : null;

  return (
    <td width="7.75rem" className={TdClass}>
      <Wrapper className="text-truncate x-spacing-4" {...props}>
        {children}
      </Wrapper>
    </td>
  );
};

/**
 * styles
 */
const Wrapper = styled.div`
  display: flex;
  height: 3.25rem;
  align-items: center;
  padding: 0.5rem 1rem;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  font-family: ${({ font }) => {
    switch (font) {
      case "number":
        return "var(--font-family-titillium)";
      default:
        return "var(--font-family-worksans)";
    }
  }};
  justify-content: ${({ direction }) => {
    switch (direction) {
      case "start":
      case "end":
        return `flex-${direction}`;
      default:
        return "center";
    }
  }};

  img {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    object-fit: cover;
    object-position: center;
  }

  @media (min-width: 768px) {
    padding: 0.5rem 1.5rem;
  }
`;

Td.propTypes = propTypes;
Td.defaultProps = defaultProps;

export default Td;
