import React, { Fragment, useRef, useGlobal } from "reactn";
import ReactToPrint from "react-to-print";
import queryString from "query-string";
import PropTypes from "prop-types";
import startCase from "lodash/startCase";
import Skeleton from "react-loading-skeleton-2";
import styled from "styled-components";
import useSWR from "swr";

import { transactionType } from "../index";
import GoogleMapsPreview from "../../GoogleMapsPreview";
import * as helpers from "../../../Utils/helpers";
import ButtonComponent from "../../Button";
import TransactionDate from "../Date";
import PaymentDetails from "../PaymentDetails";
import ModalComponent from "../../Modal";
import PaymentMethod from "../PaymentMethod";
import IconAndAmount from "./Wallet/index";
import GridStyle from "../../../Styles/Grid";
import loopUtil from "../../../Utils/loop";
import useUser from "../../../Hooks/useUser";
import TabsComponent from "../../Tabs";
import QRCode from "../../QRCode";
import Status from "../Status";

// tabs
import Instructions from "./Wallet/Evoucher/Instructions";
import Details from "./Wallet/Evoucher/Details";

/**
 * props definition
 */
const propTypes = {
  /**
   * details of transaction from API
   */
  transaction: PropTypes.object,
  /**
   * modal props
   */
};

const defaultProps = {};

const Wallet = ({ id, transaction: order, onHide }) => {
  /**
   * global state
   */
  const [token, setToken] = useGlobal("token");

  /**
   * hook
   */
  const { user } = useUser({ token, setToken });

  /**
   * apis
   */
  const { data, error, mutate } = useSWR(
    !order &&
      Boolean(id) &&
      `/transactions/${id}?${queryString.stringify({
        with_ecurrency: true,
        with_transaction: true,
        with_crypto_details: true,
      })}`,
  );

  const { data: loyaltyPoints } = useSWR(
    Boolean(id) && user &&
    `${process.env.REACT_APP_LOYALTY_API_BASEURL}/user/${user.id}/loyalty-points/${id}`,
  );
  const isLoyaltyPointEarned = loyaltyPoints && Boolean(loyaltyPoints.point);


  /**
   * ref
   */
  const printableRef = useRef();

  /**
   * variables
   */
  const transaction = Boolean(id) && (order || data);
  const type = Boolean(id) && transactionType(transaction);
  const transactionDetails =
    Boolean(id) &&
    (() => {
      if (transaction?.transaction_details) {
        try {
          return JSON.parse(transaction.transaction_details);
        } catch (error) {
          return false;
        }
      }
    })();

  return (
    <ModalComponent.Light show={Boolean(id)} size="sm" {...{ onHide }}>
      <ModalComponent.Body className="p-6 px-md-8 white-bg">
        {/* skeleton */}
        {!order && !data && !error && (
          <Fragment>
            <div className="d-flex justify-content-between mb-10">
              <Skeleton height={14} width={132} />
              <Skeleton height={14} width={72} />
            </div>

            <div className="mb-10">
              <IconAndAmount.Skeleton />
            </div>

            <GridStyle
              gaps={{ sm: "2rem" }}
              columns={{ sm: "repeat(2, minmax(0, 1fr))" }}
              className="mb-10 justify-content-between"
            >
              {loopUtil(3 * 2).map((index) => (
                <div
                  key={index}
                  className={`d-flex x-spacing-4 ${
                    index % 2 === 0
                      ? "justify-content-start"
                      : "justify-content-end"
                  } `}
                >
                  <Skeleton width={144} height={14} />
                </div>
              ))}
            </GridStyle>

            <div>
              <Skeleton height={48} />
            </div>
          </Fragment>
        )}

        {transaction && (
          <Fragment>
            <div className="print__area" ref={printableRef}>
              <div className="d-flex align-items-center justify-content-between mb-10">
                <small className="text-sub text-uppercase bluey-grey-text">
                  {[process.env.REACT_APP_EVOUCHER_TYPE].includes(
                    transaction?.payment_method?.type,
                  )
                    ? ""
                    : "Transaction"}{" "}
                  #{transaction?.id}
                </small>
                <Status {...{ transaction }} />
              </div>

              {![process.env.REACT_APP_EVOUCHER_TYPE].includes(
                transaction?.payment_method?.type,
              ) && <IconAndAmount className="mb-10" {...{ transaction }} />}

              {[process.env.REACT_APP_EVOUCHER_TYPE].includes(
                transaction?.payment_method?.type,
              ) && transaction?.status?.name?.toLowerCase() !== "processed" ? (
                <Fragment>
                  {/* cash and e e-voucher */}
                  <div className="text-center mb-10">
                    <div className="mb-6 text-center">
                      <QRCode
                        value={JSON.stringify({
                          type,
                          email: user?.email,
                          depositId: transaction?.id,
                          amount: transaction?.amount,
                          currency: transaction?.wallet?.currency?.abbreviation,
                        })}
                      />
                    </div>
                    <p className="mb-2 steel-text">{`${transaction?.id} - ${user?.email}`}</p>
                    <h1 className="mb-4 font-monospace fw-bolder">
                      {`${helpers.Money(transaction?.amount)} ${
                        transaction?.wallet?.currency?.abbreviation
                      }`}
                    </h1>

                    <TabWrapper>
                      <TabsComponent
                        childProps={{ order, user }}
                        tabs={[
                          {
                            title: "Instructions",
                            content: Instructions,
                            eventKey: "instructions",
                          },
                          {
                            title: "Get Help Here",
                            content: Details,
                            eventKey: "details",
                          },
                        ]}
                      />
                    </TabWrapper>
                  </div>
                  {/* end of cash and e-voucher */}
                </Fragment>
              ) : (
                <div className="mb-10">
                  {[
                    ...(transaction?.order
                      ? [
                          {
                            name: "Transaction Created On",
                            desc: TransactionDate({
                              transaction: transaction?.order,
                              format: "hh:mm A, Do MMM, YYYY",
                            }),
                          },
                        ]
                      : []),
                    ...(transaction?.processed_at
                      ? [
                          {
                            name: "Paid On",
                            desc: TransactionDate({
                              transaction,
                              date: "processed_at",
                              format: "hh:mm A, Do MMM, YYYY",
                            }),
                          },
                        ]
                      : []),
                    {
                      name: "Payment Method",
                      desc: PaymentMethod({ transaction }),
                    },
                    ...(isLoyaltyPointEarned
                      ? [
                          {
                            name: "Loyalty Points Earned",
                            desc: `#${(loyaltyPoints?.point || 0)?.toLocaleString()}`,
                          },
                        ]
                      : []),
                    ...(transaction?.order
                      ? [
                          {
                            name: `${startCase(
                              transaction?.order?.order_type,
                            )} Order`,
                            desc: `#${transaction?.order?.id}`,
                          },
                        ]
                      : []),
                    ...(["deposit", "withdrawal"].includes(type)
                      ? [
                          {
                            name: "Related transaction",
                            desc: `#${transaction?.id}`,
                          },
                        ]
                      : []),
                  ].map(({ name, desc }, key) => (
                    <div
                      key={key}
                      className="d-flex x-spacing-4 justify-content-between mb-8"
                    >
                      <div className="text-start">
                        <small className="bluey-grey-text text-capitalize">
                          {name}
                        </small>
                      </div>
                      <div className="text-end">
                        <small>{desc}</small>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* print-area */}

            <div className="mb-10">
              {transactionDetails && (
                <PaymentDetails
                  items={Object.keys(transactionDetails)
                    .filter(
                      (key) =>
                        key !== "payment_method_id" &&
                        Boolean(transactionDetails[key]),
                    )
                    .map((key) => ({
                      title: startCase(key),
                      desc: transactionDetails[key],
                    }))}
                  isEvoucher={[process.env.REACT_APP_EVOUCHER_TYPE].includes(
                    transaction?.payment_method?.type,
                  )}
                />
              )}

              <div className="my-6">
                {[process.env.REACT_APP_EVOUCHER_TYPE].includes(
                  transaction?.payment_method?.type,
                ) &&
                  transactionDetails && (
                    <GoogleMapsPreview
                      url={transactionDetails?.map_location}
                      name={transactionDetails?.name}
                      // url={`https://maps.google.com/maps?q=${transactionDetails.latitude},${transactionDetails.lonsgitude}`}
                      // url={
                      //   Object.keys(transactionDetails).filter(
                      //     (key) =>
                      //       key !== "payment_method_id" &&
                      //       key === "map_location" &&
                      //       Boolean(transactionDetails[key]),
                      //   )[0]?.map_location
                      // }
                    />
                  )}
              </div>
            </div>

            <GridStyle gaps={{ sm: "1rem" }}>
              {transaction?.status?.name?.toLowerCase() !== "processed" &&
                (![process.env.REACT_APP_EVOUCHER_TYPE].includes(
                  transaction?.payment_method?.type,
                ) ? (
                  <ButtonComponent
                    variant="outline"
                    className="border-1"
                    onClick={() => mutate()}
                  >
                    Refresh
                  </ButtonComponent>
                ) : (
                  <ButtonComponent onClick={() => mutate()}>
                    I have made the payment
                  </ButtonComponent>
                ))}

              <ReactToPrint
                content={() => printableRef.current}
                documentTitle={`Transaction_#${transaction?.id}`}
                trigger={() => (
                  <ButtonComponent variant="outline" className="border-1">
                    Print
                  </ButtonComponent>
                )}
                bodyClass="print"
              />
            </GridStyle>
          </Fragment>
        )}
      </ModalComponent.Body>
    </ModalComponent.Light>
  );
};

/**
 * styled components
 */
const TabWrapper = styled.div`
  .tabs__nav {
    justify-content: center;
    border-bottom: none;
    overflow-x: hidden;

    > a {
      text-transform: uppercase;
      font-size: 0.75rem;
      margin: 0 1.25rem;
      padding: 0.375rem 1rem;
    }

    .-active {
      color: var(--clear-blue);
    }
  }
`;

Wallet.propTypes = propTypes;
Wallet.defaultProps = defaultProps;

export default Wallet;
