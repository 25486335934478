import React, { useState } from "react";
import { Formik } from "formik";
import { object } from "yup";
import Fade from "react-fade-in";
import Form from "react-bootstrap/Form";

import * as helpersUtil from "../../../Utils/helpers";
import * as schemaUtil from "../../../Utils/schema";
import ButtonComponent from "../../Button";
import SelectComponent from "../../Select";
import AlertComponent from "../../Alert";
import FieldComponent from "../../Field";
import GridStyle from "../../../Styles/Grid";
import InfoIcon from "../../../Icons/Info";

const BankDeposit = ({
  user,
  wallet,
  methods,
  paramKey,
  handleSubmit,
  transactionData,
}) => {
  /**
   * state
   */
  const [selectedBank, setBank] = useState();
  const [selectedBranch, setBranch] = useState();
  const [isSubmitting, setSubmitting] = useState(false);

  /**
   * variables
   */
  const branches = selectedBank?.data?.branches || [];
  const priorities = selectedBranch?.networks || [];

  return (
    <Fade>
      <AlertComponent icon={InfoIcon} className="small navy-text lh-13 mb-8">
        Please make sure you provide the right bank account details. Also, note
        that bank payments are processed within one business day
        depending on the amount and the payment priority.
      </AlertComponent>

      <GridStyle gaps={{ sm: "2rem" }} columns={{ md: "23.125rem 15rem" }}>
        <div className="order-md-2">
          <div className="text-center border ice-blue-border pale-grey-bg rounded-3 p-6 py-md-12">
            <p className="fw-bold mb-2">You will be receiving</p>
            <h2 className="text-wrap font-monospace fw-bolder">
              {`${helpersUtil.Money(transactionData?.amount)} ${
                wallet?.currency?.abbreviation
              }`}
            </h2>
          </div>
        </div>
        <div className="order-md-1 border ice-blue-border pale-grey-bg rounded-3 p-6">
          <h5 className="mb-6">Enter your bank details</h5>

          <Formik
            validateOnMount
            validationSchema={object({
              [paramKey]: object().shape({
                branch: schemaUtil.requireString("Branch"),
                priority: schemaUtil.requireString("Priority"),
                payment_method_id: schemaUtil.requireString("Bank"),
                beneficiary: schemaUtil.requireString("Account name"),
                account_number: schemaUtil.requireString("Account number"),
              }),
            })}
            initialValues={{
              [paramKey]: {
                branch: "",
                priority: "",
                account_number: "",
                payment_method_id: "",
                beneficiary: user?.full_name || "",
              },
            }}
            onSubmit={(
              { [paramKey]: { payment_method_id, priority, ...params } },
              actions,
            ) => {
              setSubmitting(true);

              return handleSubmit(
                {
                  payment_method_id,
                  [paramKey]: {
                    ...params,
                    payment_method_id,
                    bank: selectedBank?.name,
                    branch: selectedBranch?.name,
                    priority: selectedBranch?.networks?.find(
                      ({ code }) => code === priority,
                    )?.name,
                  },
                },
                { ...actions, setSubmitting },
              );
            }}
          >
            {({
              isValid,
              handleSubmit,
              setFieldValue,
              values: {
                [paramKey]: {
                  branch,
                  priority,
                  beneficiary,
                  account_number,
                  payment_method_id,
                },
              },
            }) => (
              <Form>
                <FieldComponent
                  placeholder="Select Bank"
                  value={payment_method_id}
                  component={SelectComponent}
                  name={`${paramKey}.payment_method_id`}
                  onChange={({ value }) =>
                    setBranch("") |
                    setFieldValue(`${paramKey}.branch`, "") |
                    setFieldValue(`${paramKey}.payment_method_id`, value) |
                    setBank(methods?.find(({ id }) => id === value))
                  }
                  options={
                    methods?.map(({ id, name }) => ({
                      value: id,
                      label: name,
                    })) || []
                  }
                />

                <FieldComponent
                  value={branch}
                  placeholder="Branch"
                  name={`${paramKey}.branch`}
                  component={SelectComponent}
                  onChange={({ value }) =>
                    setFieldValue(`${paramKey}.priority`, "") |
                    setFieldValue(`${paramKey}.branch`, value) |
                    setBranch(
                      branches?.find(
                        ({ reference_number }) => reference_number === value,
                      ),
                    )
                  }
                  options={
                    branches?.map(({ reference_number, name }) => ({
                      label: name,
                      value: reference_number,
                    })) || []
                  }
                />

                <FieldComponent
                  float
                  placeholder="--"
                  value={beneficiary}
                  label="Account name"
                  name={`${paramKey}.beneficiary`}
                />

                <FieldComponent
                  float
                  placeholder="--"
                  value={account_number}
                  label="Account number"
                  name={`${paramKey}.account_number`}
                />

                <FieldComponent
                  value={priority}
                  component={SelectComponent}
                  name={`${paramKey}.priority`}
                  placeholder="Payment Priority"
                  onChange={({ value }) =>
                    setFieldValue(`${paramKey}.priority`, value)
                  }
                  options={
                    priorities?.map(({ code, name }) => ({
                      label: name,
                      value: code,
                    })) || []
                  }
                />

                <ButtonComponent
                  type="submit"
                  onClick={handleSubmit}
                  {...{ isValid, isSubmitting }}
                >
                  Continue
                </ButtonComponent>
              </Form>
            )}
          </Formik>
        </div>
      </GridStyle>
    </Fade>
  );
};

export default BankDeposit;
