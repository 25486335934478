import React, { Fragment, useState } from "react";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton-2";
import styled from "styled-components";
import Fade from "react-fade-in";

import * as helpersUtil from "../../../Utils/helpers";
import PrepaidWalletIllustration from "../../../Illustrations/PrepaidWallet";
import ConfirmOtpComponent from "../../ConfirmOtp";
import ButtonComponent from "../../Button";
import NotifyComponent from "../../Notify";
import AlertComponent from "../../Alert";
import BubbleStyle from "../../../Styles/Bubble";
import CloseIcon from "../../../Icons/Close";

const PrepaidWallet = ({
  user,
  wallet,
  history,
  onSuccess,
  walletError,
  handleSubmit,
  transactionData,
  ...props
}) => {
  /**
   * variables
   */
  const canBuy =
    Number(wallet?.prepaid_balance) >=
    Number(transactionData?.total || transactionData?.amount);
  const currency = wallet?.currency?.abbreviation;

  /**
   * states
   */
  const [isSubmitting, setSubmitting] = useState(false);

  /**
   * functions
   */
  const handleLocalSubmit = () => {
    setSubmitting(true);

    if (user?.default_2fa) {
      ConfirmOtpComponent({
        api: handleSubmit,
        otpType: user?.default_2fa,
      }).then(({ success, response }) => {
        setSubmitting(false);

        if (success) {
          onSuccess(response);
        } else {
          toast(
            <NotifyComponent body="Unable to request order" type="error" />,
          );
        }
      });
    } else {
      handleSubmit({})
        .then((response) => onSuccess(response))
        .catch(() =>
          toast(
            <NotifyComponent body="Unable to request order" type="error" />,
          ),
        )
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Fragment>
      <Fade wrapperTag={Wrapper}>
        {/* skeleton */}
        {!wallet && !walletError && (
          <Fragment>
            <Skeleton
              circle
              width={84}
              height={84}
              className="mx-auto mb-4 d-block"
            />
            <Skeleton
              height={44}
              width={200}
              className="d-block mx-auto mb-2"
            />
            <Skeleton
              height={14}
              width={248}
              className="d-block mx-auto mb-8"
            />

            <Skeleton height={64} className="d-block" />
          </Fragment>
        )}

        {wallet && (
          <Fragment>
            {!canBuy && (
              <AlertComponent
                variant="danger"
                className="mb-8"
                icon={(props) => <CloseIcon variant="circle" {...props} />}
              >
                <span className="my-auto pale-red-text">
                  You have insufficient balance to make this payment
                </span>
              </AlertComponent>
            )}

            <BubbleStyle
              size={84}
              className="mx-auto mb-4"
              bg="var(--pale-grey-four)"
            >
              <PrepaidWalletIllustration />
            </BubbleStyle>

            <div className="mb-8">
              <h1 className="font-monospace fw-bolder lh-15">
                {`${helpersUtil.Money(wallet?.prepaid_balance)} ${currency}`}
              </h1>
              <small className="bluey-grey-text text-uppercase">
                YOUR PREPAID WALLET BALANCE
              </small>
            </div>

            <ButtonComponent
              height={64}
              isValid={canBuy}
              style={{ fontSize: 16 }}
              isSubmitting={isSubmitting}
              onClick={handleLocalSubmit}
            >
              Pay {`${helpersUtil.Money(transactionData?.total)} ${currency}`}
            </ButtonComponent>
          </Fragment>
        )}
      </Fade>
    </Fragment>
  );
};

/**
 * styles
 */
const Wrapper = styled.div`
  margin: 0px auto;
  text-align: center;
  max-width: ${({ size }) => (size === "sm" ? "348px" : "460px")};
`;

export default React.memo(PrepaidWallet);
