import React from "react";
import styled from "styled-components";
import Fade from "react-fade-in";

import TransactionDate from "../../../Date";
import LinkComponent from "../../../../Link";
import PaymentMethod from "../../../PaymentMethod";
import PaymentDetails from "../../../PaymentDetails";

const Details = ({ order, user, ...props }) => {
  /**
   * variables
   */
  const orderType = order?.order_type?.toLowerCase();
  const isProcessed = order?.status?.slug === `user-${orderType}-processed`;
  const paymentDetailsItems = [
    {
      title: "Full name as on ID",
      desc: `${user?.full_name}`,
    },
    {
      title: "Pickup Date",
      desc: (
        <TransactionDate
          date="created_at"
          transaction={order}
          format="D MMM, YYYY"
        />
      ),
    },
    {
      title: "Pickup  Time",
      desc: (
        <TransactionDate
          date="created_at"
          transaction={order}
          format="hh:mm A"
        />
      ),
    },
    {
      title: "Pickup Location",
      desc: `${order?.payment_method?.details?.Location}`,
    },
  ];

  const items = [
    ...(isProcessed
      ? [
          {
            title: "Created On",
            desc: (
              <TransactionDate
                date="created_at"
                transaction={order}
                format="hh:mm A, Do MMM, YYYY"
              />
            ),
          },
          {
            title: "Paid On",
            desc: (
              <TransactionDate
                date="processed_at"
                transaction={order}
                format="hh:mm A, Do MMM, YYYY"
              />
            ),
          },
          {
            title: "Payment Method",
            desc: <PaymentMethod transaction={order} />,
          },
          {
            title: "Sell order",
            desc: `#${order?.user_transaction?.id}`,
          },
        ]
      : []),
  ];

  return (
    <div {...props}>
      <Fade mountOnEnter unmountOnExit>
        <div className="d-flex flex-column y-spacing-8">
          {items?.map(({ title, desc }, key) => (
            <Item key={key}>
              <div>
                <small className="item__title">{title}</small>
              </div>
              <div>
                {title === "Sell order" ? (
                  <LinkComponent to={`/transactions/${order?.id}`}>
                    <small className="item__description">{desc}</small>
                  </LinkComponent>
                ) : (
                  <small className="item__description">{desc}</small>
                )}
              </div>
            </Item>
          ))}
        </div>

        {isProcessed && (
          <PaymentDetails className="my-8" items={paymentDetailsItems} />
        )}
      </Fade>
    </div>
  );
};

/**
 * styles
 */
const Item = styled.div`
  gap: 1.5rem;
  display: grid;
  line-height: 1.3;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  .item__title {
    color: var(--bluey-grey);
  }

  .item__description {
    text-align: right;
    word-wrap: break-word;
  }
`;

export default Details;
