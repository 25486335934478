import styled from "styled-components";
import PropTypes from "prop-types";

/**
 * prop definition
 */
const propTypes = {
  gaps: PropTypes.shape({
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
  }),
  columns: PropTypes.shape({
    sm: PropTypes.string,
    md: PropTypes.string,
    lg: PropTypes.string,
    xl: PropTypes.string,
  }),
};

const defaultProps = {
  gaps: { sm: "32px" },
};

const Grid = styled.div`
  width: 100%;
  display: grid;
  gap: ${({ gaps }) => gaps?.sm || "auto"};
  grid-template-columns: ${({ columns }) => columns?.sm || "auto"};

  @media (min-width: 768px) {
    gap: ${({ gaps }) => gaps?.md || gaps?.sm || "auto"};
    grid-template-columns: ${({ columns }) =>
      columns?.md || columns?.sm || "auto"};
  }

  @media (min-width: 992px) {
    gap: ${({ gaps }) => gaps?.lg || gaps?.md || gaps?.sm || "auto"};
    grid-template-columns: ${({ columns }) =>
      columns?.lg || columns?.md || columns?.sm || "auto"};
  }

  @media (min-width: 1200px) {
    gap: ${({ gaps }) =>
      gaps?.xl || gaps?.lg || gaps?.md || gaps?.sm || "auto"};
    grid-template-columns: ${({ columns }) =>
      columns?.xl || columns?.lg || columns?.md || columns?.sm || "auto"};
  }
`;

Grid.propTypes = propTypes;
Grid.defaultProps = defaultProps;

export default Grid;
