import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Image from "react-bootstrap/Image";
import Card from "react-bootstrap/Card";
import Sklt from "react-loading-skeleton-2";

import ButtonComponent from "../../Components/Button";
import useConfigHook from "../../Hooks/useConfig";
import GridStyle from "../../Styles/Grid";
import loopUtil from "../../Utils/loop";

const propTypes = {
  /**
   * corresponding asset detail
   */
  asset: PropTypes.shape({
    name: PropTypes.string.isRequired,
    short_name: PropTypes.string.isRequired,
  }),
  /**
   * configuration options
   */
  config: PropTypes.shape({
    currency: PropTypes.shape({
      abbreviation: PropTypes.string.isRequired,
    }),
    in_stock: PropTypes.number.isRequired,
    accepting_buy: PropTypes.number.isRequired,
    accepting_sell: PropTypes.number.isRequired,
    buy_normal_rate: PropTypes.string.isRequired,
    sell_normal_rate: PropTypes.string.isRequired,
  }),
  /**
   * path for routing
   */
  path: PropTypes.shape({
    buy: PropTypes.shape({
      index: PropTypes.string,
    }),
    sell: PropTypes.shape({
      index: PropTypes.string,
    }),
  }),
  /**
   * router function
   */
  router: PropTypes.func,
};

const AssetCard = ({ asset, config, path, router, ...props }) => {
  /**
   * hooks
   */
  const canSell = useConfigHook("sell", "true");
  const canBuy = useConfigHook("buy", "true");

  /**
   * variables
   */
  const rates = [
    {
      slug: "buy",
      link: `${path?.buy?.index}?asset=${asset?.short_name}`,
      rate: config?.buy_normal_rate,
      currency: config?.currency?.abbreviation,
      isValid: config?.in_stock && config?.accepting_buy && canBuy,
    },
    {
      slug: "sell",
      link: `${path?.sell?.index}?asset=${asset?.short_name}`,
      rate: config?.sell_normal_rate,
      currency: config?.currency?.abbreviation,
      isValid: config?.accepting_sell && canSell,
    },
  ];

  /**
   * functions
   */
  const handleProps = () => {
    if (!config?.in_stock && config?.accepting_buy) {
      return { bg: "var(--tomato)", content: "Out of Stock" };
    }

    if (!config?.in_stock && !config?.accepting_buy) {
      return { bg: "var(--cloudy-blue)", content: "Unavailable" };
    }
  };

  return (
    <Wrapper data={handleProps()} {...props}>
      <Card.Body>
        <GridStyle
          className="mb-10"
          gaps={{ sm: "1rem" }}
          columns={{ sm: "minmax(0, 1fr) 5rem" }}
        >
          <GridStyle
            gaps={{ sm: "1rem" }}
            className="align-items-center"
            columns={{ sm: "2rem minmax(0, 1fr)" }}
          >
            <Image
              width={32}
              height={32}
              className="img-fit rounded-circle"
              src={`/assets/${asset?.short_name?.toLowerCase()}.svg`}
            />
            <div>
              <h5 className="text-truncate" title={asset?.name}>
                {asset?.name}
              </h5>
              <small>{asset?.short_name?.toUpperCase()}</small>
            </div>
          </GridStyle>
          <div className="mx-auto">
            <div
              className="d-flex"
              style={{ gap: "0.5rem", whiteSpace: "nowrap" }}
            >
              <small className="mt-1">UNIT</small>
              <h5 className="font-monospace fw-bolder">1 USD</h5>
            </div>
          </div>
        </GridStyle>

        <GridStyle gaps={{ sm: "1rem" }}>
          {rates.map((rate, key) => (
            <div
              key={key}
              className="d-flex align-items-center justify-content-between"
            >
              <div>
                <small className="d-block mb-1">
                  {rate?.slug?.toUpperCase()}ING
                </small>
                <h5 className="font-monospace fw-bolder">
                  {rate?.rate + " " + rate?.currency}
                </h5>
              </div>

              <ButtonComponent
                width={96}
                height={40}
                variant="outline"
                isValid={Boolean(rate?.isValid)}
                {...(router && { onClick: () => router(rate?.link) })}
              >
                {rate?.slug?.toUpperCase()}
              </ButtonComponent>
            </div>
          ))}
        </GridStyle>
      </Card.Body>
    </Wrapper>
  );
};

/**
 * component
 */
const Skeleton = (props) => (
  <Wrapper {...props}>
    <Card.Body>
      <GridStyle
        className="mb-10"
        gaps={{ sm: "1rem" }}
        columns={{ sm: "minmax(0, 1fr) 5rem" }}
      >
        <GridStyle
          gaps={{ sm: "1rem" }}
          className="align-items-center"
          columns={{ sm: "2rem minmax(0, 1fr)" }}
        >
          <Sklt width={32} height={32} circle />
          <div>
            <Sklt width={96} height={18} className="mb-1 d-block" />
            <Sklt height={12} width={20} />
          </div>
        </GridStyle>
        <div className="ms-auto">
          <div className="d-flex" style={{ gap: "0.5rem" }}>
            <Sklt height={12} width={26} className="mt-1" />
            <Sklt height={18} width={45} />
          </div>
        </div>
      </GridStyle>

      <GridStyle gaps={{ sm: "1rem" }}>
        {loopUtil(2).map((rate, key) => (
          <div
            key={key}
            className="d-flex align-items-center justify-content-between"
          >
            <div>
              <Sklt width={42} height={14} className="mb-1 d-block" />
              <Sklt width={68} height={18} />
            </div>

            <Sklt width={96} height={40} />
          </div>
        ))}
      </GridStyle>
    </Card.Body>
  </Wrapper>
);

/**
 * styles
 */
const Wrapper = styled(Card)`
  width: 100%;
  flex: 0 0 100%;
  height: 13.875rem;
  position: relative;

  .card-body {
    height: 100%;
    padding: 1.5rem;
    padding-top: 1.75rem;
  }

  small {
    font-size: 0.75rem;
    color: var(--bluey-grey);
    text-transform: uppercase;
  }

  .btn {
    font-weight: 600;
    font-size: 0.75rem;
    text-transform: uppercase;
  }

  &::after {
    top: 0;
    right: 1.5rem;
    color: #fff;
    font-weight: 500;
    line-height: 14px;
    position: absolute;
    font-size: 0.75rem;
    display: inline-block;
    padding: 0.1875rem 1rem;
    border-radius: 0 0 0.25rem 0.25rem;
    content: ${({ data }) => `"${data?.content}"` || " "};
    background-color: ${({ data }) => data?.bg || ""};
  }

  @media (min-width: 576px) {
    width: 20.125rem;
    flex: 0 0 20.125rem;
  }
`;

AssetCard.propTypes = propTypes;

export default Object.assign(AssetCard, { Skeleton });
