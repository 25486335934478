import React, { useState } from "react";
import { Formik } from "formik";
import { object } from "yup";
import linkify from "linkify-html";
import Fade from "react-fade-in";
import Form from "react-bootstrap/Form";

import * as helpersUtil from "../../../Utils/helpers";
import * as schemaUtil from "../../../Utils/schema";
import ButtonComponent from "../../Button";
import SelectComponent from "../../Select";
import AlertComponent from "../../Alert";
import FieldComponent from "../../Field";
import GridStyle from "../../../Styles/Grid";
import InfoIcon from "../../../Icons/Info";

const CashPayment = ({
  user,
  wallet,
  methods,
  paramKey,
  onSuccess,
  handleSubmit,
  transactionData,
}) => {
  /**
   * states
   */
  const [selectedMethod, setSelectedMethod] = useState({});

  /**
   * variables
   *
   */
  const depositInstructions = selectedMethod?.deposit_instructions;
  const details = selectedMethod?.details?.deposit;

  const instructions = depositInstructions &&
    details && [depositInstructions, details];

  return (
    <Fade>
      <AlertComponent icon={InfoIcon} className="small navy-text lh-15 mb-8">
        Kindly go to the location shown when you select a{" "}
        {process.env.REACT_APP_APP_NAME || "PayPlux"} Cash Point from the
        options below.
      </AlertComponent>

      <GridStyle gaps={{ sm: "2rem" }} columns={{ md: "repeat(2, 1fr)" }}>
        <div className="order-md-2">
          <div className="text-center border ice-blue-border pale-grey-bg rounded-3 p-6 py-md-12">
            <p className="fw-bold mb-2">You will be paying</p>
            <h2 className="text-wrap font-monospace fw-bolder">
              {`${helpersUtil.Money(transactionData?.amount)} ${
                wallet?.currency?.abbreviation
              }`}
            </h2>
          </div>
        </div>
        <div className="order-md-1 border ice-blue-border pale-grey-bg rounded-3 p-6">
          <Formik
            validateOnMount
            enableReinitialize
            validationSchema={object({
              [paramKey]: object().shape({
                branch: schemaUtil.requireString("Branch"),
              }),
            })}
            initialValues={{
              [paramKey]: {
                branch: "",
              },
            }}
            onSubmit={(params, { setSubmitting }) => {
              const branchId = params[paramKey].branch;

              params[paramKey].branch = methods?.find(
                ({ id }) => id === branchId,
              )?.name;
              params[paramKey].payment_method_id = branchId;

              handleSubmit({
                payment_method_id: branchId,
                ...params,
              })
                .then((response) => onSuccess(response))
                .finally(() => setSubmitting(false));
            }}
          >
            {({
              isValid,
              isSubmitting,
              handleSubmit,
              setFieldValue,
              values: {
                [paramKey]: { branch },
              },
            }) => (
              <Form>
                <FieldComponent
                  value={branch}
                  name={`${paramKey}.branch`}
                  component={SelectComponent}
                  placeholder="Select Cash Payment Point"
                  onChange={({ value }) =>
                    setFieldValue(`${paramKey}.branch`, value) |
                    setSelectedMethod(methods?.find(({ id }) => id === value))
                  }
                  options={
                    methods?.map(({ id, name, details }) => ({
                      value: id,
                      label: `${name}${
                        details?.Location ? ` - ${details?.Location}` : ""
                      }`,
                    })) || []
                  }
                />

                {instructions && (
                  <div className="text-start mb-6">
                    {instructions?.map((instruction, index) => (
                      <p
                        key={index}
                        className="mb-4"
                        dangerouslySetInnerHTML={{
                          __html: linkify(instruction, { target: "_blank" }),
                        }}
                      />
                    ))}
                  </div>
                )}

                <ButtonComponent
                  onClick={handleSubmit}
                  {...{ isValid, isSubmitting }}
                >
                  Done
                </ButtonComponent>
              </Form>
            )}
          </Formik>
        </div>
      </GridStyle>
    </Fade>
  );
};

export default CashPayment;
