import React from "react";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Fade from "react-fade-in";

import LinkComponent from "../Link";
import ChevronIcon from "../../Icons/Chevron";
import GridStyle from "../../Styles/Grid";

const PaymentDetails = ({ items, isEvoucher, ...props }) => {
  /**
   * variables
   */
  const [toggle, setToggle] = React.useState(false);

  /**
   * function
   */
  const decoratedOnClick = useAccordionToggle(0, () => setToggle(!toggle));

  return (
    <Accordion defaultActiveKey="none" {...props}>
      <Card className="shadow-none pale-grey-three-bg px-5 py-4">
        <Accordion.Toggle
          eventKey="0"
          preventDefault
          as={LinkComponent}
          onClick={decoratedOnClick}
          className="d-block mx-n5 my-n4 py-4 px-5"
        >
          <div className="d-flex align-items-center justify-content-between clear-blue-text text-uppercase">
            <span className="text-sub -medium">
              {isEvoucher ? "Agent" : "Payment"} Details
            </span>
            <div className="text-end">
              <ChevronIcon
                color="var(--clear-blue)"
                variant={toggle ? "up" : "down"}
              />
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0" className="">
          <Card.Body className="pt-6 pb-2">
            <Fade className="d-flex flex-column y-spacing-8">
              {items?.map((item, key) => (
                <GridStyle
                  key={key}
                  gaps={{ sm: "1.5rem" }}
                  columns={{ sm: "repeat(2, minmax(0, 1fr))" }}
                >
                  <div>
                    <small className="item bluey-grey-text">{item.title}</small>
                  </div>
                  <div className="text-end text-break lh-13">
                    <small className="description">{item.desc}</small>
                  </div>
                </GridStyle>
              ))}
            </Fade>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default PaymentDetails;
