import React from "react";
import { Field as FormikField } from "formik";
import PropTypes from "prop-types";

import { FloatWrapper, DefaultWrapper } from "./Field/Wrapper";
import CountriesField from "./Field/Countries";
import CheckboxField from "./Field/Checkbox";
import PasswordField from "./Field/Password";
import ErrorMessage from "./Field/Error";
import SwitchField from "./Field/Switch";
import MoneyField from "./Field/Money";
import PhoneField from "./Field/PhoneInput";
import RadioField from "./Field/Radio";
import DateField from "./Field/Date";
import OtpField from "./Field/Otp";

/**
 * props definition
 */
const propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  containerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  withFormik: PropTypes.bool,
  postfix: PropTypes.node,
  prefix: PropTypes.node,
  children: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.any,
};

const defaultProps = {
  type: "text",
  withFormik: true,
  component: FormikField,
};

const Field = ({
  containerClassName,
  wrapperClassName,
  labelClassName,
  withFormik,
  className,
  component,
  postfix,
  prefix,
  float,
  label,
  name,
  as,
  ...props
}) => {
  /**
   * variables
   */
  const Input = component;
  const Parent = float ? FloatWrapper : DefaultWrapper;

  return (
    <Parent
      {...{
        name,
        label,
        prefix,
        postfix,
        withFormik,
        labelClassName,
        wrapperClassName,
        containerClassName,
        ...props,
      }}
    >
      <Input
        name={name}
        autoComplete="nope"
        className={className || "form-control"}
        id={props?.id || name?.split("").reverse().join("")}
        {...(as && typeof as === "function" ? { component: as } : { as })}
        {...props}
      />
    </Parent>
  );
};

Field.propTypes = propTypes;
Field.defaultProps = defaultProps;

export default Object.assign(Field, {
  Countries: CountriesField,
  Checkbox: CheckboxField,
  Password: PasswordField,
  Switch: SwitchField,
  Error: ErrorMessage,
  Money: MoneyField,
  Phone: PhoneField,
  Radio: RadioField,
  Date: DateField,
  Otp: OtpField,
});
