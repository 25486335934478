import styled from "styled-components";
import PropTypes from "prop-types";

const propTypes = {
  bg: PropTypes.string,
  opacity: PropTypes.number,
  svg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const defaultProps = {
  size: 80,
  opacity: 1,
};

const Bubble = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  position: relative;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => `${size * 0.0625}rem`};
  height: ${({ size }) => `${size * 0.0625}rem`};

  &::before {
    top: 0;
    left: 0;
    width: 100%;
    content: " ";
    height: 100%;
    display: block;
    position: absolute;
    background-color: ${({ bg }) => bg};
    opacity: ${({ opacity }) => opacity};
  }

  * {
    z-index: 1;
  }

  ${({ svg }) =>
    svg &&
    `
        svg, img {
            width: ${svg * 0.0625}rem;
            height: ${svg * 0.0625}rem;
        }
    `}
`;

Bubble.propTypes = propTypes;
Bubble.defaultProps = defaultProps;

export default Bubble;
