import React, { Fragment } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import PropTypes from "prop-types";
import styled from "styled-components";

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  variant: PropTypes.oneOf(["circular", "default"]),
  children: PropTypes.any,
};

const defaultProps = {
  variant: "default",
  value: 0,
};

const Progress = ({ variant, value, children, ...props }) => {
  return (
    <Fragment>
      {variant === "circular" && (
        <CircularWrapper {...props}>
          <CircularProgressbarWithChildren
            strokeWidth={5}
            circleRatio={0.75}
            styles={buildStyles({
              rotation: 1 / 2 + 1 / 8,
            })}
            {...{ value }}
          >
            <div className="text-center">{children}</div>
          </CircularProgressbarWithChildren>
        </CircularWrapper>
      )}
    </Fragment>
  );
};

/**
 * styles
 */
const CircularWrapper = styled.div`
  max-width: 11.25rem;

  .CircularProgressbar {
    width: 100%;
    vertical-align: middle;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke-linecap: round;
    stroke: var(--clear-blue);
    -webkit-transition: stroke-dashoffset 0.5s ease 0s;
    transition: stroke-dashoffset 0.5s ease 0s;
  }

  .CircularProgressbar .CircularProgressbar-trail {
    stroke: var(--pale-grey-three);
    /* Used when trail is not full diameter, i.e. when props.circleRatio is set */
    stroke-linecap: round;
  }
`;

Progress.propTypes = propTypes;
Progress.defaultProps = defaultProps;

export default Progress;
