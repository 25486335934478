import React from "react";
import QrCode from "react-qr-code";
import PropTypes from "prop-types";

const propTypes = {
  /**
   * width and height of QRCode Component
   *
   * @default '180px'
   */
  size: PropTypes.number,

  /**
   * value of QR Code
   */
  value: PropTypes.string.isRequired,
};

const defaultProps = {
  size: 180,
};

const QRCode = ({ size, value, ...props }) => {
  return (
    <div className="qr-code d-inline-block p-4 border pale-grey-three-border">
      <QrCode size={size * 0.815} {...{ value, ...props }} />
    </div>
  );
};

QRCode.propTypes = propTypes;
QRCode.defaultProps = defaultProps;

export default QRCode;
