import React, { Fragment, useState } from "react";

import MoneyBagIllustration from "../../../../../../Illustrations/MoneyBag";
import ButtonComponent from "../../../../../Button";
import BubbleStyle from "../../../../../../Styles/Bubble";
import FieldComponent from "../../../../../Field";

const Fiat = ({ mutate, cancel, order, history }) => {
  const [confirm, setConfirm] = useState(false);

  return (
    <Fragment>
      <div
        style={{ maxWidth: "23.5rem", width: "100%" }}
        className="mx-auto my-5"
      >
        <div className="px-2 text-center">
          <BubbleStyle
            size={84}
            bg="var(--pale-grey-four)"
            className="mx-auto overflow-hidden"
          >
            <MoneyBagIllustration variant="send" size={48} />
          </BubbleStyle>

          <div className="my-5 d-flex flex-column">
            {[
              {
                label: "Address:",
                value: order?.request_data?.address,
              },
              {
                label: "Ecurrency:",
                value: order?.ecurrency?.name,
              },
              {
                label: "Amount:",
                value: `${order?.amount_usd} USD`,
              },
              ...(order?.status?.name?.toLowerCase() !== "reserved"
                ? [
                    ...(order?.transaction_id
                      ? [
                          {
                            label: "Payment ID:",
                            value: order?.transaction_id || "--",
                          },
                        ]
                      : []),

                    {
                      label: "Buy Order ID:",
                      value: order?.order_id || "--",
                    },
                  ]
                : []),
            ].map((e, key) => (
              <div key={key} className="d-flex my-2 x-spacing-3">
                <h5>{e?.label}</h5>
                <h5 title={e?.value} style={{ wordBreak: "break-word" }}>
                  {e?.value}
                </h5>
              </div>
            ))}
          </div>

          {order?.status?.name?.toLowerCase() === "reserved" && (
            <div>
              <FieldComponent.Checkbox
                type="radio"
                checked={confirm}
                name="confirm_details"
                onChange={() => setConfirm(!confirm)}
              >
                <p className="text-start">
                  I confirm that the details match the reservation.
                </p>
              </FieldComponent.Checkbox>

              <big className="lh-15 d-inline-block mt-4">
                Click pay now to continue
              </big>
            </div>
          )}
        </div>

        <div className="d-flex x-spacing-3 my-8 mx-auto">
          {order?.status?.name?.toLowerCase() === "reserved" && (
            <Fragment>
              <ButtonComponent
                width={292}
                disabled={!confirm}
                className="mx-auto text-uppercase"
                onClick={(e) => history.push(order?.payment_url)}
              >
                Pay Now
              </ButtonComponent>

              <ButtonComponent
                variant="cancel"
                className="border-1"
                onClick={() => cancel()}
              >
                Cancel
              </ButtonComponent>
            </Fragment>
          )}

          {!["reserved", "completed"].includes(
            order?.status?.name?.toLowerCase(),
          ) && (
            <ButtonComponent
              variant="outline"
              className="border-1"
              onClick={() => mutate()}
            >
              Refresh
            </ButtonComponent>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export const Footer = ({ order: { ecurrency, status } }) => {
  return (
    <Fragment>
      {status?.name?.toLowerCase() === "reserved" && (
        <div className="my-4">
          <div className="border-top ice-blue-border pt-4">
            <small className="steel-text lh-13">
              <Fragment>
                By clicking 'Pay Now' button, You Agree that you've been
                informed and you know that your transaction MAY be temporarily
                tied up by the security service of {ecurrency?.name} for manual
                checking purposes.
              </Fragment>
            </small>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Object.assign(Fiat, { Footer });
