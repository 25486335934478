import React, { Fragment } from "react";
import PropTypes from "prop-types";

import loopUtil from "../Utils/loop";
import Card from "./AssetRateCards/Card";

const propTypes = {
  /**
   * when api is fetching data
   */
  isLoading: PropTypes.bool,
  /**
   * array of assets
   */
  assets: PropTypes.arrayOf(PropTypes.object),
  /**
   * url path to buy or sell page
   */
  path: PropTypes.object.isRequired,
  /**
   * routing function to route asset to buy or sell page
   */
  router: PropTypes.func.isRequired,
  /**
   * wrapper to encapulate asset cards
   */
  wrapper: PropTypes.func.isRequired,
};

const defaultProps = {
  isLoading: true,
  router: () => {},
  wrapper: Fragment,
};

const AssetRateCards = ({
  path,
  assets,
  router,
  isLoading,
  wrapper: ParentWrapper,
  ...props
}) => {
  /**
   * functions
   */
  const handleSettings = (settings) => {
    if (Array.isArray(settings)) {
      return settings[0];
    } else {
      return settings;
    }
  };

  return (
    <div {...props}>
      <h5 className="fw-bold">Buy / Sell Rates</h5>

      {isLoading ? (
        <ParentWrapper>
          {loopUtil(4).map((index) => (
            <Card.Skeleton key={index} />
          ))}
        </ParentWrapper>
      ) : (
        <ParentWrapper>
          {assets
            ?.filter(({ settings }) => handleSettings(settings)?.visible)
            ?.map(({ settings, ...asset }, key) => (
              <Card
                key={key}
                config={handleSettings(settings)}
                {...{ path, router, asset }}
              />
            ))}
        </ParentWrapper>
      )}
    </div>
  );
};

AssetRateCards.propTypes = propTypes;
AssetRateCards.defaultProps = defaultProps;

export default Object.assign(AssetRateCards, { Card });
