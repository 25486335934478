import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 96,
};

const VerifyEmail = ({ size, ...props }) => (
  <svg
    fill="none"
    width={size}
    height={size}
    viewBox="0 0 96 96"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 38L46.52 62.515C46.96 62.84 47.48 63 48 63C48.52 63 49.04 62.84 49.48 62.515L88 38L49.5 8.49997C48.61 7.83497 47.39 7.83497 46.5 8.49997L8 38Z"
      fill="#768493"
    />
    <path
      d="M73 8H23C20.245 8 18 10.245 18 13V68C18 69.38 19.12 70.5 20.5 70.5H75.5C76.88 70.5 78 69.38 78 68V13C78 10.245 75.76 8 73 8Z"
      fill="#EDF0F2"
    />
    <path
      d="M49.48 62.515C49.04 62.84 48.52 63 48 63C47.48 63 46.96 62.84 46.52 62.515L8 38V83C8 85.76 10.24 88 13 88H83C85.76 88 88 85.76 88 83V38L49.48 62.515Z"
      fill="#516276"
    />
    <path
      d="M83 88H13C10.195 88 8 85.805 8 83C8 82.205 8.38 81.455 9.02 80.985L46.52 55.985C46.96 55.66 47.48 55.5 48 55.5C48.52 55.5 49.04 55.66 49.48 55.985L86.98 80.985C87.62 81.455 88 82.205 88 83C88 85.805 85.805 88 83 88Z"
      fill="#768493"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48 21.6948C42.1253 21.6948 37.3574 26.4627 37.3574 32.3374C37.3574 38.2121 42.1253 42.98 48 42.98C53.8747 42.98 58.6426 38.2121 58.6426 32.3374C58.6426 26.4627 53.8747 21.6948 48 21.6948ZM48 40.8514C43.3066 40.8514 39.4859 37.0308 39.4859 32.3374C39.4859 27.644 43.3066 23.8233 48 23.8233C52.6934 23.8233 56.514 27.644 56.514 32.3374C56.514 37.0308 52.6934 40.8514 48 40.8514ZM45.8715 34.6468L52.1293 28.389C52.5444 27.9739 53.2255 27.9739 53.6405 28.389C54.0556 28.8041 54.0556 29.4745 53.6405 29.8896L46.6271 36.9031C46.212 37.3181 45.5416 37.3181 45.1265 36.9031L42.3701 34.1466C41.955 33.7316 41.955 33.0611 42.3701 32.646C42.5689 32.4467 42.8389 32.3347 43.1204 32.3347C43.4019 32.3347 43.6718 32.4467 43.8707 32.646L45.8715 34.6468Z"
      fill="#52B875"
    />
  </svg>
);

VerifyEmail.propTypes = propTypes;
VerifyEmail.defaultProps = defaultProps;

export default VerifyEmail;
