import React, { Fragment } from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

import LinkComponent from "../Link";
import CloseIcon from "../../Icons/Close";

const Content = ({ show, children, onHide, color, preventClose, ...props }) => {
  return (
    <Fragment>
      {!preventClose && (
        <Link preventDefault onClick={onHide}>
          <CloseIcon color={color} />
        </Link>
      )}
      <Dialog {...props}>{children}</Dialog>
    </Fragment>
  );
};

/**
 * styles
 */
const Dialog = styled(Modal.Dialog)`
  min-height: calc(100% - 4.5rem);

  @media (min-width: 992px) {
    min-height: calc(100% - 7rem);
  }
`;

const Link = styled(LinkComponent)`
  padding: 1rem;
  display: flex;
  justify-content: flex-end;

  @media (min-width: 768px) {
    top: 1rem;
    right: 1rem;
    padding: 0;
    display: block;
    position: absolute;
  }

  @media (min-width: 992px) {
    top: 2.75rem;
    right: 2.75rem;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  @media (min-width: 1200px) {
    top: 4.5rem;
    right: 4.5rem;

    svg {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
`;

export default Content;
