import React, { Fragment } from "react";
import Fade from "react-fade-in";

const Comments = ({ order }) => (
  <Fade>
    {order?.comments?.length ? (
      <Fragment>
        {order?.comments?.map((comment, key) => (
          <p className="bluey-grey-text mb-2" key={key}>
            {comment}
          </p>
        ))}
      </Fragment>
    ) : (
      <p className="bluey-grey-text text-center">No comment</p>
    )}
  </Fade>
);

export default Comments;
