import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Markdown from "react-showdown";
import Fade from "react-fade-in";

import * as helpersUtil from "../../../../Utils/helpers";
import BankDepositIllustration from "../../../../Illustrations/BankDeposit";
import LogoCardComponent from "../../../LogoCard";
import ButtonComponent from "../../../Button";
import SelectComponent from "../../../Select";
import AlertComponent from "../../../Alert";
import FieldComponent from "../../../Field";
import BubbleStyle from "../../../../Styles/Bubble";
import CloseIcon from "../../../../Icons/Close";
import GridStyle from "../../../../Styles/Grid";

const propTypes = {
  /**
   * user wallet
   */
  wallet: PropTypes.object,

  /**
   * methods are all the filtered banks under the bank payment methods
   */
  methods: PropTypes.array,

  /**
   * used to toggle between payment information screen and payment form screen
   */
  setHasPaid: PropTypes.func,

  /**
   * transactionData is the data collection in initial stages of transaction data collection
   */
  transactionData: PropTypes.object,

  /**
   * setPaymentMethod function to change the bank you want to do transaction with
   */
  setPaymentMethod: PropTypes.func,
};

const Index = ({
  wallet,
  methods,
  setHasPaid,
  transactionData,
  setPaymentMethod,
}) => {
  /**
   * variables
   */
  const currency = wallet?.currency?.abbreviation;

  /**
   * state
   */
  const [method, setMethod] = useState(methods[0]);

  /**
   * effect
   */
  useEffect(() => {
    if (method) {
      setPaymentMethod(method?.id);
    }
  }, [method, setPaymentMethod]);

  return (
    <Fade>
      <GridStyle
        columns={{ md: "repeat(2, 1fr)" }}
        gaps={{ sm: "1.5rem", md: "2.5rem" }}
      >
        <div className="text-center">
          {!method?.verified && (
            <AlertComponent
              variant="danger"
              className="mb-6"
              icon={(props) => <CloseIcon {...props} variant="circle" />}
            >
              <small className="lh-13 text-start">
                You are not verified to use bank payment methods.
              </small>
            </AlertComponent>
          )}
          <h5 className="mb-8 lh-13">
            You are paying{" "}
            <b>
              {`${helpersUtil.Money(
                transactionData?.total || transactionData?.amount,
              )} ${currency}`}
            </b>{" "}
            to our bank account.
          </h5>

          <BubbleStyle
            size={84}
            className="mx-auto mb-8"
            bg="var(--pale-grey-four)"
          >
            <BankDepositIllustration />
          </BubbleStyle>

          <div style={{ maxWidth: 320 }} className="mx-auto mb-6">
            <h5 className="mb-3">Pay to our bank account</h5>
            <Markdown
              className="lh-15"
              markdown={method?.deposit_instructions || ""}
            />
          </div>

          <ButtonComponent
            onClick={() => setHasPaid(true)}
            isValid={Boolean(method?.verified)}
          >
            I have made the payment
          </ButtonComponent>
        </div>
        <div>
          <GridStyle gaps={{ sm: "1rem" }}>
            <FieldComponent
              name="network"
              withFormik={false}
              value={method?.id}
              label="Select network"
              wrapperClassName="mb-0"
              component={SelectComponent}
              onChange={({ value }) =>
                setMethod(methods?.find(({ id }) => value === id))
              }
              options={methods?.map((method) => ({
                type: "svg",
                value: method?.id,
                label: method?.name,
                avatar: BankDepositIllustration,
              }))}
            />

            <LogoCardComponent className="white-text">
              <GridStyle gaps={{ sm: "1rem" }}>
                <small className="fw-bolder">
                  {process.env.REACT_APP_APP_NAME || "PayPlux"} Bank Deposit
                  Details
                </small>
                {method?.details &&
                  Object.keys(method.details).map((item, key) => (
                    <div key={key}>
                      <small className="bluey-grey-text mb-1">{item}</small>
                      <p className="fw-bold">{method.details[item]}</p>
                    </div>
                  ))}
              </GridStyle>
            </LogoCardComponent>
          </GridStyle>
        </div>
      </GridStyle>
    </Fade>
  );
};

Index.propTypes = propTypes;

export default Index;
