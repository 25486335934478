import React from "react";
import Props from "prop-types";

import ButtonComponent from "../../Button";
import BubbleStyle from "../../../Styles/Bubble";
import CheckIcon from "../../../Icons/Check";

const propTypes = {
  route: Props.func.isRequired,
  message: Props.func,
};

const defaultProps = {
  route: () => {},
  message: "Click on okay to see details.",
};

const Success = ({ route, message }) => (
  <div className="mx-auto" style={{ maxWidth: 348 }}>
    <BubbleStyle size={74} bg="#6DD194" className="mb-8 mx-auto">
      <CheckIcon color="#fff" size={34} />
    </BubbleStyle>
    <div className="mb-8 text-center">
      <h5 className="mb-3">Request made successfully!</h5>
      <small className="bluey-grey-text lh-13">{message}</small>
    </div>

    <ButtonComponent width={320} onClick={route} className="mx-auto">
      Okay
    </ButtonComponent>
  </div>
);

Success.propTypes = propTypes;
Success.defaultProps = defaultProps;

export default Success;
