import React from "react";
import PropTypes from "prop-types";
import Index from "./EVoucher/Index";
import Agent from "./EVoucher/Agent";

const propTypes = {
  /**
   * key to identify the transaction section
   */
  paramKey: PropTypes.string,
  /**
   * user wallet
   */
  wallet: PropTypes.object,

  /**
   * methods are all the filtered banks under the bank payment methods
   */
  methods: PropTypes.array,

  /**
   * used to toggle between payment information screen and payment form screen
   */
  setHasPaid: PropTypes.func,

  /**
   * transactionData is the data collection in initial stages of transaction data collection
   */
  transactionData: PropTypes.object.isRequired,
  /**
   * handleSubmit is for form submission
   */
  handleSubmit: PropTypes.func,
  /**
   * onSuccess for successful transaction request
   */
  onSuccess: PropTypes.func,
};

const EVoucher = (props) =>
  ["credit", "debit"].includes(props?.transactionData?.type) ? (
    <Agent {...props} />
  ) : (
    <Index {...props} />
  );

EVoucher.propTypes = propTypes;

export default EVoucher;
