import React from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";

import { DefaultWrapper, FloatWrapper } from "./Wrapper";

const propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func,
};

const defaultProps = {
  withFormik: true,
  setFieldTouched: () => {},
};

const Money = ({
  name,
  float,
  label,
  value,
  currency,
  withFormik,
  placeholder,
  setFieldValue,
  setFieldTouched,
  wrapperClassName,
  containerClassName,
  ...props
}) => {
  /**
   * variables
   */
  const Parent = float ? FloatWrapper : DefaultWrapper;

  return (
    <Parent
      {...{ name, label, withFormik, wrapperClassName, containerClassName }}
    >
      <NumberFormat
        type="tel"
        decimalScale={2}
        fixedDecimalScale
        autoComplete="nope"
        allowNegative={false}
        thousandSeparator=","
        customInput={Form.Control}
        suffix={currency ? ` ${currency}` : ""}
        onValueChange={({ floatValue }) => {
          setFieldValue(name, floatValue);
          setTimeout(() => setFieldTouched(name, true));
        }}
        {...{ name, value, placeholder, ...props }}
      />
    </Parent>
  );
};

Money.propTypes = propTypes;
Money.defaultProps = defaultProps;

/**
 * export functions
 */
export default Money;
