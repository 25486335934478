import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import CheckboxIcon from "../Icons/Checkbox";

const propTypes = {
  size: PropTypes.number,
  active: PropTypes.bool,
};

const defaultProps = {
  size: 140,
  active: false,
};

const CheckCard = ({ size, active, children, ...props }) => {
  return (
    <Wrapper {...{ size, active, ...props }}>
      {active && (
        <CheckboxIcon
          checked
          size={20}
          variant="circle"
          color="var(--clear-blue)"
          className="checkcard__icon"
        />
      )}
      <div className="text-center">{children}</div>
    </Wrapper>
  );
};

/**
 * styles
 */
const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  padding: 0.75rem;
  position: relative;
  align-items: center;
  border-radius: 0.375rem;
  justify-content: center;
  background-color: #fff;
  transition: ease-in-out 0.15s all;
  width: ${({ size }) => `${size * 0.0625}rem`};
  height: ${({ size }) => `${size * 0.0625}rem`};
  flex: 0 0 ${({ size }) => `${size * 0.0625}rem`};
  border: solid 2px
    ${({ active }) => (active ? "var(--clear-blue)" : "var(--ice-blue)")};

  .checkcard__icon {
    top: 0.75rem;
    right: 0.75rem;
    position: absolute;
  }
`;

CheckCard.propTypes = propTypes;
CheckCard.defaultProps = defaultProps;

export default CheckCard;
