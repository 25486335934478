import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 48,
};

const CashoutWallet = ({ size, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h48v48H0z" />
      <g transform="translate(4 10)">
        <path
          fill="#FFB121"
          d="M1.86 0h31.628a3.72 3.72 0 0 1 3.721 3.72v22.56A3.72 3.72 0 0 1 33.49 30H1.86A1.86 1.86 0 0 1 0 28.14V1.86A1.86 1.86 0 0 1 1.86 0z"
        />
        <path fill="#5FC484" d="M3 3h36v24H3z" />
        <path fill="#6DD194" d="M3 6h34v18H3z" />
        <path
          fill="#FFC642"
          d="M1.86 0h29.228a3.72 3.72 0 0 1 3.721 3.72v22.56A3.72 3.72 0 0 1 31.09 30H1.86A1.86 1.86 0 0 1 0 28.14V1.86A1.86 1.86 0 0 1 1.86 0z"
        />
        <path
          fill="#FFB121"
          d="M26.977 11.25H40v9.375H26.977a1.86 1.86 0 0 1-1.86-1.86V13.11a1.86 1.86 0 0 1 1.86-1.86z"
        />
        <ellipse cx="30.698" cy="15.938" fill="#E79A1D" rx="1.86" ry="1.875" />
      </g>
      <g
        fill="#E79A1D"
        stroke="#E79A1D"
        strokeLinecap="round"
        strokeWidth="1.571"
      >
        <path
          strokeLinejoin="round"
          d="M23.51 23.537l-4.37-4.371 4.37 4.371zm-8.815.073l4.444-4.444-4.444 4.444z"
        />
        <path d="M19.029 31.246V20.318" />
      </g>
    </g>
  </svg>
);

CashoutWallet.propTypes = propTypes;
CashoutWallet.defaultProps = defaultProps;

export default CashoutWallet;
