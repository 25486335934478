import React, { Fragment } from "react";
import LinkComponent from "../Link";

export const instructions = [
  {
    id: 1,
    text: (
      <Fragment>
        Install the Google authenticator app on your mobile device if you don't
        already have one.{" "}
        <LinkComponent
          href="https://itunes.apple.com/gh/app/google-authenticator/id388497605"
          rel="noopener noreferrer"
          target="_blank"
        >
          Download for iOS
        </LinkComponent>{" "}
        or{" "}
        <LinkComponent
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"
          rel="noopener noreferrer"
          target="_blank"
        >
          download for Android
        </LinkComponent>
      </Fragment>
    ),
  },
  {
    id: 2,
    text: <Fragment>Scan QR code with the authenticator app</Fragment>,
  },
  {
    id: 3,
    text: (
      <Fragment>
        Please write down or print a copy of the 16-digit secret code and put it
        in a safe place
      </Fragment>
    ),
  },
  {
    id: 4,
    text: (
      <Fragment>
        Enter the code obtained after scanning the code below.
      </Fragment>
    ),
  },
];
