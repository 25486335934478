import Success from "./DepositOrPurchase/Success";
import EVoucher from "./DepositOrPurchase/EVoucher";
import CashPayment from "./DepositOrPurchase/CashPayment";
import BankDeposit from "./DepositOrPurchase/BankDeposit";
import MobileMoney from "./DepositOrPurchase/MobileMoney";
import PrepaidWallet from "./DepositOrPurchase/PrepaidWallet";

export default Object.assign(
  {},
  {
    Success,
    EVoucher,
    CashPayment,
    BankDeposit,
    MobileMoney,
    PrepaidWallet,
  },
);
