import React, { useState } from "react";
import { object, string } from "yup";
import { Formik } from "formik";
import Fade from "react-fade-in";
import Form from "react-bootstrap/Form";

import * as helpersUtil from "../../../Utils/helpers";
import * as schemaUtil from "../../../Utils/schema";
import ButtonComponent from "../../Button";
import SelectComponent from "../../Select";
import AlertComponent from "../../Alert";
import FieldComponent from "../../Field";
import GridStyle from "../../../Styles/Grid";
import InfoIcon from "../../../Icons/Info";

const MobileMoney = ({
  user,
  wallet,
  methods,
  paramKey,
  handleSubmit,
  transactionData,
}) => {
  /**
   * state
   */
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <Fade>
      <AlertComponent icon={InfoIcon} className="small navy-text lh-13 mb-8">
        Please make sure you provide the right mobile money number and name.
        Also, note that mobile money payments take up to 30 minutes to get
        processed.
      </AlertComponent>

      <GridStyle gaps={{ sm: "2rem" }} columns={{ md: "23.125rem 15rem" }}>
        <div className="order-md-2">
          <div className="text-center border ice-blue-border pale-grey-bg rounded-3 p-6 py-md-12">
            <p className="fw-bold mb-2">You will be receiving</p>
            <h2 className="text-wrap font-monospace fw-bolder">
              {`${helpersUtil.Money(transactionData?.amount)} ${
                wallet?.currency?.abbreviation
              }`}
            </h2>
          </div>
        </div>
        <div className="order-md-1 border ice-blue-border pale-grey-bg rounded-3 p-6">
          <h5 className="mb-6">Enter your details</h5>

          <Formik
            validateOnMount
            validationSchema={object({
              [paramKey]: object().shape({
                name: schemaUtil.requireString("Name"),
                payment_method_id: schemaUtil.requireNumber("Network"),
                account_type: schemaUtil.requireString("Account type"),
                phone_number: schemaUtil.requirePhoneNumber(
                  "Mobile money number",
                ),
                business_name: string().when(
                  "account_type",
                  (account_type, schema) =>
                    account_type === "merchant"
                      ? schema.required("Business name is required")
                      : schema,
                ),
              }),
            })}
            initialValues={{
              [paramKey]: {
                account_type: "",
                phone_number: "",
                business_name: "",
                payment_method_id: "",
                name: user?.full_name || "",
              },
            }}
            onSubmit={(
              {
                [paramKey]: {
                  name,
                  business_name,
                  payment_method_id,
                  ...params
                },
              },
              actions,
            ) => {
              setSubmitting(true);

              return handleSubmit(
                {
                  payment_method_id,
                  [paramKey]: {
                    ...params,
                    payment_method_id,
                    network: methods?.find(
                      ({ id }) => id === params.payment_method_id,
                    )?.name,
                    name: business_name ? `${name}, ${business_name}` : name,
                  },
                },
                { ...actions, setSubmitting },
              );
            }}
          >
            {({
              isValid,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              values: {
                [paramKey]: {
                  name,
                  account_type,
                  phone_number,
                  business_name,
                  payment_method_id,
                },
              },
            }) => (
              <Form>
                <FieldComponent
                  value={payment_method_id}
                  component={SelectComponent}
                  placeholder="Select network"
                  name={`${paramKey}.payment_method_id`}
                  onChange={({ value }) =>
                    setFieldValue(`${paramKey}.payment_method_id`, value)
                  }
                  options={
                    methods?.map(({ id, name, image }) => ({
                      value: id,
                      label: name,
                      type: "url",
                      avatar: image,
                    })) || []
                  }
                />

                <FieldComponent
                  value={account_type}
                  placeholder="Account type"
                  component={SelectComponent}
                  name={`${paramKey}.account_type`}
                  onChange={({ value }) =>
                    setFieldValue(`${paramKey}.account_type`, value)
                  }
                  options={[
                    { label: "Merchant", value: "merchant" },
                    { label: "Subscriber", value: "subscriber" },
                  ]}
                />

                <FieldComponent.Phone
                  float
                  value={phone_number || ""}
                  label="Mobile money number"
                  defaultCountry={user?.country}
                  onlyCountries={[user?.country]}
                  name={`${paramKey}.phone_number`}
                  {...{ setFieldTouched, setFieldValue }}
                />

                <FieldComponent
                  float
                  value={name}
                  placeholder="--"
                  label="Name on account"
                  name={`${paramKey}.name`}
                />

                {account_type === "merchant" && (
                  <FieldComponent
                    float
                    placeholder="--"
                    value={business_name}
                    label="Business name"
                    name={`${paramKey}.business_name`}
                  />
                )}

                <ButtonComponent
                  type="submit"
                  onClick={handleSubmit}
                  {...{ isValid, isSubmitting }}
                >
                  Continue
                </ButtonComponent>
              </Form>
            )}
          </Formik>
        </div>
      </GridStyle>
    </Fade>
  );
};

export default MobileMoney;
