import React from "react";
import { Line } from "react-chartjs-2";
import Skeleton from "react-loading-skeleton-2";
import Card from "react-bootstrap/Card";

import { Wrapper, options, data } from "../index";

const Index = (props) => {
  return (
    <Wrapper {...props}>
      <Card.Body>
        <div className="currency__details">
          <div className="currency__details__info">
            <Skeleton width={32} height={32} circle />
            <Skeleton width={120} height={18} />
            <div className="d-flex align-items-center x-spacing-2 ms-auto">
              <Skeleton width={16} height={16} />
              <Skeleton width={40} height={16} />
            </div>
          </div>
          <Skeleton height={24} width={96} />
        </div>
        <div className="currency__chart">
          <Line {...{ options, data: data({ isLoading: true }) }} />
        </div>
      </Card.Body>
    </Wrapper>
  );
};

export default Index;
