import React from "react";
import { Formik } from "formik";
import { object } from "yup";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";
import Fade from "react-fade-in";
import Form from "react-bootstrap/Form";

import * as schema from "../../../../Utils/schema";
import ButtonComponent from "../../../Button";
import FieldComponent from "../../../Field";
import LinkComponent from "../../../Link";
import ArrowIcon from "../../../../Icons/Arrow";

const propTypes = {
  /**
   * user
   */
  user: PropTypes.object,

  /**
   * params key is the wrapper object for the data being sent to the API
   * example is payment_method_data
   */
  paramKey: PropTypes.string,

  /**
   * onSuccess, used to tell parent component task is complete
   */
  onSuccess: PropTypes.func,

  /**
   * used to toggle between payment information screen and payment form screen
   */
  setHasPaid: PropTypes.func,

  /**
   * submission function for data collected
   */
  handleSubmit: PropTypes.func,

  /**
   * paymentMethod is the ID of the payment method selected
   */
  paymentMethod: PropTypes.number,
};

const PaymentDetailsForm = ({
  user,
  paramKey,
  onSuccess,
  setHasPaid,
  handleSubmit,
  paymentMethod,
}) => {
  return (
    <Fade>
      <LinkComponent
        preventDefault
        onClick={() => setHasPaid(false)}
        className="d-flex align-items-center x-spacing-2 small navy-text fw-bolder mb-4"
      >
        <ArrowIcon variant="backward" />
        <span>GO BACK</span>
      </LinkComponent>

      <Wrapper>
        <div className="mb-4">
          <h5 className="mb-2">Enter payment details</h5>
          <p className="bluey-grey-text lh-13">
            Please fill the form below with the details of bank you want to pay
            to
          </p>
        </div>

        <Formik
          validateOnMount
          validationSchema={object({
            [paramKey]: object().shape({
              bank: schema.requireString("Bank"),
              branch: schema.requireString("Branch"),
              depositor: schema.requireString("Depositor's name"),
              datetime: schema.requireString("Transaction date"),
            }),
          })}
          initialValues={{
            [paramKey]: {
              bank: "",
              branch: "",
              depositor: user?.full_name,
              datetime: new Date(),
            },
          }}
          onSubmit={(params, { setSubmitting, setErrors }) => {
            handleSubmit({
              payment_method_id: paymentMethod,
              [paramKey]: {
                ...params[paramKey],
                payment_method_id: paymentMethod,
                datetime: dayjs(params[paramKey].datetime).format(
                  "DD MMMM YYYY",
                ),
              },
            })
              .then((response) => onSuccess(response))
              .catch((error) => setErrors(error?.fields))
              .finally(() => setSubmitting(false));
          }}
        >
          {({
            isValid,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            values: {
              [paramKey]: { bank, branch, depositor, datetime },
            },
          }) => (
            <Form>
              <FieldComponent
                float
                label="Bank"
                value={bank || ""}
                name={`${paramKey}.bank`}
                placeholder="Select bank"
              />

              <FieldComponent
                float
                label="Branch"
                value={branch || ""}
                name={`${paramKey}.branch`}
                placeholder="Select branch"
              />

              <FieldComponent
                float
                value={depositor}
                label="Depositor's name"
                placeholder="Enter your name"
                name={`${paramKey}.depositor`}
              />

              <FieldComponent.Date
                float
                value={datetime}
                dateFormat="dd MMMM yyyy"
                label="Date of transaction"
                name={`${paramKey}.datetime`}
                placeholder="Date of transaction"
                {...{ setFieldValue, setFieldTouched }}
              />

              <ButtonComponent
                type="submit"
                onClick={handleSubmit}
                {...{ isValid, isSubmitting }}
              >
                Submit
              </ButtonComponent>
            </Form>
          )}
        </Formik>
      </Wrapper>
    </Fade>
  );
};

/**
 * styles
 */
const Wrapper = styled.div`
  padding: 1.5rem;
  width: 23.125rem;
  border-radius: 0.625rem;
  background-color: var(--pale-grey);
  border: solid 0.0625rem var(--light-periwinkle);
`;

PaymentDetailsForm.propTypes = propTypes;

export default PaymentDetailsForm;
