import React, { useState, Fragment } from "react";
import Skeleton from "react-loading-skeleton-2";
import Fade from "react-fade-in";

import * as helpersUtil from "../../../Utils/helpers";
import CashoutWalletIllustration from "../../../Illustrations/CashoutWallet";
import ButtonComponent from "../../Button";
import AlertComponent from "../../Alert";
import WrapperStyle from "../../../Styles/Wrapper";
import BubbleStyle from "../../../Styles/Bubble";
import InfoIcon from "../../../Icons/Info";

const CashoutWallet = ({
  wallet,
  walletError,
  handleSubmit,
  transactionData,
}) => {
  /**
   * state
   */
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <Fade>
      {/* skeleton */}
      {!wallet && !walletError && (
        <Fragment>
          <Skeleton
            circle
            width={84}
            height={84}
            className="mx-auto mb-4 d-block"
          />
          <Skeleton height={44} width={200} className="d-block mx-auto mb-2" />
          <Skeleton height={14} width={248} className="d-block mx-auto mb-8" />

          <Skeleton height={64} className="d-block" />
        </Fragment>
      )}

      {wallet && (
        <Fragment>
          <AlertComponent
            icon={InfoIcon}
            className="small navy-text lh-13 mb-8"
          >
            You can send payment to your cash-out wallet and cash it out later.
            This wallet enables you to consolidate your funds at one place and
            make a full withdrawal whenever you want to.
          </AlertComponent>

          <BubbleStyle
            size={84}
            className="mx-auto mb-4"
            bg="var(--pale-grey-four)"
          >
            <CashoutWalletIllustration size={48} />
          </BubbleStyle>

          <WrapperStyle
            width={340}
            className="p-6 py-md-10 w-100 mx-auto mb-6 text-center rounded-3 pale-grey-four-bg border ice-blue-border"
          >
            <h2 className="mb-4 text-truncate">{`${helpersUtil.Money(
              transactionData?.amount,
            )} ${wallet?.currency?.abbreviation}`}</h2>
            <p>You will be receiving</p>
          </WrapperStyle>

          <ButtonComponent
            width={340}
            height={64}
            className="mx-auto"
            isSubmitting={isSubmitting}
            onClick={() =>
              setSubmitting(true) |
              handleSubmit({}, { setSubmitting, setErrors: () => {} })
            }
          >
            Continue
          </ButtonComponent>
        </Fragment>
      )}
    </Fade>
  );
};

export default CashoutWallet;
