import React, { useEffect, Fragment } from "react";
import { ToastContainer } from "react-toastify";
import { SWRConfig } from "swr";

import Http from "../Utils/Http";

const App = ({ children }) => {
  /**
   * effect
   */
  useEffect(() => {
    const events = ["touchstart", "scroll"];

    events.forEach((event) =>
      document.addEventListener(event, () => {}, {
        passive: true,
      }),
    );

    return () => {
      events.forEach((event) =>
        document.removeEventListener(event, () => {}, {
          passive: false,
        }),
      );
    };
  }, []);

  return (
    <Fragment>
      <SWRConfig
        value={{
          fetcher: (url) => Http.get(url).then((data) => data),
          dedupingInterval: 1000 * 15,
          shouldRetryOnError: false,
          revalidateOnFocus: false,
          errorRetryInterval: 0,
          errorRetryCount: 2,
        }}
      >
        {children}
      </SWRConfig>
      <ToastContainer
        pauseOnFocusLoss={false}
        closeOnClick={false}
        position="top-right"
        autoClose={4000}
        hideProgressBar
        pauseOnHover
        newestOnTop
        rtl={false}
        draggable
      />
    </Fragment>
  );
};

export default App;
