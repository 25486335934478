export const transactionType = (transaction, secondary = false) => {
  /**
   * walle type and type are the params used to determine the transaction type
   *
   * Transaction types are:
   * 1. Topup or Deposit -> when wallet type is prepaid and type is credit
   * 2. Bought or Purchase -> when wallet type is prepaid and type is debit
   *
   * 3. Sold or Sale -> when wallet type is withdrawable and type is credit
   * 4. Withdrawal -> when wallet type is withdrawable and type is debit
   *
   * For Bulk  Orders
   * 5. Bulk Buy
   * 6. Bulk Sell
   */
  const walletType = transaction?.wallet_type?.toLowerCase() || ""; // withdrawable or prepaid
  const isReservation = ["RESERVATION_BUY", "RESERVATION_SELL"].includes(
    transaction?.status?.type,
  );

  const type = isReservation
    ? `bulk${transaction?.reservation_type}` // bulksell or bulkbuy
    : transaction?.type || ""; // debit or credit

  // concat type and wallet name for easy switch statement
  switch (type.concat(walletType)) {
    case "credit".concat("prepaid"):
      return secondary ? "topup" : "deposit";
    case "debit".concat("prepaid"):
    case "buy":
      return secondary ? "bought" : "purchase";
    case "credit".concat("withdrawable"):
    case "sell":
      return secondary ? "sold" : "sale";
    case "debit".concat("withdrawable"):
      return "withdrawal";
    case "bulkbuy":
      return "Bulk Buy";
    case "bulksell":
      return "Bulk Sell";
    default:
      return "unknown";
  }
};

export const transactionProgressStatus = (slug) => {
  switch (slug) {
    case "user-buy-has-errors":
      return [
        {
          state: "error",
          desc: "Order has errors",
        },
        {
          desc: "Processing",
          state: "stale",
        },
        {
          desc: "Asset sent",
          state: "stale",
        },
      ];
    case "user-buy-awaiting-payment-confirmation":
      return [
        {
          desc: "Confirming payment",
          state: "active",
        },
        {
          desc: "Processing",
          state: "stale",
        },
        {
          desc: "Asset sent",
          state: "stale",
        },
      ];
    case "buy-reservation-pending":
      return [
        {
          desc: "Confirming payment",
          state: "active",
        },
        {
          desc: "Processing",
          state: "stale",
        },
        {
          desc: "Asset sent",
          state: "stale",
        },
      ];
    case "user-buy-processing":
      return [
        {
          desc: "Payment confirmed",
          state: "complete",
        },
        {
          desc: "Processing",
          state: "active",
        },
        {
          desc: "Asset sent",
          state: "stale",
        },
      ];
    case "buy-reservation-processing":
      return [
        {
          desc: "Payment confirmed",
          state: "complete",
        },
        {
          desc: "Processing",
          state: "active",
        },
        {
          desc: "Asset sent",
          state: "stale",
        },
      ];
    case "user-buy-queued":
      return [
        {
          desc: "Payment confirmed",
          state: "complete",
        },
        {
          desc: "Queued",
          state: "state queued",
        },
        {
          desc: "Asset Sent",
          state: "stale",
        },
      ];
    case "buy-reservation-queued":
      return [
        {
          desc: "Payment confirmed",
          state: "complete",
        },
        {
          desc: "Queued",
          state: "state queued",
        },
        {
          desc: "Asset Sent",
          state: "stale",
        },
      ];
    case "user-buy-processed":
      return [
        {
          desc: "Payment confirmed",
          state: "complete",
        },
        {
          desc: "Processed",
          state: "complete",
        },
        {
          desc: "Asset sent",
          state: "complete",
        },
      ];
    case "buy-reservation-processed":
      return [
        {
          desc: "Payment confirmed",
          state: "complete",
        },
        {
          desc: "Processed",
          state: "complete",
        },
        {
          desc: "Asset sent",
          state: "complete",
        },
      ];
    case "user-buy-cancelled":
      return [
        {
          desc: "Order cancelled",
          state: "error",
        },
        {
          desc: "Processing",
          state: "stale",
        },
        {
          desc: "Asset sent",
          state: "stale",
        },
      ];
    case "buy-reservation-cancelled":
      return [
        {
          desc: "Order cancelled",
          state: "error",
        },
        {
          desc: "Processing",
          state: "stale",
        },
        {
          desc: "Asset sent",
          state: "stale",
        },
      ];

    // Sell Order
    case "user-sell-cancelled":
      return [
        {
          desc: "Order canceelled",
          state: "error",
        },
        {
          desc: "Confirmation",
          state: "stale",
        },
        {
          desc: "Processing",
          state: "stale",
        },
      ];
    case "user-sell-has-errors":
      return [
        {
          desc: "Sell has error",
          state: "error",
        },
        {
          desc: "Confirmation",
          state: "stale",
        },
        {
          desc: "Processing",
          state: "stale",
        },
      ];
    case "user-sell-awaiting-ecurrency":
      return [
        {
          desc: "Awaiting Asset",
          state: "active",
        },
        {
          desc: "Confirmation",
          state: "stale",
        },
        {
          desc: "Processing",
          state: "stale",
        },
      ];
    case "user-sell-ecurrency-awaiting-confirmation":
      return [
        {
          desc: "Asset recieved",
          state: "complete",
        },
        {
          desc: "Confirming asset",
          state: "active",
        },
        {
          desc: "Processing",
          state: "stale",
        },
      ];
    case "user-sell-processing":
      return [
        {
          desc: "Asset recieved",
          state: "complete",
        },
        {
          desc: "Asset confirmed",
          state: "complete",
        },
        {
          desc: "Processing",
          state: "active",
        },
      ];
    case "user-sell-processed":
      return [
        {
          desc: "Asset recieved",
          state: "complete",
        },
        {
          desc: "Asset confirmed",
          state: "complete",
        },
        {
          desc: "Processed",
          state: "complete",
        },
      ];
    case "wallet-credit-awaiting-payment":
      return [
        {
          desc: "Awaiting Payment",
          state: "active",
        },
        {
          desc: "Confirmation",
          state: "stale",
        },
        {
          desc: "Processing",
          state: "stale",
        },
      ];

    default:
      return [
        {
          desc: "Awaiting Payment",
          state: "stale",
        },
        {
          desc: "Confirmation",
          state: "stale",
        },
        {
          desc: "Processing",
          state: "stale",
        },
      ];
  }
};
