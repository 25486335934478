import styled from "styled-components";
import PropTypes from "prop-types";

const propTypes = {
  width: PropTypes.number.isRequired,
};

const Wrapper = styled.div`
  width: 100%;
  max-width: ${({ width }) => `${width * 0.0625}rem`};
  flex: 0 0 ${({ width }) => `${width * 0.0625}rem`};
`;

Wrapper.propTypes = propTypes;

export default Wrapper;
