import React from "react";
import { handleStatusColor } from "../../Utils/helpers";
import Badge from "../Badge";

const Status = ({ transaction }) => {
  /**
   * variables
   */
  const color = handleStatusColor(transaction?.status?.css_class);

  return <Badge bg={color}>{transaction?.status?.name?.toUpperCase()}</Badge>;
};

export default Status;
