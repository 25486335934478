import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import copyToClipboard from "../Utils/copyToClipboard";

/**
 * props definition
 */
const propTypes = {
  content: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

const CopyItem = ({ label, content, ...props }) => {
  return (
    <Wrapper className="copy-item" {...props}>
      <p className="copy-text" onClick={() => copyToClipboard(content)}>
        COPY
      </p>
      <small className="mb-2 bluey-grey-text">{label}</small>
      <p className="fw-bold">{content}</p>
    </Wrapper>
  );
};

/**
 * styles
 */

const Wrapper = styled.div`
  position: relative;
  border: solid 0.0625rem var(--ice-blue);
  background-color: var(--pale-grey);
  border-radius: 0.25rem;
  max-width: 23.5rem;
  overflow: auto;
  margin: ${({ margin }) => margin || "0 auto"};
  padding: 1.5rem;

  .copy-text {
    background: var(--clear-blue);
    border-radius: 0rem 0.1875rem;
    color: white;
    cursor: copy;
    font-size: 0.75rem;
    font-weight: 600;
    height: 1.5rem;
    padding: 0.35rem 1.35rem;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

CopyItem.propTypes = propTypes;
export default CopyItem;
