import React, { Fragment } from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number,
  variant: PropTypes.oneOf(["stacked", "bagged", "notes", "coins"]),
};

const defaultProps = {
  size: 48,
  variant: "notes",
};

const Cash = ({ size, variant, ...props }) => {
  return (
    <svg
      fill="none"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {variant === "stacked" && (
        <Fragment>
          <rect x="3" y="32" width="40" height="7" fill="#52B875" />
          <rect x="6" y="25" width="40" height="7" fill="#6DD194" />
          <rect x="6" y="18" width="40" height="7" fill="#52B875" />
          <rect x="2" y="11" width="40" height="7" fill="#6DD194" />
          <rect x="17" y="11" width="8" height="7" fill="white" />
          <rect x="23" y="18" width="8" height="7" fill="white" />
          <rect x="23" y="25" width="8" height="7" fill="white" />
          <rect x="20" y="32" width="8" height="7" fill="white" />
        </Fragment>
      )}

      {variant === "bagged" && (
        <Fragment>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.9999 9.00001C17.4999 9.00001 15.9999 6.00001 15.9999 6.00001L19.2313 17.9213C14.6426 19.5268 8.82921 23.5119 5.99985 32C3.99985 38 14.0587 42 23.9999 42C33.941 42 43.9999 38 41.9999 32C38.5204 22.9534 33.1213 19.2338 28.7991 17.808L31.9999 6.00001C31.9999 6.00001 30.621 8.00001 29.9999 8.00001C29.6004 8.00001 29.2089 7.4198 28.8477 6.8844C28.5382 6.42576 28.2509 6.00001 27.9999 6.00001C27.3037 6.00001 27.0383 6.64618 26.7539 7.33874C26.4255 8.13845 26.0716 9.00001 24.9999 9.00001C23.9357 9.00001 23.5649 8.08231 23.2326 7.25987C22.9404 6.5366 22.678 5.88699 21.9999 6.00001C21.2982 6.11695 20.9244 6.8239 20.5551 7.52213C20.1617 8.26602 19.7736 9.00001 18.9999 9.00001Z"
            fill="#52B875"
          />
          <rect x="18" y="16" width="12" height="2" rx="1" fill="#FFC642" />
          <path
            d="M18.9998 17C18.9998 17 12.9998 25 13.9998 38"
            stroke="#FFC642"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M19 17C19 17 11 21 10 29"
            stroke="#FFC642"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.57 32.3101V33.9H25.117V32.3322C25.5334 32.2837 25.9131 32.1886 26.256 32.047C26.7773 31.8317 27.208 31.5313 27.548 31.146C27.888 30.7607 28.126 30.3073 28.262 29.786L26.477 29.174C26.3977 29.5253 26.2673 29.8257 26.086 30.075C25.9047 30.3243 25.678 30.5113 25.406 30.636C25.3148 30.6778 25.2184 30.7126 25.117 30.7404V25.1761C25.3324 25.2469 25.5251 25.3555 25.695 25.502C26.0237 25.7853 26.2503 26.2047 26.375 26.76L28.228 26.029C28.0807 25.553 27.8483 25.1252 27.531 24.7455C27.2137 24.3658 26.7972 24.0683 26.2815 23.853C25.9452 23.7126 25.557 23.6179 25.117 23.5691V22H23.57V23.5832C23.0212 23.6593 22.5225 23.8173 22.074 24.057C21.4167 24.4083 20.9067 24.9127 20.544 25.57C20.1813 26.2273 20 27.0207 20 27.95C20 28.8793 20.1813 29.6727 20.544 30.33C20.9067 30.9873 21.4223 31.4917 22.091 31.843C22.5342 32.0759 23.0272 32.2316 23.57 32.3101ZM23.57 30.6736V25.2226C23.2458 25.3405 22.9624 25.5299 22.72 25.791C22.278 26.267 22.057 26.9923 22.057 27.967C22.057 28.6243 22.1618 29.1627 22.3715 29.582C22.5812 30.0013 22.8673 30.313 23.23 30.517C23.3392 30.5784 23.4526 30.6306 23.57 30.6736Z"
            fill="white"
          />
        </Fragment>
      )}

      {variant === "coins" && (
        <Fragment>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45 9C45 11.1977 36.5703 13.3404 26.1724 13.3404C15.7745 13.3404 7.34483 11.1977 7.34483 9V14.0609C7.35062 16.2572 15.7774 18.4043 26.1724 18.4043C36.5674 18.4043 44.9942 16.2572 45 14.0609V9ZM21.8276 22.0213C32.2255 22.0213 40.6552 19.8786 40.6552 17.6809V22.7418C40.6494 24.938 32.2226 27.0851 21.8276 27.0851C11.4326 27.0851 3.00579 24.938 3 22.7418V17.6809C3 19.8786 11.4297 22.0213 21.8276 22.0213ZM45 25.6383C45 27.836 36.5703 29.9787 26.1724 29.9787C15.7745 29.9787 7.34483 27.836 7.34483 25.6383V30.6992C7.35062 32.8955 15.7774 35.0426 26.1724 35.0426C36.5674 35.0426 44.9942 32.8955 45 30.6992V25.6383ZM42.8276 33.5957C42.8276 35.7934 34.3979 37.9362 24 37.9362C13.6021 37.9362 5.17241 35.7934 5.17241 33.5957V38.6567C5.1782 40.8529 13.605 43 24 43C34.395 43 42.8218 40.8529 42.8276 38.6567V33.5957Z"
            fill="#FFB121"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45 9.19727C45 11.6821 36.4931 14 26 14C15.5069 14 7 11.6821 7 9.19727C7 6.71164 15.5069 5 26 5C36.4931 5 45 6.71164 45 9.19727ZM40.4502 23C39.2386 25.0873 31.271 26.9837 21.6154 26.9837C15.7678 26.9837 10.5398 26.2877 7.05481 25.2758C7.02192 25.3738 7 25.4726 7 25.5736C7 27.8637 15.5069 30 26 30C36.4931 30 45 27.8637 45 25.5736C45 24.5458 43.2849 23.6628 40.4502 23Z"
            fill="#FFC642"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M41 12V16.5773C40.5578 16.7239 40.0735 16.8652 39.5455 17V12.4221C40.0735 12.2873 40.5578 12.1459 41 12ZM12 12.4549V17.3886C11.472 17.2433 10.9876 17.091 10.5455 16.933V12C10.9876 12.158 11.472 12.3096 12 12.4549ZM34.7273 18.4831V13.548C34.2531 13.6065 33.7695 13.6622 33.2727 13.7116V18.6467C33.7695 18.5973 34.2531 18.5423 34.7273 18.4831ZM18.7273 13.7123V18.6474C18.2305 18.5973 17.7469 18.5423 17.2727 18.4838V13.548C17.7469 13.6079 18.2305 13.6629 18.7273 13.7123ZM26.7273 14.0592C26.4858 14.0614 26.2436 14.0635 26 14.0635C25.7564 14.0635 25.5142 14.0614 25.2727 14.0592V18.9958C25.5142 18.9979 25.7564 19 26 19C26.2436 19 26.4858 18.9979 26.7273 18.9958V14.0592Z"
            fill="#EAA11A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M38 20V24.933C37.5578 25.091 37.0735 25.2433 36.5455 25.3886V20.4549C37.0735 20.3096 37.5578 20.1573 38 20ZM7.45455 20.4549V25.3886C6.92655 25.2433 6.44218 25.091 6 24.933V20C6.44218 20.158 6.92655 20.3096 7.45455 20.4549ZM30.7273 26.4838V21.5487C30.2531 21.6072 29.7695 21.6622 29.2727 21.7123V26.6474C29.7695 26.598 30.2531 26.543 30.7273 26.4838ZM14.7273 21.7116V26.6474C14.2305 26.5973 13.7469 26.5423 13.2727 26.4838V21.5487C13.7469 21.6079 14.2305 21.6629 14.7273 21.7116ZM22.7273 22.0592C22.4858 22.0614 22.2436 22.0635 22 22.0635C21.7564 22.0635 21.5142 22.0614 21.2727 22.0592V26.9958C21.5142 26.9979 21.7564 27 22 27C22.2436 27 22.4858 26.9979 22.7273 26.9958V22.0592Z"
            fill="#EAA11A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42 28V32.933C41.5578 33.091 41.0735 33.2426 40.5455 33.3886V28.4549C41.0735 28.3096 41.5578 28.1573 42 28ZM11.4545 28.4549V33.3886C10.9265 33.2433 10.4422 33.091 10 32.933V28C10.4422 28.158 10.9265 28.3096 11.4545 28.4549ZM34.7273 34.4838V29.5487C34.2531 29.6072 33.7695 29.6622 33.2727 29.7123V34.6474C33.7695 34.598 34.2531 34.543 34.7273 34.4838ZM18.7273 29.7116V34.6474C18.2305 34.5973 17.7469 34.5423 17.2727 34.4838V29.5487C17.7469 29.6079 18.2305 29.6629 18.7273 29.7116ZM26.7273 30.0592C26.4858 30.0614 26.2436 30.0635 26 30.0635C25.7564 30.0635 25.5142 30.0614 25.2727 30.0592V34.9958C25.5142 34.9979 25.7564 35 26 35C26.2436 35 26.4858 34.9979 26.7273 34.9958V30.0592Z"
            fill="#EAA11A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40 36V40.933C39.5578 41.091 39.0735 41.2426 38.5455 41.3886V36.4549C39.0735 36.3096 39.5578 36.1573 40 36ZM9.45455 36.4549V41.3886C8.92655 41.2433 8.44218 41.091 8 40.933V36C8.44218 36.158 8.92655 36.3096 9.45455 36.4549ZM32.7273 42.4838V37.5487C32.2531 37.6072 31.7695 37.6622 31.2727 37.7123V42.6474C31.7695 42.598 32.2531 42.543 32.7273 42.4838ZM16.7273 37.7116V42.6474C16.2305 42.5973 15.7469 42.5423 15.2727 42.4838V37.5487C15.7469 37.6079 16.2305 37.6629 16.7273 37.7116ZM24.7273 38.0592C24.4858 38.0614 24.2436 38.0635 24 38.0635C23.7564 38.0635 23.5142 38.0614 23.2727 38.0592V42.9958C23.5142 42.9979 23.7564 43 24 43C24.2436 43 24.4858 42.9979 24.7273 42.9958V38.0592Z"
            fill="#EAA11A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.2727 18.2967C17.6371 18.2967 10.3585 16.7924 8.096 15C4.93673 15.6622 3 16.5769 3 17.6523C3 19.9024 11.4662 22 21.9091 22C32.352 22 40.8182 19.9024 40.8182 17.6523C40.8182 17.3338 40.6429 17.0302 40.3222 16.7413C36.8611 17.6686 31.8495 18.2967 26.2727 18.2967ZM26.2727 35.0748C17.4269 35.0748 10.0022 33.5172 7.93673 31.6904C6.18909 32.2571 5.18182 32.9416 5.18182 33.7073C5.18182 35.929 13.6473 38 24.0909 38C34.5338 38 43 35.929 43 33.7073C43 33.4023 42.8349 33.1098 42.5316 32.8334C39.2349 34.1219 33.1891 35.0748 26.2727 35.0748Z"
            fill="#F5BE3F"
          />
        </Fragment>
      )}

      {variant === "notes" && (
        <Fragment>
          <rect x="3" y="6" width="42" height="22" fill="#44AB65" />
          <rect x="5" y="8" width="38" height="18" fill="#6DD194" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 22C17.7614 22 20 19.7614 20 17C20 14.2386 17.7614 12 15 12C12.2386 12 10 14.2386 10 17C10 19.7614 12.2386 22 15 22Z"
            fill="#A8DBBA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 11C6.88071 11 8 9.88071 8 8.5C8 7.11929 6.88071 6 5.5 6C4.11929 6 3 7.11929 3 8.5C3 9.88071 4.11929 11 5.5 11Z"
            fill="#44AB65"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.5 11C43.8807 11 45 9.88071 45 8.5C45 7.11929 43.8807 6 42.5 6C41.1193 6 40 7.11929 40 8.5C40 9.88071 41.1193 11 42.5 11Z"
            fill="#44AB65"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 28C6.88071 28 8 26.8807 8 25.5C8 24.1193 6.88071 23 5.5 23C4.11929 23 3 24.1193 3 25.5C3 26.8807 4.11929 28 5.5 28Z"
            fill="#44AB65"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.5 28C43.8807 28 45 26.8807 45 25.5C45 24.1193 43.8807 23 42.5 23C41.1193 23 40 24.1193 40 25.5C40 26.8807 41.1193 28 42.5 28Z"
            fill="#44AB65"
          />
          <rect x="3" y="12" width="42" height="22" fill="#44AB65" />
          <rect x="5" y="14" width="38" height="18" fill="#6DD194" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 28C17.7614 28 20 25.7614 20 23C20 20.2386 17.7614 18 15 18C12.2386 18 10 20.2386 10 23C10 25.7614 12.2386 28 15 28Z"
            fill="#A8DBBA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 17C6.88071 17 8 15.8807 8 14.5C8 13.1193 6.88071 12 5.5 12C4.11929 12 3 13.1193 3 14.5C3 15.8807 4.11929 17 5.5 17Z"
            fill="#44AB65"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.5 17C43.8807 17 45 15.8807 45 14.5C45 13.1193 43.8807 12 42.5 12C41.1193 12 40 13.1193 40 14.5C40 15.8807 41.1193 17 42.5 17Z"
            fill="#44AB65"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 34C6.88071 34 8 32.8807 8 31.5C8 30.1193 6.88071 29 5.5 29C4.11929 29 3 30.1193 3 31.5C3 32.8807 4.11929 34 5.5 34Z"
            fill="#44AB65"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.5 34C43.8807 34 45 32.8807 45 31.5C45 30.1193 43.8807 29 42.5 29C41.1193 29 40 30.1193 40 31.5C40 32.8807 41.1193 34 42.5 34Z"
            fill="#44AB65"
          />
          <rect x="3" y="19" width="42" height="22" fill="#44AB65" />
          <rect x="5" y="21" width="38" height="18" fill="#6DD194" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15 35C17.7614 35 20 32.7614 20 30C20 27.2386 17.7614 25 15 25C12.2386 25 10 27.2386 10 30C10 32.7614 12.2386 35 15 35Z"
            fill="#A8DBBA"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 24C6.88071 24 8 22.8807 8 21.5C8 20.1193 6.88071 19 5.5 19C4.11929 19 3 20.1193 3 21.5C3 22.8807 4.11929 24 5.5 24Z"
            fill="#44AB65"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.5 24C43.8807 24 45 22.8807 45 21.5C45 20.1193 43.8807 19 42.5 19C41.1193 19 40 20.1193 40 21.5C40 22.8807 41.1193 24 42.5 24Z"
            fill="#44AB65"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.5 41C6.88071 41 8 39.8807 8 38.5C8 37.1193 6.88071 36 5.5 36C4.11929 36 3 37.1193 3 38.5C3 39.8807 4.11929 41 5.5 41Z"
            fill="#44AB65"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.5 41C43.8807 41 45 39.8807 45 38.5C45 37.1193 43.8807 36 42.5 36C41.1193 36 40 37.1193 40 38.5C40 39.8807 41.1193 41 42.5 41Z"
            fill="#44AB65"
          />
        </Fragment>
      )}
    </svg>
  );
};

Cash.propTypes = propTypes;
Cash.defaultProps = defaultProps;

export default Cash;
