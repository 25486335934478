import React from "react";
import styled from "styled-components";

import LogoIcon from "../Icons/Logo";

const LogoCard = ({ children, ...props }) => {
  return (
    <Wrapper {...props}>
      {children} <Logo color="grey" variant="logo" size={30} />
    </Wrapper>
  );
};

/**
 * styles
 */
const Logo = styled(LogoIcon)`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  opacity: 0.5;
`;

const Wrapper = styled.div`
  padding: 1.5rem;
  position: relative;
  border-radius: 0.25rem;
  background-color: var(--navy);

  small {
    text-transform: capitalize;
  }

  p {
    * {
      color: var(--white);
    }
  }
`;

export default LogoCard;
