const PaymentMethod = ({ transaction }) => {
  const isWallet = Boolean(transaction?.wallet_type);

  /**
   * variables
   */
  if (!isWallet) {
    const userTransaction = transaction?.user_transaction;

    return userTransaction
      ? userTransaction?.payment_method?.name || "--"
      : transaction?.order_type === "buy"
      ? "Prepaid wallet balance"
      : "Sent to cashout balance";
  }

  if (isWallet) {
    const isExternal = transaction?.order;

    if (isExternal) {
      const userTransaction = transaction?.order?.user_transaction;

      return userTransaction
        ? userTransaction?.payment_method?.name || "--"
        : transaction?.order?.order_type === "buy"
        ? "Prepaid wallet balance"
        : "Sent to cashout balance";
    } else {
      return transaction?.payment_method?.name || "--";
    }
  }
};

export default PaymentMethod;
