const useSession = () => {
  /**
   * variables
   */
  const session = window.sessionStorage;

  /**
   * functions
   */
  const setItem = (key, value) => {
    return session.setItem(key, JSON.stringify(value));
  };

  const getItem = (key) => {
    return JSON.parse(session.getItem(key));
  };

  const deleteItem = (key) => {
    return session.removeItem(key);
  };

  return { setItem, getItem, deleteItem };
};

export default useSession;
