import startCase from "lodash/startCase";

import { transactionType } from "./index";

const Type = ({ transaction, secondary }) => {
  /**
   * variable
   */
  const type = transactionType(transaction, secondary);

  return type === "unknown" ? "--" : startCase(type);
};

export default Type;
