import React from "react";
import Img from "react-bootstrap/Image";

import { transactionType } from "./index";

const Image = ({ transaction }) => {
  /**
   * variables
   */
  const order = transaction?.order;
  const isCrypto = ["purchase", "sale"].includes(transactionType(transaction));
  const isReservation = ["Bulk Sell", "Bulk Buy"].includes(
    transactionType(transaction),
  );
  const imageUrl = (() => {
    if (isCrypto) {
      return `/assets/${order?.ecurrency?.short_name?.toLowerCase()}.svg`;
    } else if (isReservation) {
      return `/assets/${transaction?.ecurrency?.short_name?.toLowerCase()}.svg`;
    } else {
      return transaction?.payment_method?.image || "";
    }
  })();

  return <Img src={imageUrl} className="img-fit" />;
};

export default Image;
