import React, { useState, Fragment } from "react";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton-2";
import styled from "styled-components";
import Flag from "react-country-flag";
import Form from "react-bootstrap/Form";

import SelectComponent from "./Select";
import FieldComponent from "./Field";
import TrendingIcon from "../Icons/Trending";
import removeTagsUtil from "../Utils/removeTags";
import loopUtil from "../Utils/loop";

const propTypes = {
  /**
   * label
   */
  label: PropTypes.string,
  /**
   * rate
   */
  rate: PropTypes.number.isRequired,
  /**
   * error
   */
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * transaction type
   */
  type: PropTypes.oneOf(["sell", "buy", "bulk", "redeem"]).isRequired,
  /**
   * the selected ecurrency from API
   */
  ecurrency: PropTypes.object,
  /**
   * the list of assets
   */
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      type: PropTypes.string,
      label: PropTypes.string.isRequired,
      avatar: PropTypes.string,
    }),
  ),
  /**
   * values from formik form
   */
  values: PropTypes.object.isRequired,
  /**
   * function to update asset
   */
  setFieldValue: PropTypes.func.isRequired,
  /**
   * handleBlur function from formik to make validation schema work
   */
  handleBlur: PropTypes.func.isRequired,
  /**
   * inputs information in array | 2 objects of the below shape is expected
   */
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      currency: PropTypes.shape({
        symbol: PropTypes.string,
        abbr: PropTypes.string,
        country_code: PropTypes.string,
      }),
      onChange: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  ),
};

const defaultProps = {
  rate: 0,
  assets: [],
};

const AssetForm = ({
  rate,
  type,
  error,
  label,
  assets,
  inputs,
  values,
  ecurrency,
  handleBlur,
  setFieldValue,
  ...props
}) => {
  /**
   * variables
   */
  const currency = (() => {
    if (ecurrency) {
      // check if settings is an array or object
      const settings = Array.isArray(ecurrency)
        ? ecurrency?.settings[0]
        : ecurrency?.settings;

      // if settings is valid, get rate and currency
      if (settings) {
        return settings?.currency?.abbreviation;
      } else {
        return null;
      }
    } else {
      return null;
    }
  })();

  const buyerInstruction = (() => {
    if (ecurrency) {
      // check if settings is an array or object
      const settings = Array.isArray(ecurrency)
        ? ecurrency?.settings[0]
        : ecurrency?.settings;

      // if settings is valid, get buyer instructions
      if (settings) {
        return settings?.buyer_instruction;
      } else {
        return null;
      }
    } else {
      return null;
    }
  })();

  /**
   * state
   */
  const [touched, setTouched] = useState(false);
  const [focused, setFocused] = useState();

  return (
    <div {...props}>
      {/* asset select dropdown | using react select
       * see docs for further understanding
       * https://react-select.com/
       */}
      <AssetSelector>
        {assets?.length > 0 ? (
          <FieldComponent
            name="asset"
            withFormik={false}
            isSearchable={false}
            value={values?.asset || ""}
            component={SelectComponent}
            label={label || "Select digital asset"}
            containerClassName="ice-blue-bg border-0"
            onChange={({ value }) => setFieldValue("asset", value)}
            options={assets || []}
          />
        ) : (
          <Fragment>
            <Skeleton height={14} className="mb-2" width={120} />
            <Skeleton height={46} width="100%" />
          </Fragment>
        )}
      </AssetSelector>
      {/** show buyer instructions **/}
      {type === "buy" && buyerInstruction && (
        <div className="buyer-instruction d-flex mb-2">
          <h5 className="pale-red-text">{removeTagsUtil(buyerInstruction)}</h5>
        </div>
      )}
      {/*
       * show loading state if ecurrency is still loading from db
       */}
      <div className="position-relative">
        {!ecurrency ? (
          <Fragment>
            <FormWrapper>
              {loopUtil(inputs.length || 2).map((index) => (
                <div className="input d-flex" key={index}>
                  <Skeleton
                    height={84}
                    width="100%"
                    className="border-end light-periwinkle-border rounded-0"
                  />
                  <Skeleton
                    width={92}
                    height={84}
                    className="rounded-0"
                    style={{ flex: "0 0 5.75rem" }}
                  />
                </div>
              ))}
            </FormWrapper>
            <Exchange>
              <Skeleton height={34} />
            </Exchange>
          </Fragment>
        ) : (
          <Fragment>
            <FormWrapper onBlur={() => inputs[0]?.value && setTouched(true)}>
              {/* this component is using NumberFormat
               * do see the docs for further understanding
               * https://www.npmjs.com/package/react-number-format
               */}
              {inputs.map((input, key) => (
                <div key={key} className="input">
                  <div className="d-flex align-items-center">
                    <div className="form-floating w-100">
                      <NumberFormat
                        autoComplete="nope"
                        decimalScale={2}
                        name={input.name}
                        fixedDecimalScale
                        value={input.value}
                        onBlur={handleBlur}
                        allowNegative={false}
                        thousandSeparator=","
                        placeholder={input.label}
                        customInput={Form.Control}
                        prefix={input.currency.symbol}
                        onFocus={() => setFocused(input.name)}
                        {...(focused === input.name && {
                          onValueChange: ({ floatValue }) =>
                            input.onChange(floatValue),
                        })}
                      />
                      <label>{input.label}</label>
                    </div>
                    <Currency withCaret={key === 0}>
                      <div className="m-auto w-100">
                        <p className="font-monospace fw-bolder mb-1">
                          {input.currency.abbr}
                        </p>
                        <Flag
                          svg
                          countryCode={input.currency.country_code}
                          style={{ width: "1.75rem", height: "1.75rem" }}
                        />
                      </div>
                    </Currency>
                  </div>
                </div>
              ))}
            </FormWrapper>

            <Exchange className="d-flex align-items-center justify-content-center exchange x-spacing-4">
              <TrendingIcon variant="up" size={18} />
              <small className="font-monospace fw-bolder">
                {type === "redeem" ? (
                  <Fragment>{rate && `1 unit = ${rate} pts`}</Fragment>
                ) : (
                  <Fragment>{rate && `1 USD = ${rate} ${currency}`}</Fragment>
                )}
              </small>
            </Exchange>

            {error && touched && (
              <small className="d-block pale-red-text mt-3">{error}</small>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

/**
 * styles
 */
const AssetSelector = styled.div`
  width: 13.125rem;
  margin-bottom: 2rem; ;
`;

const Currency = styled.div`
  display: flex;
  height: 5.25rem;
  flex: 0 0 5.75rem;
  text-align: center;
  position: relative;
  background-color: var(--pale-grey);
  border-left: solid 0.0625rem var(--light-periwinkle);

  ${({ withCaret }) =>
    withCaret
      ? `
        border-radius: 0 0.375rem 0 0 ;

        &::before,
        &::after {
            width: 0;
            top: 100%;
            left: 50%;
            height: 0;
            z-index: 5;
            content: " ";
            position: absolute;
            pointer-events: none;
            border: solid transparent;
        }

        &::before {
            border-width: 0.4375rem;
            margin-left: -0.4375rem;
            border-top-color: var(--light-periwinkle);
        }

        &::after {
            margin-left: -0.3125rem;
            border-width: 0.3125rem;
            border-color: transparent;
            border-top-color: var(--pale-grey);
        }
    `
      : `
        border-radius: 0 0 0.375rem 0;
    `}
`;

const Exchange = styled.div`
  z-index: 1;
  width: 10.875rem;
  height: 2.125rem;
  overflow: hidden;
  margin-top: -1px;
  margin-left: auto;
  background-color: var(--pale-grey);
  border-radius: 0 0 0.375rem 0.375rem;
  border: solid 0.0625rem var(--light-periwinkle);

  small {
    font-size: 0.75rem;
  }

  @media (min-width: 768px) {
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    right: 5.625rem;
    position: absolute;
    border-radius: 1.25rem;
    background-color: #fff;
    border: solid 0.0625rem var(--light-periwinkle);
  }
`;

const FormWrapper = styled.div`
  z-index: 1;
  position: relative;
  border-radius: 0.375rem 0.375rem 0 0.375rem;
  border: solid 0.0625rem var(--light-periwinkle);

  .input {
    &:first-child {
      border-bottom: solid 0.0625rem var(--light-periwinkle);
    }

    .form-currency {
      margin-bottom: 0.0625rem;
      font-weight: 600;
    }

    .form-control {
      font-family: var(--font-family-titillium);
      padding-bottom: 0rem;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  @media (min-width: 768px) {
    border-radius: 0.375rem;
  }
`;

AssetForm.propTypes = propTypes;
AssetForm.defaultProps = defaultProps;

export default React.memo(AssetForm);
