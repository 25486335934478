import { Money } from "../../Utils/helpers";

const Amount = ({
  transaction,
  local = true,
  withSign = false,
  reservation,
}) => {
  /**
   * variables
   */
  const currency = transaction?.wallet?.currency; // get currency
  const sign = (() => {
    const orderType =
      transaction?.order_type?.toLowerCase() ||
      transaction?.type?.toLowerCase();

    if (withSign && ["debit", "credit"].includes(orderType)) {
      return orderType === "credit" ? "+" : "-";
    }

    return "";
  })(); // check if sign is needed and add sign

  const amount = (() => {
    if (local) {
      return Money(transaction?.amount);
    }

    if (!local) {
      return Money(
        transaction?.amount / transaction?.ecurrency_rate -
          transaction?.fee_usd,
      );
    }

    if (reservation) {
      return Money(transaction?.request_data?.amount_usd);
    }
  })(); // check if local amount or usd value is need and return

  return `${sign} ${amount} ${
    local ? currency?.abbreviation?.toUpperCase() : "USD"
  }`;
};

export default Amount;
