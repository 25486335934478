import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const propTypes = {
  direction: PropTypes.oneOf(["start", "center", "end"]),
};

const defaultProps = {
  direction: "start",
};

const Th = ({ children, ...props }) => {
  return (
    <th>
      <Wrapper {...props}>{children}</Wrapper>
    </th>
  );
};

/**
 * styles
 */
const Wrapper = styled.div`
  display: flex;
  height: 1.875rem;
  align-items: center;
  padding: 0.5rem 1rem;
  justify-content: ${({ direction }) => {
    switch (direction) {
      case "start":
      case "end":
        return `flex-${direction}`;
      default:
        return "center";
    }
  }};

  @media (min-width: 768px) {
    padding: 0.5rem 1.5rem;
  }
`;

Th.propTypes = propTypes;
Th.defaultProps = defaultProps;

export default Th;
