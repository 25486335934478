import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 48,
};

const Illustration = ({ size, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h48v48H0z" />
      <g transform="translate(12 4)">
        <rect width="24" height="40" fill="#2B4059" rx="2" />
        <rect width="20" height="31" x="2" y="2" fill="#F2F3F5" rx="1" />
        <rect width="6" height="3" x="9" y="35" fill="#516276" rx="1.5" />
      </g>
      <path
        fill="#5FC484"
        d="M29 17l-2 2-2-2h-1a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h19a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H29z"
      />
      <path
        fill="#FFF"
        d="M41.414 13.058l.619.626.888 1.224-.792.576-.888-1.224-.408-.785-.408.785-.876 1.224-.792-.576.888-1.224.611-.627-.863-.153-1.428-.456.288-.924 1.44.468.782.388-.122-.868L40.34 10h.996l-.012 1.512-.132.872.792-.392 1.44-.468.288.924-1.428.456-.87.154zm-6.982 0l.618.626.888 1.224-.792.576-.888-1.224-.408-.785-.408.785-.876 1.224-.792-.576.888-1.224.612-.627-.864-.153-1.428-.456.288-.924 1.44.468.783.388-.123-.868L33.358 10h.996l-.012 1.512-.13.872.79-.392 1.44-.468.288.924-1.428.456-.87.154zm-6.982 0l.618.626.888 1.224-.792.576-.888-1.224-.408-.785-.408.785-.876 1.224-.792-.576.888-1.224.612-.627-.864-.153L24 12.448l.288-.924 1.44.468.783.388-.123-.868L26.376 10h.996l-.012 1.512-.131.872.791-.392 1.44-.468.288.924-1.428.456-.87.154z"
      />
    </g>
  </svg>
);

Illustration.propTypes = propTypes;
Illustration.defaultProps = defaultProps;

export default Illustration;
