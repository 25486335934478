import React from "react";
import PropTypes from "prop-types";
import Paginate from "react-paginate";
import styled from "styled-components";

import ChevronIcon from "../Icons/Chevron";

/**
 * props definition
 */
const propTypes = {
  totalPages: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
};

const defaultProps = {
  page: 1,
  totalPages: 0,
  setPage: () => {},
};

const Pagination = ({ totalPages, page, setPage, ...props }) => (
  <Wrapper {...props}>
    <Paginate
      initialPage={page - 1}
      pageCount={totalPages}
      pageRangeDisplayed={1}
      marginPagesDisplayed={2}
      nextLabel={<ChevronIcon variant="right" size={16} />}
      onPageChange={({ selected }) => setPage(selected + 1)}
      previousLabel={<ChevronIcon variant="left" size={16} />}
    />
  </Wrapper>
);

/**
 * styles
 */
const Wrapper = styled.div`
  overflow-x: auto;

  ul {
    padding: 0rem;
    display: flex;
    list-style: none;
    margin-bottom: 0rem;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }

    li {
      a {
        background-color: var(--pale-grey-three);
        transition: ease-in-out all 0.15s;
        color: var(--slate) !important;
        justify-content: center;
        border-radius: 0.125rem;
        padding: 0.5rem 0.75rem;
        align-items: center;
        font-size: 0.875rem;
        font-weight: bold;
        display: flex;
        outline: none;
      }

      &.next,
      &.previous {
        &.disabled {
          opacity: 0.5;
        }
      }

      &.selected {
        a {
          color: #fff !important;
          transition: ease all 0.25s;
          background-color: var(--clear-blue);
        }
      }
    }
  }
`;

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;

export default Pagination;
