import React, { useState } from "react";
import { object, number } from "yup";
import { Formik } from "formik";
import PropTypes from "prop-types";
import linkify from "linkify-html";
import Fade from "react-fade-in";

import EVoucherIllustration from "../../../../Illustrations/EVoucher";
import LogoCardComponent from "../../../LogoCard";
import ButtonComponent from "../../../Button";
import SelectComponent from "../../../Select";
import FieldComponent from "../../../Field";
import WrapperStyle from "../../../../Styles/Wrapper";
import GridStyle from "../../../../Styles/Grid";

const propTypes = {
  /**
   * key to identify the transaction section
   */
  paramKey: PropTypes.string,
  /**
   * user wallet
   */
  wallet: PropTypes.object,

  /**
   * methods are all the filtered banks under the bank payment methods
   */
  methods: PropTypes.array,

  /**
   * used to toggle between payment information screen and payment form screen
   */
  setHasPaid: PropTypes.func,

  /**
   * transactionData is the data collection in initial stages of transaction data collection
   */
  transactionData: PropTypes.object,
  /**
   * handleSubmit is for form submission
   */
  handleSubmit: PropTypes.func,
  /**
   * onSuccess for successful transaction request
   */
  onSuccess: PropTypes.func,
};

const Index = ({ methods, paramKey, handleSubmit, onSuccess }) => {
  /**
   * state
   */
  const [method, setMethod] = useState(methods[0]);

  return (
    <Fade>
      <GridStyle
        columns={{ md: "repeat(2, 1fr)" }}
        gaps={{ sm: "1.5rem", md: "2.5rem" }}
      >
        <div className="text-center">
          <div className="mb-6">
            <p className="mb-4">
              From the options, select (or type in a location to search) an
              agent closest to you and click on proceed to generate a QR code.
              Next, go to the agent's location with your ID card and QR Code to
              make payment.
            </p>
            <p>
              If you need help, click here to use the{" "}
              <a href={process.env.REACT_APP_SUPPORT_URL} target="support">
                livechat
              </a>{" "}
              to get support.
            </p>
          </div>

          {method?.deposit_instructions && (
            <p
              className="mb-6"
              dangerouslySetInnerHTML={{
                __html: linkify(method.deposit_instructions, {
                  target: "_blank",
                }),
              }}
            />
          )}

          <WrapperStyle width={400} className="mx-auto">
            <div className="d-flex justify-content-center">
              <Formik
                validateOnMount
                enableReinitialize
                validationSchema={object({
                  payment_method_id: number().typeError().required(),
                })}
                initialValues={{
                  payment_method_id: method?.id,
                  [paramKey]: {
                    payment_method_id: method?.id,
                    ...method?.details,
                  },
                }}
                onSubmit={(params, { setSubmitting, setErrors }) => {
                  handleSubmit(params)
                    .then((response) => onSuccess(response))
                    .catch((error) => setErrors(error?.fields))
                    .finally(() => setSubmitting(false));
                }}
              >
                {({ isSubmitting, isValid, handleSubmit }) => (
                  <ButtonComponent
                    onClick={handleSubmit}
                    {...{ isValid, isSubmitting }}
                  >
                    Proceed
                  </ButtonComponent>
                )}
              </Formik>
            </div>
          </WrapperStyle>
        </div>

        <div>
          <GridStyle gaps={{ sm: "1rem" }}>
            <FieldComponent
              name="network"
              withFormik={false}
              value={method?.id}
              label="Select location"
              wrapperClassName="mb-0"
              component={SelectComponent}
              onChange={({ value }) =>
                setMethod(methods?.find(({ id }) => value === id))
              }
              options={methods?.map((method) => ({
                type: "svg",
                value: method?.id,
                label: method?.name,
                avatar: EVoucherIllustration,
              }))}
            />

            <LogoCardComponent className="white-text">
              <GridStyle gaps={{ sm: "1rem" }}>
                <small className="fw-bolder">
                  {process.env.REACT_APP_APP_NAME || "PayPlux"} E-Voucher
                  Details
                </small>
                {method?.details &&
                  Object.keys(method.details).map((item, key) => (
                    <div key={key}>
                      <small className="bluey-grey-text mb-1">{item}</small>
                      <p
                        className="fw-bold"
                        dangerouslySetInnerHTML={{
                          __html: linkify(method.details[item], {
                            target: "_blank",
                          }),
                        }}
                      />
                    </div>
                  ))}
              </GridStyle>
            </LogoCardComponent>
          </GridStyle>
        </div>
      </GridStyle>
    </Fade>
  );
};

Index.propTypes = propTypes;

export default Index;
