import React, { Fragment } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import PropTypes from "prop-types";
import styled from "styled-components";

import BubbleStyle from "../../Styles/Bubble";
import PendingIcon from "../../Icons/Pending";
import CheckIcon from "../../Icons/Check";

/**
 * props definition
 */
const propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      state: PropTypes.oneOf(["stale", "active", "complete"]),
      desc: PropTypes.string,
    }),
  ),
};

/**
 * static
 */
const handleColorsAndIcons = (state) => {
  if (state === "active") {
    return [
      "var(--clear-blue)",
      "clear-blue-text",
      (props) => <ClipLoader size={16} {...props} />,
    ];
  }
  if (state === "complete") {
    return [
      "var(--dark-mint)",
      "dark-mint-text",
      (props) => <CheckIcon size={20} {...props} />,
    ];
  }
  if (state === "stale") {
    return [
      "var(--light-periwinkle)",
      "bluey-grey-text",
      (props) => <PendingIcon size={34} {...props} />,
    ];
  }

  return ["", "", Fragment];
};

const ProgressCheck = ({ items, ...props }) => {
  /**
   * variables
   */
  const count = items?.length;
  const states = items?.map(({ state }) => state);

  return (
    <Wrapper {...{ count, ...props }}>
      <ProgressLine {...{ states, count }} />
      {items?.map((item, key) => (
        <Item {...item} key={key} />
      ))}
    </Wrapper>
  );
};

/**
 *  others
 */
const Item = ({ state, desc }) => {
  /**
   * variables
   */
  const [bg, color, Icon] = handleColorsAndIcons(state);

  return (
    <div className="mx-auto text-center">
      <BubbleStyle className="mb-2 mx-auto" bg={bg} size={34}>
        <Icon color="#fff" />
      </BubbleStyle>
      <small className={`text-sub -media lh-15 d-block ${color}`}>{desc}</small>
    </div>
  );
};

/**
 * styles
 */
const ProgressLine = styled.div`
  left: 0;
  right: 0;
  top: 1rem;
  margin: 0 auto;
  height: 0.125rem;
  position: absolute;
  background-color: var(--light-periwinkle);
  width: calc(100% - (100% / ${({ count }) => count}));

  &::before {
    width: 100%;
    content: " ";
    height: 100%;
    display: block;
    background-image: linear-gradient(
      90deg,
      ${({ states, count }) =>
        states
          ?.map(
            (state, key) =>
              `${handleColorsAndIcons(state)[0]} ${(
                (key / count) * 100 +
                (1 / count / count) * 100
              ).toFixed(2)}%`,
          )
          .join(", ")}
    );
  }
`;

const Wrapper = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(${({ count }) => count}, 1fr);
`;

ProgressCheck.propTypes = propTypes;

export default ProgressCheck;
