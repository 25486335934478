import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import loopUtil from "../../Utils/loop";

const propTypes = {
  placement: PropTypes.oneOf(["top", "bottom"]),
  radius: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  gap: PropTypes.number.isRequired,
  count: PropTypes.number,
};

const defaultProps = {
  gap: 5,
  radius: 6,
  width: 450,
  color: "#fff",
  placement: "top",
};

const Waves = ({ radius, width, color, gap, count, ...props }) => {
  /**
   * variables
   */
  const circles = (() => {
    const arr = [radius]; // initial value is radius of circle
    count = count || (width - width / gap) / (radius * 2) - 1; // number of circles display

    /**
     * loop through count
     * get the last item in array, add the diameter to the item
     * to get the new circle (this will put the circle right next to it)
     * add gap to create spacing between last item and new item
     */
    loopUtil(count).map(() => arr.push(arr[arr.length - 1] + radius * 2 + gap));

    return arr;
  })();

  return (
    <Wrapper
      fill={color}
      width="100%"
      height={radius * 2}
      xmlns="http://www.w3.org/2000/svg"
      {...{ ...props, radius }}
    >
      <g>
        {circles.map((cx, key) => (
          <circle key={key} cx={cx} cy={radius} r={radius} />
        ))}
      </g>
    </Wrapper>
  );
};

/**
 * styles
 */
const Wrapper = styled.svg`
  z-index: 0;
  position: absolute;
  left: ${({ radius }) => `-${radius * 0.0625}rem`};
  right: ${({ radius }) => `-${radius * 0.0625}rem`};
  height: ${({ radius }) => `${radius * 2 * 0.0625}rem`};
  width: ${({ radius }) => `calc(100% + ${radius * 2 * 0.0625}rem)`};

  ${({ placement, radius }) => {
    switch (placement) {
      case "top":
        return `top: -${radius * 0.0625}rem;`;
      case "bottom":
        return `bottom: -${radius * 0.0625}rem;`;
    }
  }}
`;

Waves.propTypes = propTypes;
Waves.defaultProps = defaultProps;

export default Waves;
