import React, { Fragment } from "react";
import queryString from "query-string";
import Skeleton from "react-loading-skeleton-2";
import useSWR from "swr";

import ModalComponent from "../../Modal";
import ProcessedOrder from "./Order/ProcessedOrder";
import WithdrawalOrder from "./Order/WithdrawalOrder";
import PendingOrder from "./Order/PendingOrder";

/**
 * sell order statuses
 * all of the below items are prefixed with user-sell-
 * [has-errors, awaiting-ecurrency, ecurrency-awaiting-confirmation, processing, processed, cancelled]
 */

/**
 * buy order statuses
 * all of the below items are prefixed with user-buy-
 * [has-errors, awaiting-payment-confirmation, processing, queued, processed, cancelled]
 */

const Order = ({ id, order: transaction, ...props }) => {
  /**
   * api
   */
  const { data, error, mutate } = useSWR(
    !transaction &&
      Boolean(id) &&
      `/orders/${id}?${queryString.stringify({
        with_crypto_details: true,
        with_transaction: true,
        with_ecurrency: true,
        with_wallet: true,
        with_coupon: true,
      })}`,
    {
      refreshInterval: 1000 * 30,
    },
  );

  /**
   * variables
   */
  const order = Boolean(id) && (transaction || data);

  return (
    <Fragment>
      {id && !transaction && !data && !error && (
        <ModalComponent.Dark show onHide={() => {}}>
          <ModalComponent.Body>
            <Skeleton height={600} />
          </ModalComponent.Body>
        </ModalComponent.Dark>
      )}

      {Boolean(id && order) && (
        <Fragment>
          {order?.payment_method?.type === "cash-point" ? (
            <WithdrawalOrder {...{ ...props, id, order }} />
          ) : order?.status?.slug !== "user-sell-awaiting-ecurrency" ? (
            <ProcessedOrder {...{ ...props, id, order }} />
          ) : (
            <PendingOrder {...{ ...props, id, order, mutate }} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Order;
