import React, { Fragment } from "react";
import { Formik } from "formik";
import { object } from "yup";
import { css } from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";
import PropTypes from "prop-types";
import useSWR from "swr/immutable";
import Form from "react-bootstrap/Form";

import { instructions } from "./GoogleAuthenticatorModal/index";
import * as schemaUtil from "../Utils/schema";
import copyToClipboardUtil from "../Utils/copyToClipboard";
import ButtonComponent from "./Button";
import QRCodeComponent from "./QRCode";
import FieldComponent from "./Field";
import ModalComponent from "./Modal";
import BubbleStyle from "../Styles/Bubble";
import CopyIcon from "../Icons/Copy";
import HttpUtil from "../Utils/Http";

/**
 * props definition
 */
const propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

const GoogleAuthenticatorModal = ({ show, onHide, onSuccess, ...props }) => {
  /**
   * functions
   */
  const handleSubmit = (params, { setSubmitting, setErrors }) => {
    HttpUtil.post("/google2fa/activate", params)
      .then(() => onSuccess())
      .catch((error) => setErrors(error?.fields))
      .finally(() => setSubmitting(false));
  };

  /**
   * api
   */
  const { data } = useSWR(show && `/google2fa/setup`);

  return (
    <ModalComponent.Dark {...{ show, onHide }}>
      <ModalComponent.Body className="py-6 px-4 px-md-6 white-bg">
        <Fragment>
          <h5 className="mb-15">Setup Google Authenticator</h5>

          {instructions?.map(({ text }, index) => (
            <div className="d-flex align-items-center mb-8" key={index}>
              <div className="me-6 align-self-start steel-text fw-bolder">
                <BubbleStyle className="pale-grey-two-bg" size={50}>
                  {++index}
                </BubbleStyle>
              </div>
              <p className="lh-16">{text}</p>
            </div>
          ))}

          <hr className="my-8 steel-bg" />

          <Formik
            validateOnMount
            enableReinitialize
            validationSchema={object({
              otp: schemaUtil.requireString("Verification code"),
            })}
            initialValues={{ otp: "", secret: data?.secret || "" }}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setFieldTouched,
            }) => (
              <Form className="mb-8">
                <Form.Group>
                  <div className="d-flex flex-column-reverse align-items-center flex-md-row">
                    <div className="me-0 me-md-6 mt-10 mt-md-0">
                      {data ? (
                        <QRCodeComponent size={148} value={data?.qrcode_data} />
                      ) : (
                        <ClipLoader color="var(--clear-blue)" />
                      )}
                    </div>

                    <div>
                      <p className="small mb-4 bluey-grey-text">
                        Enter 6-digit verification code
                      </p>
                      <div className="d-flex align-items-center justify-content-center position-relative">
                        <FieldComponent.Otp
                          withFormik
                          name="otp"
                          isDisabled={isSubmitting}
                          value={values?.otp || ""}
                          setFieldValue={(name, value) => {
                            setFieldValue(name, value);

                            if (value.length === 6) {
                              setTimeout(() => handleSubmit());
                            }
                          }}
                          {...{ setFieldTouched }}
                        />
                        {isSubmitting && (
                          <ClipLoader
                            color="var(--clear-blue)"
                            css={css`
                              position: absolute;
                            `}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Form.Group>
              </Form>
            )}
          </Formik>

          <div style={{ maxWidth: 378 }}>
            <FieldComponent
              withFormik={false}
              value={data?.secret}
              wrapperClassName="mb-0"
              component={Form.Control}
              name="authenticator_secret_code"
              label="Authenticator Secret Code"
              postfix={<CopyInputSubComponent value={data?.secret} />}
            />
          </div>
        </Fragment>
      </ModalComponent.Body>
    </ModalComponent.Dark>
  );
};

/**
 * component
 */
const CopyInputSubComponent = ({ value }) => (
  <ButtonComponent
    bg="var(--pale-grey-three)"
    className="border-0 border-start light-periwinkle-border"
    style={{
      cursor: "copy",
      borderRadius: "inherit",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    }}
    onClick={() => copyToClipboardUtil(value)}
    variant="custom"
    width={50}
  >
    <CopyIcon />
  </ButtonComponent>
);

GoogleAuthenticatorModal.propTypes = propTypes;

export default GoogleAuthenticatorModal;
