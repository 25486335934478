import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const propTypes = {
  color: PropTypes.string,
  dash: PropTypes.string,
};

const defaultProps = {
  color: "#fff",
  dash: "var(--dark-grey-blue)",
};

const Separator = (props) => (
  <Wrapper {...props}>
    <div className="separator__dash" />
  </Wrapper>
);

/**
 * style
 */
const Wrapper = styled.div`
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  height: 2rem;

  &::before,
  &::after {
    width: 2rem;
    height: 2rem;
    content: " ";
    display: block;
    border-radius: 50%;
    position: absolute;
    background-color: ${({ color }) => color};
  }

  &::before {
    left: -1rem;
  }

  &::after {
    right: -1rem;
  }

  .separator__dash {
    border-top: solid 2px ${({ dash }) => dash};
    border-top-style: dashed;
    width: calc(100% - 64px);
  }
`;

Separator.propTypes = propTypes;
Separator.defaultProps = defaultProps;

export default Separator;
