import queryString from "query-string";
import PropTypes from "prop-types";
import useSWR from "swr";

const propTypes = {
  asset: PropTypes.number.isRequired,
  user: PropTypes.object,
  chart: PropTypes.bool,
};

const defaultProps = {
  chart: false,
};

const useAsset = ({ asset, user, chart, ...props }, fetcher) => {
  const data = useSWR(
    asset &&
      (chart
        ? `/marketdata/chart/${asset}?${queryString.stringify({
            with_crypto_rate: true,
            ...props,
          })}`
        : `/ecurrencies/${asset}/wallets/${
            user?.default_wallet_id
          }?${queryString.stringify({
            ...props,
          })}`),
    fetcher,
  );

  return data;
};

useAsset.propTypes = propTypes;
useAsset.defaultProps = defaultProps;

export default useAsset;
