import React, { useState, useEffect, Fragment } from "react";
import { createConfirmation, confirmable } from "react-confirm";
import { Formik } from "formik";
import { object } from "yup";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";
import Form from "react-bootstrap/Form";

import * as schemaUtil from "../Utils/schema";
import CodeRequestCounterComponent from "./CodeRequestCounter";
import ButtonComponent from "./Button";
import NotifyComponent from "./Notify";
import OtpIllustration from "../Illustrations/Otp";
import ModalComponent from "./Modal";
import LinkComponent from "./Link";
import BubbleStyle from "../Styles/Bubble";
import HttpUtil from "../Utils/Http";
import OtpField from "./Field/Otp";

const propTypes = {
  params: PropTypes.object,
  api: PropTypes.func.isRequired,
  otpType: PropTypes.oneOf(["google2fa", "sms"]),
  variant: PropTypes.oneOf(["phone", "2fa", "none"]),
};

const ConfirmOtp = ({
  api,
  show,
  proceed,
  params = {},
  otpType = "sms",
  variant = "2fa",
}) => {
  /**
   * state
   */
  const [allowResend, setAllowResend] = useState();
  const [isSent, setIsSent] = useState();
  const [load, setLoad] = useState();

  /**
   * functions
   * @param {*} type
   */
  const handleResendOtp = () => {
    setAllowResend(dayjs().add(59, "seconds").toDate());
    setIsSent(false);
  };

  /**
   * effect
   */
  useEffect(() => {
    if (show && !isSent) {
      setIsSent(true);
      if (variant === "2fa" && otpType === "sms") {
        HttpUtil.post("/user-2fa/send")
          .then(() => setLoad(true))
          .catch(
            (errors) =>
              toast(
                <NotifyComponent body="Unable to send 2fa code" type="error" />,
              ) | proceed({ success: false, errors }),
          );
      }

      if (variant === "2fa" && otpType === "google2fa") {
        setLoad(true);
      }

      if (variant === "phone" && params?.phone) {
        HttpUtil.post("/phones", params)
          .then(() => setLoad(true))
          .catch(
            (errors) =>
              toast(
                <NotifyComponent body="Unable to send 2fa code" type="error" />,
              ) | proceed({ success: false, errors }),
          );
      }
    }
  }, [show, isSent, variant, otpType, proceed]);

  return (
    <Fragment>
      {load && (
        <ModalComponent.Dark
          size="sm"
          show={show}
          className="overlay"
          backdropClassName="overlay"
          onHide={() => proceed({ success: false })}
        >
          <ModalComponent.Body className="white-bg overflow-hidden">
            <div className="d-flex align-items-center justify-content-center py-8 clear-blue-bg">
              <BubbleStyle
                size={104}
                bg="rgba(0,0,0,0.125)"
                className="overflow-hidden"
              >
                <OtpIllustration size={84} style={{ marginBottom: -64 }} />
              </BubbleStyle>
            </div>

            <Formik
              validateOnMount
              initialValues={{ code: "" }}
              validationSchema={object({
                code: schemaUtil.requireOTP("OTP"),
              })}
              onSubmit={({ code }, { setErrors, setSubmitting }) => {
                api({ ...params, code })
                  .then((response) => proceed({ success: true, response }))
                  .catch((errors) => {
                    if (errors?.fields?.code) {
                      setErrors({
                        code: errors?.fields?.code?.[0],
                        ...errors.fields,
                      });
                    } else {
                      proceed({ success: false, errors });
                    }
                  })
                  .finally(() => setSubmitting(false));
              }}
            >
              {({
                values,
                isValid,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Form className="p-6 p-8 mb-10">
                  {otpType === "sms" && (
                    <div
                      className="mx-auto text-center mb-8 mb-md-12"
                      style={{ maxWidth: "290px" }}
                    >
                      <p className="mb-4">
                        Enter the verification code we just sent to your phone
                        number OR dial *928*075#
                      </p>
                      <h5 className="font-monospace fw-bolder">
                        {params?.phone}
                      </h5>
                    </div>
                  )}

                  {otpType === "google2fa" && (
                    <p className="mb-8 mb-md-12 text-center">Enter 2FA code.</p>
                  )}

                  <Wrapper className="mb-12">
                    <OtpField
                      withFormik
                      name="code"
                      isDisabled={isSubmitting}
                      value={values.code || ""}
                      {...{ setFieldTouched, setFieldValue }}
                    />
                  </Wrapper>

                  <ButtonComponent
                    height={64}
                    type="submit"
                    onClick={handleSubmit}
                    {...{ isValid, isSubmitting }}
                  >
                    Verify and proceed
                  </ButtonComponent>
                </Form>
              )}
            </Formik>

            {otpType === "sms" && (
              <div className="text-center mb-12">
                {allowResend ? (
                  <CodeRequestCounterComponent
                    date={allowResend}
                    setDate={setAllowResend}
                  />
                ) : (
                  <small className="d-block">
                    Didn’t receive the code?{" "}
                    <LinkComponent preventDefault onClick={handleResendOtp}>
                      Resend
                    </LinkComponent>
                  </small>
                )}
              </div>
            )}
          </ModalComponent.Body>
        </ModalComponent.Dark>
      )}
    </Fragment>
  );
};

/**
 * styles
 */
const Wrapper = styled.div`
  margin: 0px auto;
  max-width: 328px;
`;

ConfirmOtp.propTypes = propTypes;

export const Confirm = createConfirmation(confirmable(ConfirmOtp));

export default Confirm;
