import React, { useEffect } from "react";
import Countdown from "react-countdown";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const propTypes = {
  date: PropTypes.any.isRequired,
  setDate: PropTypes.func.isRequired,
};

const defaultProps = {
  message: "You can request a new code in",
};

const CodeRequestCounter = ({ date, setDate, message, className }) => {
  /**
   * effect
   */
  useEffect(() => {
    if (date) {
      if (dayjs().isAfter(dayjs(date))) {
        setDate(null);
      }
    }
  }, [date, setDate]);

  return (
    <Countdown
      date={new Date(date)}
      onComplete={() => setDate(null)}
      renderer={({ seconds, completed }) =>
        !completed && (
          <small className={`d-block text-center ${className}`}>
            {message + " "}
            <span className="fw-bold">{seconds}s</span>
          </small>
        )
      }
    />
  );
};

CodeRequestCounter.propTypes = propTypes;
CodeRequestCounter.defaultProps = defaultProps;

export default CodeRequestCounter;
