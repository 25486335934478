import React from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";

import "./GoogleMapsPreview/styles.css";
import Link from "./Link";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

/**
 * props definition
 */
const propTypes = {
  /**
   * map location url
   */
  url: PropTypes.string.isRequired,
  /**
   * latitude of location
   */
  lat: PropTypes.number.isRequired,
  /**
   * longitude of location
   */
  lng: PropTypes.number.isRequired,
  /**
   * name to show in marker
   */
  name: PropTypes.string,
  /**
   * caption to show in preview
   */
  caption: PropTypes.string,
  /**
   * center: Lat/lng at which to center the map
   */
  center: PropTypes.object,
  /**
   * height of the parent container
   */
  height: PropTypes.string,
  /**
   * width of the parent container
   */
  width: PropTypes.string,
  /**
   * Map zoom level
   */
  zoom: PropTypes.number,
};

const defaultProps = {
  lat: 5.619836518608938,
  lng: -0.22623926032067726,
  caption: "View full map",
  center: {
    lat: 5.619836518608938,
    lng: -0.22623926032067726,
  },
  height: "152px",
  width: "100%",
  name: "Agent",
  zoom: 11,
};

export function SimpleMap({
  lat,
  lng,
  name,
  url,
  caption,
  center,
  zoom,
  height,
  width,
  ...props
}) {
  return (
    // Important! Always set the container height explicitly
    <div className="map-container" style={{ height: height, width: width }}>
      <div className="map-container__caption">
        <Link href={url} target="_blank">
          {caption || ""}
        </Link>
      </div>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_KEY || "",
          libraries: ["places"],
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        {...props}
      >
        <AnyReactComponent lat={lat} lng={lng} text={name} />
      </GoogleMapReact>
    </div>
  );
}

const GoogleMapsPreview = (props) => {
  return <SimpleMap {...props} />;
};

GoogleMapsPreview.propTypes = propTypes;
GoogleMapsPreview.defaultProps = defaultProps;

export default GoogleMapsPreview;
