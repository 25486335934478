import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  size: PropTypes.number,
};

const defaultProps = {
  size: 48,
};

const PrepaidWallet = ({ size, ...props }) => (
  <svg
    fill="none"
    width={size}
    height={size}
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 11.8605C4 10.833 4.83296 10 5.86047 10H37.4884C39.5434 10 41.2093 11.6659 41.2093 13.7209V36.2791C41.2093 38.3341 39.5434 40 37.4884 40H5.86046C4.83296 40 4 39.167 4 38.1395V11.8605Z"
      fill="#FFB121"
    />
    <rect x="7" y="13" width="36" height="24" fill="#5FC484" />
    <rect x="7" y="16" width="34" height="18" fill="#6DD194" />
    <path
      d="M4 11.8605C4 10.833 4.83296 10 5.86047 10H35.0884C37.1434 10 38.8093 11.6659 38.8093 13.7209V36.2791C38.8093 38.3341 37.1434 40 35.0884 40H5.86047C4.83296 40 4 39.167 4 38.1395V11.8605Z"
      fill="#FFC642"
    />
    <path
      d="M29.1162 23.1105C29.1162 22.083 29.9492 21.25 30.9767 21.25H43.9999V30.625H30.9767C29.9492 30.625 29.1162 29.792 29.1162 28.7645V23.1105Z"
      fill="#FFB121"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.6979 27.8125C35.7254 27.8125 36.5583 26.973 36.5583 25.9375C36.5583 24.902 35.7254 24.0625 34.6979 24.0625C33.6704 24.0625 32.8374 24.902 32.8374 25.9375C32.8374 26.973 33.6704 27.8125 34.6979 27.8125Z"
      fill="#E79A1D"
    />
  </svg>
);

PrepaidWallet.propTypes = propTypes;
PrepaidWallet.defaultProps = defaultProps;

export default PrepaidWallet;
