import { transactionType } from "./index";

const Description = ({ transaction }) => {
  const description = (() => {
    const type = transactionType(transaction);

    switch (type) {
      case "deposit":
        return "Prepaid wallet topup";
      case "purchase":
        return `${transaction?.order?.ecurrency?.name || "Crypto"} Bought`;
      case "sale":
        return `${transaction?.order?.ecurrency?.name || "Crypto"} sold`;
      case "withdrawal":
        return "Funds withdrawal";
      case "Bulk Buy":
        return `${transaction?.ecurrency?.name || "Crypto"} Bought`;
      case "Bulk Sell":
        return `${transaction?.ecurrency?.name || "Crypto"} sold`;
      default:
        return "--";
    }
  })();

  return description;
};

export default Description;
