import React, { Fragment, useState } from "react";

import PaymentDetailsForm from "./MobileMoney/PaymentDetailsForm";
import Index from "./MobileMoney/Index";

const MobileMoney = (props) => {
  /**
   * state
   */
  const [hasPaid, setHasPaid] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();

  return (
    <Fragment>
      {hasPaid ? (
        <PaymentDetailsForm {...{ ...props, setHasPaid, paymentMethod }} />
      ) : (
        <Index {...{ ...props, setHasPaid, setPaymentMethod }} />
      )}
    </Fragment>
  );
};

export default MobileMoney;
