import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Card from "react-bootstrap/Card";

import LinkComponent from "./Link";
import useWidthHook from "../Hooks/useWidth";
import Desktop from "./RecentTransactions/Desktop";
import Mobile from "./RecentTransactions/Mobile";

const propTypes = {
  isLoading: PropTypes.bool,
  transactions: PropTypes.arrayOf(PropTypes.object),
  onSellAll: PropTypes.func,
};

const RecentTransactions = ({
  isLoading,
  transactions,
  className,
  onSellAll,
}) => {
  /**
   * variables
   */
  const width = useWidthHook();

  return (
    <Wrapper className={`mx-n4 mx-md-0 ${className}`}>
      <Card.Body className="px-4 py-8 p-lg-6 position-relative">
        <Header className="d-flex justify-content-between align-items-center mb-4">
          <h5>Recent Transactions</h5>
          {onSellAll && (
            <LinkComponent
              preventDefault
              onClick={onSellAll}
              className="fw-bolder"
            >
              SEE ALL
            </LinkComponent>
          )}
        </Header>
        <Content>
          {width < 768 ? (
            <Mobile {...{ isLoading, transactions }} />
          ) : (
            <Desktop {...{ isLoading, transactions }} />
          )}
        </Content>
      </Card.Body>
    </Wrapper>
  );
};

/**
 * styles
 */

const Content = styled.div`
  flex: 1 1 0%;
  height: 100%;
  overflow-y: auto;
`;

const Header = styled.div`
  a {
    font-size: 0.75rem;
  }
`;

const Wrapper = styled(Card)`
  .card-body {
    display: flex;
    max-height: 28rem;
    position: relative;
    flex-direction: column;
  }

  @media (max-width: 767px) {
    box-shadow: none;
    border-top: solid 0.0625rem var(--ice-blue);
    border-bottom: solid 0.0625rem var(--ice-blue);
  }
`;

RecentTransactions.propTypes = propTypes;

export default RecentTransactions;
