import { useEffect } from "reactn";
import useSWR from "swr/immutable";
import PropTypes from "prop-types";

const propTypes = {
  token: PropTypes.string.isRequired,
  setToken: PropTypes.func.isRequired,
  required: PropTypes.bool,
  onUnauthorized: PropTypes.func,
};

const defaultProps = {
  required: true,
  setToken: () => {},
  onUnauthorized: () => {},
};

const useUser = ({
  token,
  setToken,
  required = true,
  onUnauthorized = () => {},
}) => {
  /**
   * api
   */
  const { data, error, mutate, ...props } = useSWR(
    token && "/auth/me",
    (url) =>
      window
        .fetch(`${process.env.REACT_APP_API_BASEURL}${url}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => response.json()),
    {
      revalidateOnReconnect: true,
    },
  );

  /**
   * effect
   */
  useEffect(() => {
    if (required) {
      if (token && !data && !error) {
        if (error) {
          setToken(null);
          onUnauthorized();
        }
      }

      if (!token) {
        onUnauthorized();
      }
    }
    return () => {};
  }, [token, data, error, setToken, onUnauthorized]);

  return {
    data,
    error,
    mutate,
    user: data?.data,
    isLoading: !data && !error,
    ...props,
  };
};

useUser.propTypes = propTypes;
useUser.defaultProps = defaultProps;

export default useUser;
